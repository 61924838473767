import { get } from "lodash"
import api from '../../../api/battery-data-hub.api';


export default {

    namespaced: true,

    state: {

        filter: {
            batteryModels: [],
            manufacturers: [],
            batteryMaterials: []
        },

        pagination: {
            from: 0,
            pageSize: 0,
            totalItemCount: 0,
        },

        model: {

            columns: [],
            rows: [],
        },

        context: {
            batteryModels: [],
            manufacturers: [],
            batteryMaterials: []
        }
    },

    mutations: {
        
        setMatrixRows(state, rows) {
            state.model.rows = (rows) ? rows : [];
        },

        appendMatrixRows(state, rows) {
            if (!state.model.rows) state.model.rows = [];

            if (rows) {
                for (const row of rows) {
                    state.model.rows.push(row);
                }
            }
        },

        setMatrixColumns(state, columns) {
            state.model.columns = (columns) ? columns : [];
        },

        setPagination(state, pagination) {
            state.pagination = (pagination) ? pagination : { from: 0, pageSize: 0, totalItemCount: 0};
        },

        setContextBatteryModelResponse(state, {items}){
            state.context.batteryModels = items ? items : [];
        },

        setContextManufacturerResponse(state, {items}){
            state.context.manufacturers = items ? items : [];
        },

        setContextBatteryMaterialResponse(state, {items}) {
            state.context.batteryMaterials = items ? items : [];
        },

        setBatteryModelFilter(state, items) {
            state.filter.batteryModels = items ? items : [];
        },

        setManufacturerFilter(state, items) {
            state.filter.manufacturers = items ? items : [];
        },

        setBatteryMaterialFilter(state, items) {
            state.filter.batteryMaterials = items ? items : [];
        }
    },

    actions: {

        async resetMatrix(context) {

            // The 
            context.commit('setMatrixRows',    []);
            context.commit('setMatrixColumns', []);
            context.commit('setPagination', {
                from: 0,
                pageSize: 0,
                totalItemCount: 0,
            });
        },

        async resetContext(context) {

           // Reset the model response 
           await context.commit('setContextBatteryModelResponse',  { items: [] });
           await context.commit('setContextManufacturerResponse',  { items: [] });
           await context.commit('setContextBatteryMaterialResponse', { items: [] });
        },

        async resetFilter(context) {
            // Reset the model response 
            await context.commit('setBatteryModelFilter',  [] );
            await context.commit('setManufacturerFilter', [] );
            await context.commit('setBatteryMaterialFilter', [] );
        },
        async resetPagination(context) {
            // Reset the model response 
            await context.commit('setPagination',  { from: 0, pageSize: 0, totalItemCount: 0} );
        },

        async loadMatrix(context, {pageSize}) {

            const batteryModels = context.getters.batteryModelFilter;
            const batteryMaterials = context.getters.batteryMaterialFilter;
            const manufacturers = context.getters.manufacturerFilter;

            
            const response = await api.masterdata.batteryMaterialMatrix.get(batteryModels, batteryMaterials, manufacturers, 0, pageSize);
            context.commit('setMatrixRows',    get(response, 'rows', []));
            context.commit('setMatrixColumns', get(response, 'columns', []));
            context.commit('setPagination',    get(response, 'pagination', { from: 0, pageSize: pageSize, totalItemCount: 0}));

        },

        async loadMatrixNextPage(context, {from, pageSize}) {

            const batteryModels = context.getters.batteryModelFilter;
            const batteryMaterials = context.getters.batteryMaterialFilter;

            
            const response = await api.masterdata.batteryMaterialMatrix.get(batteryModels, batteryMaterials, from, pageSize);
            context.commit('appendMatrixRows',    get(response, 'rows', []));
            context.commit('setPagination',    get(response, 'pagination', { from: 0, pageSize: pageSize, totalItemCount: 0}))

        },

        async loadContextBatteryModels(context) {

            const result = await api.masterdata.batteryModels.findByFilter('*', 0, 100);
            context.commit('setContextBatteryModelResponse', result);
        },

        async loadContextManufacturers(context) {

            const result = await api.masterdata.manufacturers.findByFilter('*', 0, 100);
            context.commit('setContextManufacturerResponse', result);
        },

        async loadContextBatteryMaterials(context) {
            const result = await api.masterdata.batteryMaterials.findByFilter('*', 0, 100);
            context.commit('setContextBatteryMaterialResponse', result);
        },

        updateBatteryModelFilter(context, { items }) {
            context.commit('setBatteryModelFilter', items);
        },

        updateManufacturerFilter(context, { items }) {
            context.commit('setManufacturerFilter', items);
        },

        updateBatteryMaterialFilter(context, { items }) {
            context.commit('setBatteryMaterialFilter', items);
        }
    },

    getters: {
        
        matrixColumns(state) {
            return get(state, 'model.columns', []);   
        }, 

        matrixRows(state) {
            return get(state, 'model.rows', []);
        },

        batteryModels(state) {
            return get(state, 'context.batteryModels', []);
        },

        manufacturers(state) {
            return get(state, 'context.manufacturers', []);
        },

        batteryMaterials(state) {
            return get(state, 'context.batteryMaterials', []);
        },

        batteryModelFilter(state) {
            return get(state, 'filter.batteryModels', []);
        },

        manufacturerFilter(state) {
            return get(state, 'filter.manufacturers', []);
        },
        
        batteryMaterialFilter(state) {
            return get(state, 'filter.batteryMaterials', []);
        },

        pagination(state) {
            return get(state, 'pagination', {})
        },

        totalItemCount(state) {
            return get(state, 'pagination.totalItemCount', 0);
        },

        paginationFrom(state) {
            return get(state, 'pagination.from', 0);
        },

        paginationPageSize(state) {
            return get(state, 'pagination.pageSize', 100);
        }
    }
}