<template>
  <v-container fluid class="secondary pt-10 pb-10">
      <v-row justify="center">
          <v-col cols="12" lg="9">
              <c-base-dialog :value="substanceRecyclingShares" :title="$t('substance-recycling-share-wizard.view.title')"
                  :subtitle="$t('substance-recycling-share-wizard.view.subtitle')" color="secondary"
                  @dialog-saved="save"
                  @dialog-canceled="cancel">
                  <template v-slot:default="props">
                      <v-card flat>
                          <v-card-text>
                              <v-row>
                                  <v-col cols="12">
                                      <c-percentage-input-recycling v-model="props.item.data.preConsumerShare"      :label = "$t('substance-recycling-share-wizard.view.subheader.preConsumerShare')" filled> </c-percentage-input-recycling>
                                      <c-percentage-input-recycling v-model="props.item.data.postConsumerShare"     :label = "$t('substance-recycling-share-wizard.view.subheader.postConsumerShare')" filled> </c-percentage-input-recycling>
                                      <c-percentage-input-recycling v-model="props.item.data.primaryMaterialShare"  :label = "$t('substance-recycling-share-wizard.view.subheader.primaryMaterialShare')" filled> </c-percentage-input-recycling>
                                  </v-col>
                              </v-row>
                              <v-row> 
                                  <v-col cols="12" >

                                      <c-item-selector :items="batteryModels.items" v-model="props.item.references.batterymodel" :return-object="false" filled
                                          :chips="false"
                                          :rules="[  v => v && !!v.trim()  || $t('battery-substance-wizard.view.fields.schema.validation.notEmpty') ]"
                                          :label="$t('substance-recycling-share-wizard.view.subheader.model')">BatteryModels</c-item-selector>
                                  </v-col>
                              </v-row>
                              <v-row> 
                                  <v-col cols="12" >
                                      <c-item-selector :items="batterySubstances.items" v-model="props.item.references.batterysubstance" :return-object="false" filled
                                          :chips="false"
                                          :rules="[  v => v && !! v.trim()  || $t('battery-substance-wizard.view.fields.schema.validation.notEmpty') ]"
                                          :label="$t('substance-recycling-share-wizard.view.subheader.substance')">BatterySubstances</c-item-selector>
                                  </v-col>
                              </v-row>
                          </v-card-text>
                      </v-card>
                  </template>
              </c-base-dialog>
          </v-col>
      </v-row>
  </v-container>
</template>


<script>
import SubstanceRecyclingSharesWizardModule from "../../../store/masterdata/substance-recycling-share/substance-recycling-shares-wizard.module";
import DxsPercentageInputRecycling from "../../../components/masterdata/substance-recycling-shares/percentage-input-recycling.component.vue";
const MODULE_NAME = "SubstanceRecyclingSharesWizard";

export default {
  name: "substance-recycling-shares-wizard",
  components: {
    "c-percentage-input-recycling": DxsPercentageInputRecycling,
  },
  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
  ],

  methods: {
    async save() {
            this.progressIndicatorService.show();

            try {
              
                await this.$store.dispatch(MODULE_NAME + "/createSubstanceRecyclingShare");
                this.$router.push({
                    name: "SubstancesRecyclingShareEditorView",
                    params: { name: this.substanceRecyclingShares.name },
                });
                this.reset();
            } catch (error) {
                const err = new Error( this.$t('substances-recycling-share-editor.view.subheader.errorm'))
                this.errorHandlerService.handleError(err);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.reset();
            this.$router.push({
                name: "SubstancesRecyclingShareListView",
            });
            
    this.reset();
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetSubstanceRecyclingShare");
            this.setTenantId(this.tenantId);
        }
  },

  computed: {

    batteryModelId: {
    get() {
        return this.$store.getters[MODULE_NAME + "/batteryModelId"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateBatteryModelId", item)
            .catch(this.errorHandlerService.handleError);
    },
},

batterySubstanceId: {
    get() {
        return this.$store.getters[MODULE_NAME + "/batterySubstanceId"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateBatterySubstanceId", item)
            .catch(this.errorHandlerService.handleError);
    },
},

substanceRecyclingShares: {
    get() {
        return this.$store.getters[MODULE_NAME + "/substanceRecyclingShare"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateSubstanceRecyclingShare", item)
            .catch(this.errorHandlerService.handleError);
    },
},

substanceRecyclingSharesId: {

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateSubstanceRecyclingShare", item)
            .catch(this.errorHandlerService.handleError);
    },
},
batteryModels() {
    return this.$store.getters[MODULE_NAME + "/batteryModels"];
},
substanceName: {
      get() {
        return this.$store.getters[MODULE_NAME + "/substanceName"];
      },
    },
batterySubstances() {
    return this.$store.getters[MODULE_NAME + "/batterySubstances"];
},


preConsumerShare:{
    set(item){
        this.$store
            .dispatch(MODULE_NAME + "/updatePreConsumerShare", item)
            .catch(this.errorHandlerService.handleError);
    },
    get(){
        return this.$store.getters[MODULE_NAME + "/preConsumerShare"]
    }
},
postConsumerShare:{
    set(item){
        this.$store
        .dispatch(MODULE_NAME + "/updatePostConsumerShare", item)
        .catch(this.errorHandlerService.handleError);
    },
    get(){
        return this.$store.getters[MODULE_NAME + "/postConsumerShare"]
    }
},

primaryMaterialShare:{
    set(item){
        this.$store.dispatch(MODULE_NAME + "/updatePrimaryMaterialShare", item).catch(this.errorHandlerService.handleError);
    },
    get(){
        return this.$store.getters[MODULE_NAME + "/primaryMaterialShare"]
    }
},

tenantId() {
    return this.envContextService.tenantId;
}
},

async beforemount(){
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, SubstanceRecyclingSharesWizardModule);//todo
  }
    console.log("unmounting")
    this.$store.commit(MODULE_NAME + "/resetSubstanceRecyclingShare");//todo
    this.reset();
  },

async created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, SubstanceRecyclingSharesWizardModule);//todo
  }
    this.$store.commit(MODULE_NAME + "/resetSubstanceRecyclingShare");//todo
    this.reset();
  await this.$store.dispatch(MODULE_NAME + "/loadData");//todo
},

destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>

<i18n>
{
    "en" : {
        "substance-recycling-share-wizard.view.title" : "Create New Substance Recycling Share",
        "substance-recycling-share-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Substance Recycling Share.",
        "substance-recycling-share-wizard.view.subheader.model" : "Battery Model",
        "substance-recycling-share-wizard.view.subheader.substance" : "Battery Substance",
        "battery-model-wizard.view.fields.schema.validation.notEmpty" : "Please assign a Battery Model to this Substance Recycling Share.",
        "battery-substance-wizard.view.fields.schema.validation.notEmpty" : "Please assign a Battery Substance to this Substance Recycling Share.",
        "substance-recycling-share-wizard.view.subheader.primaryMaterialShare" : "Material Share",
        "substance-recycling-share-wizard.view.subheader.preConsumerShare" : "Pre-Consumer Share",
        "substance-recycling-share-wizard.view.subheader.postConsumerShare" : "Post-Consumer Share",
        "substances-recycling-share-editor.view.subheader.errorm" : "This Battery Substance in combination with this Battery Model already exists."
    },
    "de" : {
        "substance-recycling-share-wizard.view.title" : "Neuen Wiederverwertbarkeitsanteil anlegen",
        "substance-recycling-share-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Wiederverwertbarkeitsanteils.",
        "substance-recycling-share-wizard.view.subheader.model" : "Batterie-Modell",
        "substance-recycling-share-wizard.view.subheader.substance" : "Batterie-Element",
        "battery-model-wizard.view.fields.schema.validation.notEmpty" : "Bitte ordnen Siedem Wiederverwertbarkeitsanteil ein Batterie-Modell zu.",
        "battery-substance-wizard.view.fields.schema.validation.notEmpty" : "Bitte ordnen Sie dem Wiederverwertbarkeitsanteil ein Batterie-Element zu.",
        "substance-recycling-share-wizard.view.subheader.primaryMaterialShare" : "Material-Anteil",
        "substance-recycling-share-wizard.view.subheader.preConsumerShare" : "Material-Anteil vor Nutzung",
        "substance-recycling-share-wizard.view.subheader.postConsumerShare" : "Material-Anteil nach Nutzung",
        "substances-recycling-share-editor.view.subheader.errorm" : "Dieses Batterie-Element mit diesem Batterie-Modell existiert bereits."
    }
}
</i18n>