<template>
    
  <div>
    <c-section
      :title="$t('substances-recycling-share-list.view.title')"
      :subtitle="$t('substances-recycling-share-list.view.subtitle')"
      class="secondary"
    >
      <v-card class="mt-5 mb-5" tile>
        <dxs-item-list-shares
          :title="$t('substances-recycling-share-list.view.toolbar.title')"
          :items="items"
          hideI18nNameColumn
          hideNameColumn
          hide
          :pagination="pagination"
          @refresh-list="refresh"
          @previous-page="onPreviousPage"
          @next-page="onNextPage"
          @item-added="onItemAdded"
          @item-edited="onItemEdited"
          @item-deleted="onItemDeleted"
          @route-substance-emitted="onSubstanceRoute"
          @route-model-emitted="onModelRoute"
          @item-list-filter-applied="onFilterChanged"

          :disable-add-item="!hasCreateRole"
          :disable-edit-item="!hasEditRole && !hasReadRole"
          :disable-delete-item="!hasDeleteRole"
          supports-delete-item
        >
        </dxs-item-list-shares>
        <v-card-text></v-card-text>
        <v-divider />
        <v-subheader class="grey lighten-5">{{
          $t("substances-recycling-share-list.view.help.title")
        }}</v-subheader>
        <v-card-text class="grey lighten-5">{{
          $t("substances-recycling-share-list.view.help.description")
        }}</v-card-text>
      </v-card>
    </c-section>
  </div>
  
</template>

<script>
import Roles from "@/roles";
import { get } from "lodash";

import SubstancesRecyclingShareListModule from "../../../store/masterdata/substance-recycling-share/substance-recycling-shares-list.module"
import DxsItemListShares from "../../../components/masterdata/battery-models/item-list-shares.component.vue";
const MODULE_ID = "SubstancesRecyclingSharesList";

export default {
  components: {"dxs-item-list-shares" : DxsItemListShares},
  name: "substances-recycling-share-list",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "userContextService",
  ],

  data: () => ({
    collectionSetting: {},

    displayConfirmationDialog: false,

    displayDeleteDialog: false,
  }),

  computed: {
    response() {
      return this.$store.getters[MODULE_ID + "/response"];
    },

    items() {
      return this.response.items;
    },

    pagination() {
      return get(this.response, "pagination", {});
    },

    hasReadRole() {
      return this.userContextService.hasAllRoles(Roles.RECYCLINGSHARE_READ);
    },

    hasEditRole() {
      return this.userContextService.hasAllRoles(Roles.RECYCLINGSHARE_EDIT);
    },

    hasCreateRole() {
      return this.userContextService.hasAllRoles(Roles.RECYCLINGSHARE_CREATE);
    },

    hasDeleteRole() {
      return this.userContextService.hasAllRoles(Roles.RECYCLINGSHARE_DELETE);
    },
  },

  methods: {
    async refresh() {
      this.loadPage(0, 50);
    },

    async onNextPage({ from, pageSize }) {
      this.loadPage(from, pageSize);
    },

    async onPreviousPage({ from, pageSize }) {
      this.loadPage(from, pageSize);
    },

    async loadPage(from, pageSize) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_ID + "/loadResponse", {
          from,
          pageSize,
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    onItemEdited(event) {
      if (event) {
        this.$router.push({
          name: "SubstancesRecyclingShareEditorView",
          params: {
            name: event.item.name,
          },
        });
      } else {
        this.$log.debug(
          "Event object is undefined. Can't navigate to prediction scenario."
        );
      }
    },
    onSubstanceRoute(event) {
      if (event) {
        this.$router.push({
          name: "BatterySubstanceEditorView",
          params: {
            name: event.item.references.batterysubstance,
          },
        });
      } else {
        this.$log.debug(
          "Event object is undefined. Can't navigate to prediction scenario."
        );
      }
    },
    onModelRoute(event) {
      if (event) {
        this.$router.push({
          name: "BatteryModelEditorView",
          params: {
            name: event.item.references.batterymodel,
          },
        });
      } else {
        this.$log.debug(
          "Event object is undefined. Can't navigate to prediction scenario."
        );
      }
    },

    onItemAdded() {
      this.$router.push({ name: "SubstancesRecyclingShareWizardView" });
    },

    async onItemDeleted({ item }) {
      this.progressIndicatorService.show();
      try {
        // Delete the item, then reload the list in order to reflect change.
        await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
        this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async onFilterChanged(event) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter);
        await this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    }
  },

  created() {
    // Dynamically register the vuex store module for managing the data.
    if (!this.$store.hasModule(MODULE_ID)) {
      this.$store.registerModule(MODULE_ID, SubstancesRecyclingShareListModule);
    }

    this.refresh();
  },
  destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_ID)) {
        this.$store.unregisterModule(MODULE_ID);
        this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
    }
  }
};
</script>

<i18n>
{
    "en" : {
        "substances-recycling-share-list.view.title" : "Substance Recycling Shares",
        "substances-recycling-share-list.view.subtitle" : "You may use this dialog for creating, editing or deleting Substance Recycling Shares.",
        "substances-recycling-share-list.view.toolbar.title" : "Substance Recycling Share",

        "substances-recycling-share-list.view.columns.open" : "Open",
        "substances-recycling-share-list.view.columns.apis" : "APIs",

        "substances-recycling-share-list.view.help.title" : "What are Substance Recycling Shares?",
        "substances-recycling-share-list.view.help.description" : "Substance Recycling Shares could be any kind of Battery Substance that might occure in a certain percentage in the battery, e.g. 2% Sodium Pre-Consumer-Recycling Share, 1.2% Sodium Post-Consumer-Recycling Share, 1.5% Material Share, etc."

    },

    "de" : {
        "substances-recycling-share-list.view.title" : "Verwaltung Wiederverwertbarkeitsanteile",
        "substances-recycling-share-list.view.subtitle" : "Sie können neue Wiederverwertbarkeitsanteile anlegen, einen Wiederverwertbarkeitsanteil editieren oder löschen.",
        "substances-recycling-share-list.view.toolbar.title" : "Wiederverwertbarkeitsanteile",

        "substances-recycling-share-list.view.columns.open" : "Öffnen",
        "substances-recycling-share-list.view.columns.apis" : "APIs",

        "substances-recycling-share-list.view.help.title" : "Was sind Wiederverwertbarkeitsanteile?",
        "substances-recycling-share-list.view.help.description" : "Wiederverwertbarkeitsanteile sind Batterie-Elemente, die in der Batterie in einem bestimmten Prozentsatz verbaut sind, z.B. 2% Natrium Anteil vor Nutzung, 1,2% Natrium Anteil nach Nutzung, 1,5% Materialanteil, etc."
        

    }
}
</i18n>