<template>
<div>
    <c-data-aggregation-configuration-panel
        v-model="settings"
        :schema="schema"
    >
        <c-data-group-result-panel
            :aggregation-settings="settings.aggregation"
            :filter-settings="settings.filter"
            :pagination="model.pagination"
            :items="model.items"
            :schema="schema">
        
        </c-data-group-result-panel>
            
         >
    </c-data-aggregation-configuration-panel>
</div>    
</template>

<script>
import CollectionAggregationModule from '@/core/store/modules/collections/collection-aggregation.module';

import { get } from 'lodash';

const MODULE_NAME = 'core:collections:collection-aggregation'; 

export default {
    
    name: 'collection-aggregation',

    props: {
    
        value: {
            type: Object,
            required: false,
        },
    },

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    data: () => ({
        
        view : {

            viewType : 'CHART'
        }

    }),

    methods: {

        async init(collectionId) {
            this.$log.debug(`Initializing view for collection ${collectionId}`);

            this.progressIndicatorService.show();

            try {
                // First make sure that the view is reset properly. 
                
                // Now reload data
                this.progressIndicatorService.updateText(this.$t('collection-aggregation.view.progress.metadata'));
                await this.$store.dispatch(`${MODULE_NAME}/loadMetadata`, { collectionId });


            } catch (error) {
                this.errorHandlerService.handleError(error);

            } finally {
                this.progressIndicatorService.hide();
            }

        }
    }, 

    computed: {
        
        collectionId() {
            return this.value ? this.value.collectionId : null;
        },

        collectionMetaData() {
            return this.$store.getters[`${MODULE_NAME}/collectionMetaData`];
        },

        schema() {
            return get(this.collectionMetaData, 'schema', { attributes: []}); 
        },

        model() {
            return this.$store.getters[`${MODULE_NAME}/model`];
        },

        settings() {
            return this.$store.getters[`${MODULE_NAME}/settings`];
        }
    },

    async created() {
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, CollectionAggregationModule);
            this.$log.debug(`Registered new Vuex Store ${MODULE_NAME}`);

        } else {
            this.$log.debug(`Vuex Store ${MODULE_NAME} is already registered.`)
        }        

       await this.init(this.collectionId);
    },

    destroyed() {
        if (this.$store.hasModule(MODULE_NAME)) {
            this.$store.unregisterModule(MODULE_NAME);
            this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`)
        }        
    }

}
</script>

<i18n>
{
    "en" : {

    },

    "de" : {

    }
}
</i18n>