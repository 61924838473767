<template>
<div>
    <c-sticky-content>
        <v-sheet color="error" class="pa-5 white--text" v-if="!hasCollectionSettingsReadRole"> 
            <v-icon color="white" class="pr-5">warning</v-icon> {{$t('upload-wizard.view.warning.collectionSettingsReadRoleMissing')}}
        </v-sheet>
    </c-sticky-content>
  <v-container fluid class="secondary pt-10">
    <!-- Upload Area -->
    <v-row justify="center">
      <v-col cols="12" lg="8">
          <v-card tile>
            <v-card-text>
              <div class="title">{{ $t("upload-wizard.view.title") }}</div>
              <div class="subheader">{{ $t("upload-wizard.view.subtitle") }}</div>
            </v-card-text>
            <v-divider />
              <v-card-text>
                <c-collection-settings-selector
                  @input="setSelectedCollectionSetting"
                  :items="collectionSettings"
                  :value="{}"
                  filled
                  :label="$t('upload-wizard.view.fields.targetCollection')"
                  :rules="[(v) =>(v !== null && v !== undefined) || $t('upload-wizard.view.messages.targetCollectionId.notEmpty')]" />

                  <c-item-selector
                  v-model="selectedFileFormat"
                  :items="fileFormats"
                  filled
                  :label="$t('upload-wizard.view.fields.fileFormat')"
                  />
                    
                <v-file-input
                  v-model="selectedFile" truncate-length="95" accept=".csv,.txt,.json" show-size full-width filled
                  prepend-icon=""
                  prepend-inner-icon="attach_file"
                  :placeholder="$t('upload-wizard.view.fields.file')"
                  :rules="[ (v) => (v !== null && v !== undefined) || $t('upload-wizard.view.messages.upload.noFile')]" 
                  :hint="$t('upload-wizard.view.messages.upload.hint.size')"
                  persistent-hint
                />         
              </v-card-text>
              <!----------------- FAQs ---------------------->
              <v-card-text tile>
                <v-expansion-panels class="mb-6" :popout="false">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div><v-icon color="accent" class="mr-2">help</v-icon>{{$t("upload-wizard.view.faqs.title")}}</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="pa-10">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.fileformat.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.fileformat.a")}}</div>
                          </div>
                          <v-divider />
                          <div class="pa-10" v-if="selectedFileFormat === 'CSV-UTF8' || 'CSV-WINDOWS1252'">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.separator.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.separator.a")}}</div>
                          </div>
                          <div class="pa-10" v-if="selectedFileFormat === 'JSON'">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.separator.json.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.separator.json.a")}}</div>
                          </div>
                          <v-divider />
                          <div class="pa-10">
                            <div class="text-body2 font-weight-bold"> {{$t("upload-wizard.view.faqs.decimalseparator.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.decimalseparator.a")}}</div>
                          </div>
                          <v-divider />
                          <div class="pa-10" v-if="selectedFileFormat === 'CSV-UTF8' || 'CSV-WINDOWS1252'">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.csvcontent.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.csvcontent.a")}}</div>
                          </div>
                          <v-divider />
                          <div class="pa-10">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.csvformat.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.csvformat.a")}}</div>
                          </div>
                          <div class="pa-10" v-if="selectedFileFormat === 'JSON'">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.jsoncontent.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.jsoncontent.a")}}</div>
                          </div>
                          <v-divider />
                          <div class="pa-10">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.attributenames.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.attributenames.a")}}</div>
                          </div>
                          <v-divider />
                          <div class="pa-10">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.datetype.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.datetype.a")}}</div>
                          </div>
                          <v-divider />
                          <div class="pa-10">
                            <div class="text-body2 font-weight-bold">{{$t("upload-wizard.view.faqs.datetime.q")}}</div>
                            <div class="text-body2">{{$t("upload-wizard.view.faqs.datetime.a")}}</div>
                          </div>
                         
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>

            <v-card-actions>
              <v-btn depressed tile block color="primary lighten-1" @click="onSubmit" :disabled="!uploadReady">
                  <v-icon>cloud_upload</v-icon>&nbsp; Upload</v-btn>
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>
    <!-- Result Overview Area-->
    <v-row justify="center" v-if="totalItems > 0 || errorMessage">
      <v-col cols="12" md="8">     
        <v-card tile>
          <v-card-text>
            <div class="title">{{ $t("upload-wizard.view.result.overview") }}</div>
          </v-card-text>
          <v-container center v-if="errorMessage">
             <div height="100%">
                    <v-card class="pa-3" color="error" height="100%">
                    <v-card-text>
                      <div class="text-h5 white--text text-left">{{$t("upload-wizard.view.result.error")}}</div>
                    </v-card-text>
                    <v-card-text class="pa-3">
                      <div class="text-h6 white--text text-left">{{ errorMessage }}</div>
                    </v-card-text>
                    </v-card>
                  </div>
          </v-container>
          <v-divider />
          <v-container center >
            <!-- three v-cards -->
            <v-container class="mb-6">
              <v-row justify="center">
                <v-col>
                  <v-card class="pa-2">
                    <v-card-text>
                      <div class="text-h6"> {{$t("upload-wizard.view.result.total")}} </div>
                    </v-card-text>
                    <v-card-text> <div class="text-h4 blue--text text-end"> {{ totalItems }} </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="pa-2">
                    <v-card-text>
                      <div class="text-h6"> {{$t("upload-wizard.view.result.uploaded")}}</div>
                    </v-card-text>
                    <v-card-text>
                      <div class="text-h4 green--text text-right">{{ uploadedItems }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  
                  <div v-if="failedItemsCount !== 0" height="100%">
                    <v-card class="pa-3" color="error" height="100%">
                    <v-card-text>
                      <div class="text-h6 h4 white--text">{{$t("upload-wizard.view.result.notuploaded")}}</div>
                    </v-card-text>
                    <v-card-text class="pa-3">
                      <div class="text-h4 white--text text-right">{{ failedItemsCount }}</div>
                    </v-card-text>
                    </v-card>
                  </div>

                  <div v-if="failedItemsCount === 0">
                  <v-card class="pa-2" >
                    <v-card-text>
                      <div class="text-h6 grey--text">{{$t("upload-wizard.view.result.notuploaded")}}</div>
                    </v-card-text>
                    <v-card-text >
                      <div class="text-h4 grey--text text-right">{{ failedItemsCount }}</div>
                    </v-card-text>
                  </v-card>  
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <!-- Only for restricted devices-->
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-divider/>
                <div v-if="failedItemsCount === 0 && !errorMessage" class="mb-5">
                  <p class="text-h6 text-left">{{$t("upload-wizard.view.result.table.sucessMessage")}}
                  </p>
                   &nbsp; <v-btn class="white--text" absolute right dark color="accent" v-bind="attrs" v-on="on" @click="switchToCollection"><v-icon left>open_in_new</v-icon>{{ $t("upload-wizard.view.result.open.icon") }}</v-btn>
                </div>
              <v-simple-table class="mb-5" v-for="item in failedItems" v-bind:key="item.rowNumber">
                <thead>
                  <tr> <th>{{$t("upload-wizard.view.result.table.rowNumber")}}</th>
                       <th class="text-right">{{ item.rowNumber }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr> <td>{{$t("upload-wizard.view.result.table.errorMessage")}}</td>
                       <td class="text-right">{{ item.errorMessage }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>
            <!-- For regular devices use tabular layout -->
            <template v-else>
              <v-divider/>
              <v-subheader v-if="failedItemsCount !== 0">{{$t('upload-wizard.view.result.error.overview')}}</v-subheader>
              <v-divider/>
              <v-simple-table fixed-header border="2" class="mb-6">
                <thead v-if="failedItemsCount !== 0">
                  <tr>
                    <th>{{$t("upload-wizard.view.result.table.rowNumber")}}</th>
                    <th>{{$t("upload-wizard.view.result.table.errorMessage")}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in failedItems" v-bind:key="item.rowNumber">
                    <td class="d-none d-sm-table-cell">{{ item.rowNumber }}</td>
                    <td class="d-none d-sm-table-cell">{{ item.errorMessage }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <div v-if="failedItemsCount == 0 && !errorMessage">
                <p  class="text-h6 text-center">{{$t("upload-wizard.view.result.table.sucessMessage")}} &nbsp; <v-btn class="white--text" absolute right dark color="accent" v-bind="attrs" v-on="on" @click="switchToCollection"><v-icon left>open_in_new</v-icon>{{ $t("upload-wizard.view.result.open.icon") }}</v-btn>
                </p>
              </div>
            </template>

            <v-spacer/>

            <div class="mt-15" v-if="failedItemsCount !== 0 && deadLetterDocumentsCount == 0" >
            <v-btn class="white--text" absolute right bottom dark color="accent" v-bind="attrs" v-on="on" @click="switchToCollection"><v-icon left>open_in_new</v-icon>{{$t("upload-wizard.view.result.open.icon")}}</v-btn>
            </div>

            <div v-if="deadLetterDocumentsCount !== 0">
              <v-btn class="white--text" absolute right top dark color="accent" v-bind="attrs" v-on="on" @click="switchToCollection"><v-icon left>open_in_new</v-icon>{{$t("upload-wizard.view.result.open.icon")}}</v-btn>
              <v-card class="pa-5" >
                <v-card-title class="text-h5 red--text">{{$t("upload-wizard.view.result.deadletter.title")}}</v-card-title>
                <v-card-text>
                <div>{{$t("upload-wizard.view.result.deadletter.content")}} </div>
                <div class="mb-5">{{$t("upload-wizard.view.result.deadletter.subcontent")}} </div>
                </v-card-text>
                <v-btn class="white--text" absolute right bottom dark color="accent" v-bind="attrs" v-on="on" @click="switchToDeadLetter"><v-icon left>open_in_new</v-icon>{{$t("upload-wizard.view.result.deadletter.icon.text")}}</v-btn>
            </v-card>     
            </div>
          </v-container>
        </v-card>
      </v-col>
      
    </v-row>
  </v-container>
</div>
</template>

<script>
const MODULE_NAME = "uploadFile";
import Roles from '@/roles';

export default {
  inject: ["errorHandlerService", "progressIndicatorService", "userContextService", "collectionPermissionService"],

  data() {
    return {
      fileName: '',
      selectedFile: null,
      selectedCollection: null,
      selectedFileFormat: null,
      charset: null
    };
  },

  watch: {
    selectedFile(file) {
      if (file) { // Ensure there's a file selected
          this.$store.dispatch(MODULE_NAME + "/updateData", { data: file });

          if (this.selectedFileFormat === 'CSV-UTF8' || this.selectedFileFormat === 'CSV-WINDOWS1252') {
            this.fileName = file.name.slice(0, -4);
          } else if (this.selectedFileFormat === 'JSON') {
            this.fileName = file.name.slice(0, -5);
          }
  
      }
    }
  },


  computed: {

    hasCollectionSettingsReadRole() {
         return this.userContextService.hasAllRoles(Roles.COLLECTION_SETTINGS_READ);
    },
    
    response() {
      let response = this.$store.getters[MODULE_NAME + '/response'];
      return response;
    },
    
    collectionSettings() {

        const collectionSettings = this.$store.getters[MODULE_NAME + '/collectionSettings'];
        
        // Apply filtering - only allow uploads to collections which are permitted for upload.
        const filteredCollectionSettings = collectionSettings.filter( e => this.collectionPermissionService.hasImportPermission({collectionId: e.collectionId}));

        return filteredCollectionSettings

      
    },

    fileFormats() {
      return [
              {name: 'CSV-UTF8', i18n: { en: { name: 'CSV UTF-8 (with ; separator)'}, de: { name: 'CSV UTF-8 (mit Trennzeichen ;)'}}}, 
              {name: 'CSV-WINDOWS1252', i18n: { en: { name: 'CSV Windows 1252 (for Excel)'}, de: { name: 'CSV Windows 1252 (für Excel)'}}},
              {name: 'JSON',  i18n: { en: { name: 'Pandas Data Frame (JSON)'}}}
            ]
    },
    totalItems() {
      return this.response.totalItems ? this.response.totalItems : 0;
    },
    uploadedItems() {
      return this.totalItems - this.failedItemsCount;
    },
    failedItemsCount() {
      let items = this.response.failedRecords ? this.response.failedRecords : [];
      let count = 0;
      if (items) {
        count = items.length;
      }
      return count;
    },
    failedItems() {
      return this.response.failedRecords ? this.response.failedRecords : [];
    },
    deadLetterDocumentsCount() {
      return this.response.deadLetterDocuments ? this.response.deadLetterDocuments : 0;
    },
    uploadReady() {
      return this.selectedFile && this.selectedCollection;
    },
    errorMessage() {
      return this.response.errorMessage ? this.response.errorMessage : null;
    }
  },

  methods: {
    async refresh() {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_NAME + '/loadCollectionSettings');
        await this.$store.dispatch(MODULE_NAME + '/loadResponse');
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },
    setSelectedCollectionSetting(itemName) {
      this.selectedCollection = itemName;
      this.$store.commit(MODULE_NAME + '/setSelectedCollectionSetting', {itemName});
    },
    setSelectedFileFormat(itemName) {
      this.selectedFileFormat = itemName;
      let charset = null;
      if(itemName === "CSV-WINDOWS1252") {
        charset = "windows-1252";
        this.charset = charset;
      }else{
        charset = "utf-8";
        this.charset = charset;
      }
      
      this.$store.commit(MODULE_NAME + '/setSelectedFileFormat', {itemName});
      this.$store.commit(MODULE_NAME + '/setCharset', {charset});
    },
    async onSubmit() {
      this.progressIndicatorService.show();
      this.setSelectedFileFormat(this.selectedFileFormat);
      try {
        await this.$store.dispatch(MODULE_NAME + '/updateFileName', this.fileName); 
        await this.$store.dispatch(MODULE_NAME + '/uploadFile');
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },
    switchToCollection() {
      if (this.selectedCollection) {
        try { 
          let routeData = this.$router.resolve({path: '/collections/' + this.selectedCollection + '/documents'})
          window.open(routeData.href);
        } catch(error){
          this.$router.push({path: '/collections/' + this.selectedCollection + '/documents' })
          this.errorHandlerService.handleError(error);
        }
        
      }
    },
    switchToDeadLetter() {
      this.$router.push({name: "deadLetterList"});
    },
  },
  created() {
    this.refresh();
  },
};
</script>

<i18n>
{
    "en" : {

        "upload-wizard.view.warning.collectionSettingsReadRoleMissing" : "The role amentis-dxs-catalog-collectionsettings-read  is not assigned to your user account. The dialog can therefore only be used with restrictions. In order to obtain the role, please contact to your admin.",

        "upload-wizard.view.title" : "Upload File",
        "upload-wizard.view.subtitle" : "This wizard will guide you through the process of uploading the content of self contained files into an existing collection. The file content will be appended to the existing documents, i.e. existing documents might be updated.",
        "upload-wizard.view.fields.targetCollection" : "Target Collection",
        "upload-wizard.view.fields.fileFormat" : "File Format",
        "upload-wizard.view.fields.file" : "Select file",
        "upload-wizard.view.fields.hint.title" : "Hint",
        "upload-wizard.view.fields.hint.subtitle" : "The file upload is executed in the background. The system will inform you on its outcome by means of sending a notification to your inbox.",
        "upload-wizard.view.messages.targetCollectionId.notEmpty" : "The target collection must not be empty. Please select a target collection.",
        "upload-wizard.view.messages.fileFormat.notEmpty" : "The file format must not be empty. Please select a file format.",
        "upload-wizard.view.messages.upload.noFile" : "Please select a file",
        "upload-wizard.view.messages.upload.hint.size": "Supported file-size: max. 50MB (Split up the file if it exceeds the size)",
        "upload-wizard.view.result.error": "Error",
        "upload-wizard.view.result.overview" : "Result Overview",
        "upload-wizard.view.result.error.overview" : "Error Overview",
        "upload-wizard.view.result.total" : "Total rows",
        "upload-wizard.view.result.uploaded" : "Uploaded rows",
        "upload-wizard.view.result.notuploaded" : "Not uploaded rows",
        "upload-wizard.view.result.table.rowNumber" : "Row Number",
        "upload-wizard.view.result.table.errorMessage" : "Cause of failure",
        "upload-wizard.view.result.table.sucessMessage" : "All rows are successfully uploaded!",
        "upload-wizard.view.result.open.icon": "open collection",
        "upload-wizard.view.result.deadletter.title": "Warning",
        "upload-wizard.view.result.deadletter.content": "There are some documents that could not be imported. These are located in navigation bar: Data Integration / Invalid Documents.",
        "upload-wizard.view.result.deadletter.subcontent" : "Click the button to view and edit them if necessary",
        "upload-wizard.view.result.deadletter.icon.text" : "open invalid documents",

        "upload-wizard.view.faqs.title": "Supported File Formats",
        "upload-wizard.view.faqs.fileformat.q" : "What file format must my file have?",
        "upload-wizard.view.faqs.fileformat.a" : "Currently the system supports CSV (UTF-8[Text] / Windows-1252[Excel]) & JSON file format. Select a file format to find further information to the specific format.",
        "upload-wizard.view.faqs.separator.q" : "Which column separator must my CSV file have?",
        "upload-wizard.view.faqs.separator.a" : "As separator \uFF1Bis supported. No separator is required after the last column of each row.",
        "upload-wizard.view.faqs.decimalseparator.q" : "Which decimal separator must decimal numbers have?",
        "upload-wizard.view.faqs.decimalseparator.a" : "The . must be used as the decimal separator. The number of fractional digits is not limited (e.g.: 12.34567).",
        "upload-wizard.view.faqs.csvcontent.q" : "What content must the CSV file have?",
        "upload-wizard.view.faqs.csvcontent.a" : "The first row of the file muss be a list of attribute names. The names must be equivalent to the attribute names of the schema (e.g.: id,productName,productNumber). Starting from the second line, the data to be read in may be contained. Empty lines are ignored during reading.",
        "upload-wizard.view.faqs.attributenames.q" : "Am I required to use upper/lower case for attribute names?",
        "upload-wizard.view.faqs.attributenames.a" : "No, the system can read the names case-insensitively, e.g. ProductNaMe is accepted.",
        "upload-wizard.view.faqs.datetype.q" : "Do I have to follow certain rules for date types?",
        "upload-wizard.view.faqs.datetype.a" : "Yes, the supported format is an ISO 8601 compliant string: YYYY-MM-DD (e.g. 2021-06-13).",
        "upload-wizard.view.faqs.datetime.q" : "Do I have to follow certain rules for timestamp?",
        "upload-wizard.view.faqs.datetime.a" : "Yes, the supported format is an ISO 8601 compliant string. (e.g. 2021-06-13T16:27:50 in the UTC time zone, 2021-06-13T16:27:15+02:00 in the Berlin time zone (CET) with daylight saving time and additional specification of seconds).",
    
        "upload-wizard.view.faqs.separator.json.q" : "Which column separator must my JSON file have?",
        "upload-wizard.view.faqs.separator.json.a" : "As separator \u002Cis supported. No separator is required after the last column of each row. (Valid JSON)",
        "upload-wizard.view.faqs.jsoncontent.q" : "What content must the JSON file have?",
        "upload-wizard.view.faqs.jsoncontent.a" : "The file must be valid JSON. The structure of the JSON is an index number per row, followed by the attribute(column) names and values. Example: \"$index_0\" : { \"$columnName_0\" : $value[$columnName_0, $index_0], ... , \"$columnName_m\" : $value[$columnName_m, $index_0]  }",
    
        "upload-wizard.view.faqs.csvformat.q" : "What's the difference between file format CSV UTF-8 and CSV Windows-1252?",
        "upload-wizard.view.faqs.csvformat.a" : "CSV UTF-8 is for files which were saved through an editor other than excel. Excel uses a different encoding mechanism. CSV Windows-1252 is for files which were edited and saved through excel. This distinction is important for special characters like umlauts etc."
    },
    "de" : {

        "upload-wizard.view.warning.collectionSettingsReadRoleMissing" : "Die Rolle amentis-dxs-catalog-collectionsettings-read ist Ihrem Benuterkonto aktuell nicht zugewiesen. Die Benutzung des Dialogs ist nur eingeschränkt möglich. Bitte wenden Sie sich an Ihren Systembetreuer, um die Rolle zu erhalten.",
        "upload-wizard.view.title" : "Datei hochladen",
        "upload-wizard.view.subtitle" : "Dieser Assistent führt Sie durch den Prozess des Hochladens des Inhalts eigenständiger Dateien in eine vorhandene Sammlung. Der Dateiinhalt wird an die bestehenden Dokumente angehängt, d.h. bestehende Dokumente können aktualisiert werden.",
        "upload-wizard.view.fields.targetCollection" : "Ziel-Datenbereich",
        "upload-wizard.view.fields.fileFormat" : "Dateiformat",
        "upload-wizard.view.fields.file" : "Datei auswählen",
        "upload-wizard.view.fields.hint.title" : "Hinweis",
        "upload-wizard.view.fields.hint.subtitle" : "Das Datei-Hochladen wird im Hintergrund ausgeführt. Das System informiert Sie über das Ergebnis, indem es eine Benachrichtigung an Ihren Posteingang sendet.",
        "upload-wizard.view.messages.targetCollectionId.notEmpty" : "Der Ziel-Datenbereich darf nicht leer sein. Bitte wählen Sie einen Datenbereich aus.",
        "upload-wizard.view.messages.fileFormat.notEmpty" : "Das Dateiformat Feld darf nicht leer sein. Bitte wählen Sie ein Datenformat aus.",
        "upload-wizard.view.messages.upload.noFile" : "Bitte eine Datei auswählen",
        "upload-wizard.view.messages.upload.hint.size": "Unterstütze Dateigröße: max. 50MB (Teilen sie die Datei auf, wenn es die Größe überschreitet)",
        "upload-wizard.view.result.error": "Fehler",
        "upload-wizard.view.result.overview" : "Ergebnisübersicht",
        "upload-wizard.view.result.error.overview" : "Fehlerübersicht",
        "upload-wizard.view.result.total" : "Gesamtzeilen",
        "upload-wizard.view.result.uploaded" : "Hochgeladen",
        "upload-wizard.view.result.notuploaded" : "Nicht hochgeladen",
        "upload-wizard.view.result.table.rowNumber" : "Zeilennummer",
        "upload-wizard.view.result.table.errorMessage" : "Fehlerursache",
        "upload-wizard.view.result.table.sucessMessage" : "Alle Zeilen wurden erfolgreich hochgeladen!",
        "upload-wizard.view.result.open.icon": "Datenbereich öffnen",
        "upload-wizard.view.result.deadletter.title": "Warnung",
        "upload-wizard.view.result.deadletter.content": "Einige Dokumente konnten nicht importiert werden. Diese befinden sich in der Navigationsleiste: Datenintegration / Ungültige Dokumente.",
        "upload-wizard.view.result.deadletter.subcontent" : "Klicken Sie auf die Taste, um sich die Dokumente anzusehen und diese gegebenenfalls zu bearbeiten.",
        "upload-wizard.view.result.deadletter.icon.text" : "Nicht zustellbare Dokumente",

        "upload-wizard.view.faqs.title": "Unterstützte Dateiformate",
        "upload-wizard.view.faqs.fileformat.q" : "Welches Dateiformat muss meine Datei haben?",
        "upload-wizard.view.faqs.fileformat.a" : "Aktuell unterstützt das System das CSV (UTF-8[Text] / Windows-1252[Excel]) & JSON-Dateiformat. Wählen sie ein Dateiformat aus um zusätzliche Informationen spezifisch zum Format zu erhalten.",
        "upload-wizard.view.faqs.separator.q" : "Welches Spalten-Trennzeichen muss meine CSV-Datei haben?",
        "upload-wizard.view.faqs.separator.a" : "Als Trennzeichen wird \uFF1B unterstützt. Nach der letzten Spalte einer jeden Zeile wird kein Trennzeichen benötigt.",
        "upload-wizard.view.faqs.decimalseparator.q" : "Welches Dezimaltrennzeichen müssen Dezimalzahlen haben?",
        "upload-wizard.view.faqs.decimalseparator.a" : "Das . muss als Dezimaltrennzeichen verwendet werden. Die Anzahl der Nachkommastellen ist nicht begrenzt (z.B.: 12.34567).",
        "upload-wizard.view.faqs.csvcontent.q" : "Welche Inhalte muss die CSV-Datei haben?",
        "upload-wizard.view.faqs.csvcontent.a" : "Die erste Zeile der Datei muss eine Liste von Attributnamen sein. Die Namen müssen mit den Attributnamen des Schemas übereinstimmen. (z.B.: id,productName,productNumber). Ab der zweiten Zeile können die einzulesenden Daten beinhaltet sein. Leere Zeilen werden beim Einlesen ignoriert.",
        "upload-wizard.view.faqs.attributenames.q" : "Muss ich Groß-/Kleinschreibung bei den Attributnamen beachten?",
        "upload-wizard.view.faqs.attributenames.a" : "Nein, das System kann die Attributnamen ohne Rücksicht auf Groß- und Kleinschreibung lesen, z.B. wird ProductNaMe akzeptiert.",
        "upload-wizard.view.faqs.datetype.q" : "Muss ich bestimmte Regeln bei Datumsangaben beachten?",
        "upload-wizard.view.faqs.datetype.a" : "Ja, das unterstützte Format ist ein ISO 8601 konformer String: JJJJ-MM-TT (z.B. 2021-06-13).",
        "upload-wizard.view.faqs.datetime.q" : "Muss ich bestimmte Regeln bei Zeitstempeln beachten?",
        "upload-wizard.view.faqs.datetime.a" : "Ja, das unterstützte Format ist ein ISO 8601 konformer String. (z.B. 2021-06-13T16:27:50 in der UTC-Zeitzone, 2021-06-13T16:27:15+02:00 in der Zeitzone Berlin (CET) bei Sommerzeit und zusätzliche Angabe von Sekunden).",
        
        "upload-wizard.view.faqs.separator.json.q" : "Welches Spalten-Trennzeichen muss meine JSON-Datei haben?",
        "upload-wizard.view.faqs.separator.json.a" : "Als Trennzeichen wird \u002c unterstützt. Nach der letzten Spalte einer jeden Zeile wird kein Trennzeichen benötigt. (Valides JSON)",
        "upload-wizard.view.faqs.jsoncontent.q" : "Welche Inhalte muss die JSON-Datei haben?",
        "upload-wizard.view.faqs.jsoncontent.a" : "Die Datei muss valides JSON beinhalten. Das JSON besteht aus einer Indexnummer am Anfang jeder Reihe, gefolgt von den Attributnamen (Spalten) und Werten. Beispiel: \"$index_0\" : { \"$spaltenName_0\" : $wert[$spaltenName_0, $index_0], ... , \"$spaltenName_m\" : $wert[$spaltenName_m, $index_0]  }",

        "upload-wizard.view.faqs.csvformat.q" : "Was ist der Unterschied zwischen Dateiformat CSV UTF-8 und CSV Windows-1252?",
        "upload-wizard.view.faqs.csvformat.a" : "CSV UTF-8 ist für Dateien, die mit einem anderen Editor als Excel gespeichert wurden. Excel verwendet einen anderen Kodierungsmechanismus. CSV Windows-1252 ist für Dateien, die mit Excel bearbeitet und gespeichert wurden. Diese Unterscheidung ist wichtig für Sonderzeichen wie Umlaute usw."
}
}

</i18n>