<template>
    
    <div>
      <c-section
        :title="$t('battery-chemistries-list.view.title')"
        :subtitle="$t('battery-chemistries-list.view.subtitle')"
        class="secondary"
      >
        <v-card class="mt-5 mb-5" tile>
          <c-item-list
            :title="$t('battery-chemistries-list.view.toolbar.title')"
            :items="items"
            :pagination="pagination"
            @refresh-list="refresh"
            @previous-page="onPreviousPage"
            @next-page="onNextPage"
            @item-added="onItemAdded"
            @item-edited="onItemEdited"
            @item-deleted="onItemDeleted"
            @item-list-filter-applied="onFilterChanged"
  
            :disable-add-item="!hasCreateRole"
            :disable-edit-item="!hasEditRole && !hasReadRole"
            :disable-delete-item="!hasDeleteRole"
            supports-delete-item
          >
          </c-item-list>
          <v-card-text></v-card-text>
          <v-divider />
          <v-subheader class="grey lighten-5">{{$t("battery-chemistries-list.view.help.title")}}</v-subheader>
          <v-card-text class="grey lighten-5">{{$t("battery-chemistries-list.view.help.description")}}</v-card-text>
        </v-card>
      </c-section>
    </div>
  </template>
  
  <script>
  import Roles from "@/roles";
  import { get } from "lodash";
  
  import BatteryChemistryListModule from '@/solutions/battery-data-hub/store/masterdata/battery-chemistries/battery-chemistries-list.module';
  
  const MODULE_ID = 'battery-data-hub:masterdata:battery-chemistries:battery-chemistries-list';
  
  export default {
    name: "battery-categories-list",
  
    inject: [
      "errorHandlerService",
      "progressIndicatorService",
      "userContextService",
    ],
  
    data: () => ({
      collectionSetting: {},
  
      displayConfirmationDialog: false,
  
      displayDeleteDialog: false,
    }),
  
    computed: {
      response() {
        return this.$store.getters[MODULE_ID + "/response"];
      },
  
      items() {
        return this.response.items;
      },
  
      pagination() {
        return get(this.response, "pagination", {});
      },
  
      hasReadRole() {
        return this.userContextService.hasAllRoles(Roles.BATTERYCHEMISTRY_READ);
      },
  
      hasEditRole() {
        return this.userContextService.hasAllRoles(Roles.BATTERYCHEMISTRY_EDIT);
      },
  
      hasCreateRole() {
        return this.userContextService.hasAllRoles(Roles.BATTERYCHEMISTRY_CREATE);
      },
  
      hasDeleteRole() {
        return this.userContextService.hasAllRoles(Roles.BATTERYCHEMISTRY_DELETE);
      },
    },
  
    methods: {
      async refresh() {
        this.loadPage(0, 50);
      },
  
      async onNextPage({ from, pageSize }) {
        this.loadPage(from, pageSize);
      },
  
      async onPreviousPage({ from, pageSize }) {
        this.loadPage(from, pageSize);
      },
  
      async loadPage(from, pageSize) {
        this.progressIndicatorService.show();
        try {
          await this.$store.dispatch(MODULE_ID + "/loadResponse", {
            from,
            pageSize,
          });
        } catch (error) {
          this.errorHandlerService.handleError(error);
        } finally {
          this.progressIndicatorService.hide();
        }
      },
  
      onItemEdited(event) {
        if (event) {
          this.$router.push({
            name: "BatteryChemistryEditorView",
            params: {
              name: event.item.name,
            },
          });
        } else {
          this.$log.debug(
            "Event object is undefined. Can't navigate to prediction scenario."
          );
        }
      },
  
      onItemAdded() {
        this.$router.push({ name: "BatteryChemistryWizardView" });
      },
  
      async onItemDeleted({ item }) {
        this.progressIndicatorService.show();
        try {
          // Delete the item, then reload the list in order to reflect change.
          await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
          this.refresh();
        } catch (error) {
          this.errorHandlerService.handleError(error);
        } finally {
          this.progressIndicatorService.hide();
        }
      },
  
      async onFilterChanged(event) {
        this.progressIndicatorService.show();
        try {
          await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter);
          await this.refresh();
        } catch (error) {
          this.errorHandlerService.handleError(error);
        } finally {
          this.progressIndicatorService.hide();
        }
      }
    },
    beforeUnmount(){
      this.$store.unregisterModule(MODULE_ID)
    },
    created() {
      // Dynamically register the vuex store module for managing the data.
      if (!this.$store.hasModule(MODULE_ID)) {
        this.$store.registerModule(MODULE_ID, BatteryChemistryListModule);
      }
  
      this.refresh();
    },
    destroyed() {
      // Unregister the vuex store if it has been registered before.
      if (this.$store.hasModule(MODULE_ID)) {
          this.$store.unregisterModule(MODULE_ID);
          this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
      }
    }
  };
  </script>
  
  <i18n>
  {
      "en" : {
          "battery-chemistries-list.view.title" : "Battery Chemistries",
          "battery-chemistries-list.view.subtitle" : "You may use this dialog for creating, editing or deleting battery chemistries.",
          "battery-chemistries-list.view.toolbar.title" : "Battery Chemistries",
  
          "battery-chemistries-list.view.columns.open" : "Open",
          "battery-chemistries-list.view.columns.apis" : "APIs",
  
          "battery-chemistries-list.view.help.title" : "What are Battery Chemistries?",
          "battery-chemistries-list.view.help.description" : "Battery chemistry refers to the specific chemical compositions and reactions that take place in a battery to store and release electrical energy."
  
      },
  
      "de" : {
          "battery-chemistries-list.view.title" : "Verwaltung Batterie-Chemien",
          "battery-chemistries-list.view.subtitle" : "Sie können neue Batterie-Chemien anlegen, eine Batterie-Chemie editieren oder löschen.",
          "battery-chemistries-list.view.toolbar.title" : "Batterie-Chemie",
  
          "battery-chemistries-list.view.columns.open" : "Öffnen",
          "battery-chemistries-list.view.columns.apis" : "APIs",
  
          "battery-chemistries-list.view.help.title" : "Was sind Batterie-Chemien?",
          "battery-chemistries-list.view.help.description" : "Batterie-Chemie bezieht sich auf die spezifischen chemischen Zusammensetzungen und Reaktionen, die in einer Batterie stattfinden, um elektrische Energie zu speichern und abzugeben."
  
      }
  }
  </i18n>