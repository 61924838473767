<template>
    <v-card>
        <v-card-text>
        <div v-if="!$vuetify.breakpoint.xsOnly" :key="9">
        <v-btn absolute dark right color="accent" @click="onAddItem" :disabled="!hasEditRole">{{ $t('safety-instruction-editor.view.tabs.details.add') }}<v-icon>add</v-icon></v-btn>
        </div>
        <div v-else :key="8">
        <v-btn absolute fab dark right color="accent" @click="onAddItem" :disabled="!hasEditRole"><v-icon>add</v-icon></v-btn>
        </div><br><br>
          <!-- Searchbar als Folgestory anlegen
          <v-sheet color="grey lighten-5 pa-5" v-if="supportsFilterItems">
              <v-text-field solo flat background-color="grey lighten-2" v-model="filter" :hide-details="true" :label="$t('paginated-item-list-page.component.filter.label')">
                  <template v-slot:prepend-inner>
                      <v-icon v-if="$vuetify.breakpoint.smAndUp">search</v-icon>
                  </template>
                  <template v-slot:append>
                      <v-btn color="white" small class="align-self-center" :elevation="0"  @click="onFilterApplied">{{ $t($vuetify.breakpoint.xsOnly ? 'paginated-item-list-page.component.actions.applyFilter.xs' : 'paginated-item-list-page.component.actions.applyFilter') }}</v-btn>
                  </template>
              </v-text-field>
          </v-sheet>
      -->
  
          <!-- Content -->
          <template v-if="true">
              <slot name="paginated-item-list-page" v-bind:items="itemList">
                  <template v-if="$vuetify.breakpoint.mdAndUp">
                            <v-list one-line>
                                <template v-for="(dat,locale) in itemList">
                                    <v-list-item v-if="locale&&hasEditRole" :key=locale> 
                                        <v-list-item-avatar >
                                            <v-btn @click="showEditView({dat,locale})" icon><div v-if="showFlags"><div>{{ locale }}</div>
                                                        <vf-icon :country="locale === 'en'?'gb':locale"> </vf-icon>
                                                        <vf-icon :country="locale === 'en'?'us':locale" v-if="locale === 'en'"> </vf-icon>
                                                    </div>
                                                    <div v-else>{{ locale }}</div></v-btn>
                                                </v-list-item-avatar>    
                                                <v-list-item-content >                                                 
                                                <v-list-item-title @click="showEditSingleView({dat,locale,focus:'summary'})" outlined v-html="dat[Object.keys(dat)[0]]" style="max-width:75%;"></v-list-item-title>
                                                <v-list-item-subtitle @click="showEditSingleView({dat,locale,focus:'description'})" v-html="dat[Object.keys(dat)[1]]" style="max-width:75%;"></v-list-item-subtitle>
                                                
                                        </v-list-item-content> 
                                        <v-btn icon @click="showEditView({dat,locale})" :disabled="disableEditItem"><v-icon>{{editItemIcon}}</v-icon></v-btn>
                                        <v-btn icon @click="onDelete({dat:dat,locale:locale})" :disabled="disableDeleteItem"><v-icon>{{deleteItemIcon}}</v-icon></v-btn>
                                        
                                        </v-list-item>
                                    <v-list-item v-if="locale&&!hasEditRole" :key=locale> 
                                        <v-list-item-avatar >
                                            <v-btn disabled icon><div v-if="showFlags"><div>{{ locale }}</div>
                                                        <vf-icon :country="locale === 'en'?'gb':locale"> </vf-icon>
                                                        <vf-icon :country="locale === 'en'?'us':locale" v-if="locale === 'en'"> </vf-icon>
                                                    </div>
                                                    <div v-else>{{ locale }}</div></v-btn>
                                                </v-list-item-avatar>    
                                                <v-list-item-content >                                                 
                                                <v-list-item-title >{{ $t('paginated-item-list-page.component.columns.summary') }}</v-list-item-title>
                                                <v-list-item-subtitle outlined v-html="dat[Object.keys(dat)[0]]" style="max-width:75%;"></v-list-item-subtitle>
                                                <v-list-item-title >{{ $t('paginated-item-list-page.component.columns.description') }}</v-list-item-title>
                                                <v-list-item-subtitle v-html="dat[Object.keys(dat)[1]]" style="max-width:75%;"></v-list-item-subtitle>
                                                
                                        </v-list-item-content> 
                                        <v-btn icon disabled><v-icon>{{editItemIcon}}</v-icon></v-btn>
                                        <v-btn icon disabled><v-icon>{{deleteItemIcon}}</v-icon></v-btn>
                                        
                                    </v-list-item>
                                    <v-divider v-if="hasEditRole" :key="locale+'1'"/>
                                </template>
                                </v-list>
                     
                  </template>

                  <!-- ******************************************************* -->
                  <!-- Alternative Representation for small screen resolutions -->
                  <!-- ******************************************************* -->
                  <template v-else>
                      <v-list>
                          <template v-for="(dat,locale) in itemList">
                          <slot name="list-item">
                              <v-list-item two-line :key="'listItem_' + locale">
                                  <v-list-item-action>
                                      <v-btn icon @click="showEditView({dat,locale})" :disabled="!hasEditRole"><v-icon>{{editItemIcon}}</v-icon></v-btn>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title>
                                          <slot name="list-item-title" v-bind:item="locale">{{locale}}</slot>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                          <slot name="list-item-subtitle" v-bind:item="locale">{{dat.summary}}</slot>
                                      </v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                      <v-menu bottom >
                                          <template v-slot:activator="{ on, attrs }" >
                                              <v-btn icon v-bind="attrs" v-on="on" :disabled="!hasEditRole"><v-icon>more_vert</v-icon></v-btn>
                                          
                                          </template>
  
                                          <v-list>
                                              <v-list-item v-if="hasEditRole" @click="onDelete({dat:dat,locale:locale})">
                                                  <v-list-item-action><v-btn icon :disabled="!hasEditRole"><v-icon>{{deleteItemIcon}}</v-icon></v-btn></v-list-item-action>
                                                  <v-list-item-title>{{$t('paginated-item-list-page.component.actions.delete')}}</v-list-item-title>
                                              </v-list-item>
                                              <v-list-item v-if="hasEditRole"  @click="showEditSingleView({dat,locale,focus:'summary'})">
                                                  <v-list-item-action><v-btn icon :disabled="!hasEditRole"><v-icon>menu</v-icon></v-btn></v-list-item-action>
                                                  <v-list-item-title>{{$t('paginated-item-list-page.component.columns.summary')}}</v-list-item-title>
                                              </v-list-item>
                                              <v-list-item v-if="hasEditRole"  @click="showEditSingleView({dat,locale,focus:'description'})">
                                                  <v-list-item-action><v-btn icon :disabled="!hasEditRole"><v-icon>menu</v-icon></v-btn></v-list-item-action>
                                                  <v-list-item-title>{{$t('paginated-item-list-page.component.columns.description')}}</v-list-item-title>
                                              </v-list-item>
                                          </v-list>
                                      </v-menu>
                                  </v-list-item-action>
                              </v-list-item>
                          </slot>
                          </template>
                      </v-list>
                  </template>
              </slot>
  
  
          </template>
  
        <!-- Empty Content -->
          <template v-if="itemListEmpty">
              <v-container fluid class="grey lighten-5">
                      <v-row justify="center">
                          <v-col cols="10">
                              <div class="subtitle-1 text-center">{{$t('paginated-item-list-page.component.empty.title', $i18n.locale)}}</div>
                              <div class="text-center" v-if="supportsAddItem">{{$t('paginated-item-list-page.component.empty.subtitle', $i18n.locale)}}</div>
                          </v-col>
                      </v-row>
                  </v-container>
          </template>
              <!-- ******************************************************* -->
              <!-- Pagination                                              -->
              <!-- ******************************************************* -->
              
              <template>
  
                               <!-- alternative view (not big button mode)
                  <div v-else>
                  <v-container fluid class="grey lighten-5 pa-3" v-if="!hideProgress">
                      
              <div style="border-width: thin;height: 15px;">
                      <v-row>
          <v-card-text class="grey lighten-5">
              <v-progress-linear :color="color" height="14" :value="loadingPercentage" striped :indeterminate="loading" style="position: relative; top: -10px;">
                  <div class="text-caption" v-if="!this.$vuetify.breakpoint.xs">
                      {{$t('paginated-item-list-panel.component.progress.label', {currentItemCount: itemsLengthFormatted, totalItemCount: totalItemCountFormatted, loadingPercentage: loadingPercentageRoundedFormatted})}}
                  </div>
                  <div class="text-caption" v-else>
                      {{$t('paginated-item-list-panel.component.progress.label.small', {currentItemCount: itemsLengthFormatted, totalItemCount: totalItemCountFormatted, loadingPercentage: loadingPercentageRoundedFormatted})}}
                  </div>
              </v-progress-linear>
          </v-card-text>
                      </v-row>
                      
                      
                  </div>
              </v-container>
                      <v-row v-if="this.$vuetify.breakpoint.mdAndUp">
                          <v-col cols="6" class="text-body-2 text-left" v-if="!noMoreItemsAvalaible">
                              {{$t('paginated-item-list-page.component.pagination.currentPage', {
                                  currentPageFrom,
                                  currentPageTo,
                                  totalItemCount
  
                              })}}
                          </v-col>
                          
                          
                          <v-col cols="6" class="text-body-2 text-left" v-if="noMoreItemsAvalaible">
                              {{$t('paginated-item-list-page.component.pagination.currentPage.all', {
                                  length:this.pagination.totalItemCount
                              })}}
  
                          </v-col>
                          <v-col cols="6" class="text-body-2 text-right" v-if="noMoreItemsAvalaible">
                              <v-btn block style="position: relative; right: 15px;top:-3.3px" @click="fireLoadMore" :disabled="noMoreItemsAvalaible" :color="color"><v-icon :color="color" large>assignment_turned_in</v-icon>&nbsp;{{$t('paginated-item-list-page.component.pagination.loadMore.all', {pageSize: pagination.pageSize})}}</v-btn>    
                          </v-col>
                          <v-col cols="6" class="text-body-2 text-right" v-if="!noMoreItemsAvalaible">
                              <v-btn block style="position: relative; right: 15px;top:-3.3px" @click="fireLoadMore" :disabled="noMoreItemsAvalaible" :color="color"><v-icon >arrow_downward</v-icon>&nbsp;{{$t('paginated-item-list-page.component.pagination.loadMore', {pageSize: amountOfLoadedItems})}}</v-btn>    
                          </v-col>
                      </v-row>
  
                      <v-row v-if="this.$vuetify.breakpoint.sm">
  
                          <v-col cols="6" class="text-body-2 text-left" v-if="!noMoreItemsAvalaible">
                              {{$t('paginated-item-list-page.component.pagination.currentPage.small', {
                                  currentPageFrom,
                                  currentPageTo,
                                  totalItemCount
  
                              })}}<br>{{$t('paginated-item-list-page.component.pagination.currentPage.small.values', {
                                  currentPageFrom,
                                  currentPageTo,
                                  totalItemCount
  
                              })}}
                          </v-col>
                          <v-col cols="6" class="text-body-2 text-left" v-if="noMoreItemsAvalaible">
                              {{$t('paginated-item-list-page.component.pagination.currentPage.all.small', {
                                  itemsLength
                              })}}
                          </v-col>
                              
                          
                              <v-flex v-if="noMoreItemsAvalaible">
                          <v-col cols="12" class="text-body-2 text-right" >
                              <v-btn width="100%" style="position: relative; right: 15px;top:-1px" @click="fireLoadMore" :disabled="noMoreItemsAvalaible" :color="color"><v-icon :color="color" large>assignment_turned_in</v-icon></v-btn>
                          </v-col>
                          </v-flex>
                              <v-flex v-if="!noMoreItemsAvalaible">
                          <v-col cols="12" class="text-body-2 text-right">
                              
                              <v-btn width="100%" style="position: relative; right: 15px;top:-1px" @click="fireLoadMore" :disabled="noMoreItemsAvalaible" :color="color"><v-icon >arrow_downward</v-icon>&nbsp;{{$t('paginated-item-list-page.component.pagination.loadMore.small')}}</v-btn>
                          </v-col>
                          </v-flex>
                      </v-row>
                      
                      <v-row v-else-if="this.$vuetify.breakpoint.xs">
                          <v-flex v-if="noMoreItemsAvalaible">
                          <v-col cols="9" class="text-body-2 text-right">
                              <v-btn style="position: relative;left: 16%;top:-1px" width="100%" @click="fireLoadMore" :disabled="noMoreItemsAvalaible" :color="color"><v-icon :color="color">assignment_turned_in</v-icon></v-btn>
                             </v-col>
                          </v-flex>
                          <v-flex v-if="!noMoreItemsAvalaible">
                          <v-col cols="9" class="text-body-2 text-right">
                              <v-btn style="position: relative;left: 16%;top:-1px" width="100%" @click="fireLoadMore" :disabled="noMoreItemsAvalaible" :color="color"><v-icon >arrow_downward</v-icon>&nbsp;{{$t('paginated-item-list-page.component.pagination.loadMore.smallest')}}</v-btn>
                             </v-col>
                          </v-flex>
                      </v-row>
  </div>
-->
          <v-dialog v-model="showDeleteConfirmationDialog" persistent max-width="480">
              <slot name="delete-dialog"  v-bind:onDeleteConfirmed="onDeleteConfirmed" v-bind:onDeleteCanceled="onDeleteCanceled">
                  <v-card tile flat>
                      <v-card-text />
                      <v-card-text>
                          <div class="title">{{$t('paginated-item-list-page.confirmRemove.title')}}</div>
                      </v-card-text>
                      <v-card-text />
                      <v-card-text>
                          <div class="body">{{$t('paginated-item-list-page.confirmRemove.description')}}</div>
                      </v-card-text>
                      <v-card-text />
                      <v-card-actions class="text-right">
                          <v-spacer />
                          <v-btn text color="accent" @click="onDeleteConfirmed">{{$t('paginated-item-list-page.confirmRemove.actions.confirm')}}</v-btn>
                          <v-btn text color="grey darken-1" @click="onDeleteCanceled">{{$t('paginated-item-list-page.confirmRemove.actions.cancel')}}</v-btn>
                      </v-card-actions>
                  </v-card>
              </slot>
          </v-dialog> 

          <!-- ============================================================= -->
        <!-- JSON DIALOG  -->
        <!-- ============================================================= -->
        <v-dialog v-model="jsonView" fullscreen v-if="this.$vuetify.breakpoint.xsOnly">
          <v-card flat tile>
              <v-toolbar flat>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'gb':Object.keys(jsonViewItem?jsonViewItem:{})[0]"> </vf-icon>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'us':Object.keys(jsonViewItem?jsonViewItem:{})[0]" v-if="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'"> </vf-icon>
                  <v-spacer />
                  <v-toolbar-items>
                      <v-btn icon @click="onSave" color="accent" :disabled="!saveEnabled"><v-icon>save_alt</v-icon></v-btn>
                      <v-btn icon @click="hideJsonView"><v-icon>close</v-icon></v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-divider />
              <v-card-text class="grey lighten-3 pa-5">
            <v-textarea filled :label="$t('paginated-item-list-page.component.columns.summary')"  v-model="Summary">
            </v-textarea >
        </v-card-text>
              <v-card-text class="grey lighten-3 pa-5">
            <v-textarea filled :label="$t('paginated-item-list-page.component.columns.description')" v-model="Description">
            </v-textarea >
                </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="jsonView" fullscreen v-else>
          <v-card flat tile>
              <v-toolbar flat>
                  <v-toolbar-title>{{Object.keys(jsonViewItem?jsonViewItem:{})[0]}}</v-toolbar-title><v-spacer style="max-width: 15px;"/>:
                  <v-spacer style="max-width: 15px;"/>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'gb':Object.keys(jsonViewItem?jsonViewItem:{})[0]"> </vf-icon>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'us':Object.keys(jsonViewItem?jsonViewItem:{})[0]" v-if="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'"> </vf-icon>
                  <v-spacer />
                  <v-toolbar-items>
                      <v-btn text @click="onSave" color="accent" :disabled="!saveEnabled"><v-icon>save_alt</v-icon>{{ $t('editable-item-list.component.editor.actions.save') }}</v-btn>
                      <v-btn text @click="hideJsonView" color="accent"><v-icon>close</v-icon>{{ $t('editable-item-list.component.editor.actions.cancel') }}</v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-divider />
              <v-card-text class="grey lighten-3 pa-5">
            <v-textarea filled :label="$t('paginated-item-list-page.component.columns.summary')" v-model="Summary">
            </v-textarea >
                </v-card-text>
              <v-card-text class="grey lighten-3 pa-5">
            <v-textarea filled :label="$t('paginated-item-list-page.component.columns.description')" v-model="Description">
            </v-textarea >
                </v-card-text>
          </v-card>
        </v-dialog><v-dialog v-model="jsonViewSummary" fullscreen v-if="this.$vuetify.breakpoint.xsOnly">
          <v-card flat tile>
              <v-toolbar flat>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'gb':Object.keys(jsonViewItem?jsonViewItem:{})[0]"> </vf-icon>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'us':Object.keys(jsonViewItem?jsonViewItem:{})[0]" v-if="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'"> </vf-icon>
                  <v-spacer />
                  <v-toolbar-items>
                      <v-btn icon @click="onSave" color="accent" :disabled="!saveEnabled"><v-icon>save_alt</v-icon></v-btn>
                      <v-btn icon @click="hideJsonView"><v-icon>close</v-icon></v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-divider />
              <v-card-text class="grey lighten-3 pa-5">
            <v-textarea filled :label="$t('paginated-item-list-page.component.columns.summary')"  v-model="Summary">
            </v-textarea >
        </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="jsonViewSummary" fullscreen v-else>
          <v-card flat tile>
              <v-toolbar flat>
                  <v-toolbar-title>{{Object.keys(jsonViewItem?jsonViewItem:{})[0]}}</v-toolbar-title><v-spacer style="max-width: 15px;"/>:
                  <v-spacer style="max-width: 15px;"/>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'gb':Object.keys(jsonViewItem?jsonViewItem:{})[0]"> </vf-icon>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'us':Object.keys(jsonViewItem?jsonViewItem:{})[0]" v-if="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'"> </vf-icon>
                  <v-spacer />
                  <v-toolbar-items>
                      <v-btn text @click="onSave" color="accent" :disabled="!saveEnabled"><v-icon>save_alt</v-icon>{{ $t('editable-item-list.component.editor.actions.save') }}</v-btn>
                      <v-btn text @click="hideJsonView" color="accent"><v-icon>close</v-icon>{{ $t('editable-item-list.component.editor.actions.cancel') }}</v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-divider />
              <v-card-text class="grey lighten-3 pa-5">
            <v-textarea filled :label="$t('paginated-item-list-page.component.columns.summary')" v-model="Summary">
            </v-textarea >
                </v-card-text>
          </v-card>
        </v-dialog>
    <v-dialog v-model="jsonViewDescription" fullscreen v-if="this.$vuetify.breakpoint.xsOnly">
          <v-card flat tile>
              <v-toolbar flat>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'gb':Object.keys(jsonViewItem?jsonViewItem:{})[0]"> </vf-icon>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'us':Object.keys(jsonViewItem?jsonViewItem:{})[0]" v-if="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'"> </vf-icon>
                  <v-spacer />
                  <v-toolbar-items>
                      <v-btn icon @click="onSave" color="accent" :disabled="!saveEnabled"><v-icon>save_alt</v-icon></v-btn>
                      <v-btn icon @click="hideJsonView"><v-icon>close</v-icon></v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-divider />
              <v-card-text class="grey lighten-3 pa-5">
            <v-textarea filled :label="$t('paginated-item-list-page.component.columns.description')"  v-model="Description">
            </v-textarea >
        </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="jsonViewDescription" fullscreen v-else>
          <v-card flat tile>
              <v-toolbar flat>
                  <v-toolbar-title>{{Object.keys(jsonViewItem?jsonViewItem:{})[0]}}</v-toolbar-title><v-spacer style="max-width: 15px;"/>:
                  <v-spacer style="max-width: 15px;"/>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'gb':Object.keys(jsonViewItem?jsonViewItem:{})[0]"> </vf-icon>
                  <vf-icon :country="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'?'us':Object.keys(jsonViewItem?jsonViewItem:{})[0]" v-if="Object.keys(jsonViewItem?jsonViewItem:{})[0] === 'en'"> </vf-icon>
                  <v-spacer/>
                  <v-toolbar-items>
                      <v-btn text @click="onSave" color="accent" :disabled="!saveEnabled"><v-icon>save_alt</v-icon>{{ $t('editable-item-list.component.editor.actions.save') }}</v-btn>
                      <v-btn text @click="hideJsonView" color="accent"><v-icon>close</v-icon>{{ $t('editable-item-list.component.editor.actions.cancel') }}</v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-divider />
              <v-card-text class="grey lighten-3 pa-5">
            <v-textarea filled :label="$t('paginated-item-list-page.component.columns.description')" v-model="Description">
            </v-textarea >
                </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog eager fullscreen v-model="showAddDialogue" v-if="!this.$vuetify.breakpoint.mdAndUp">
            <v-card flat tile >
                <v-card-title>{{ $t('paginated-item-list-page.component.columns.addTitle') }}</v-card-title>
                <v-card-subtitle>{{ $t('paginated-item-list-page.component.columns.addSubtitle') }}</v-card-subtitle>
                <!--<div v-if="lanSet" class="red--text">{{ $t('paginated-item-list-page.component.columns.language.error') }}</div>
                -->
              <v-toolbar flat>
                    <v-spacer />
                  <v-toolbar-items>
                      <v-btn text @click="onSaveAdd" color="accent" :disabled="!validAddItem"><v-icon>save_alt</v-icon></v-btn>
                      <v-btn text @click="hideJsonViewAdd" color="accent"><v-icon>close</v-icon></v-btn>
                  </v-toolbar-items>
              </v-toolbar>
                <v-card-text class="error white--text pa-5" v-if="showDuplicateWarning">
                    {{ $t('paginated-item-list-page.component.columns.duplicateWarning') }}
                </v-card-text>
                <v-card-text class="grey lighten-3 pa-5">
                    <v-select persistent-hint :hint="Language===''?$t('paginated-item-list-page.component.columns.language.error'):''" :label="$t('paginated-item-list-panel.component.progress.label.language')" filled :items="supportedLanguage" v-model="Language"></v-select>
            </v-card-text>
                <v-card-text class="grey lighten-3 pa-5">
                    <div @click="clickedOnATextAreas">
            <v-textarea  filled :label="$t('paginated-item-list-page.component.columns.summary')"  v-model="Summary" :disabled="Language==''">
            </v-textarea >
        </div>
              <v-textarea filled @focus="clickedOnATextAreas" :label="$t('paginated-item-list-page.component.columns.description')" v-model="Description" :disabled="Language==''">
              </v-textarea >
                  </v-card-text>
              <v-divider />
          </v-card>
        </v-dialog>
        <v-dialog eager v-model="showAddDialogue" fullscreen v-else>
            <v-card flat tile >
                <v-row>
                    <v-col cols="8">
                <v-card-title>{{ $t('paginated-item-list-page.component.columns.addTitle') }}</v-card-title>
                <v-card-subtitle>{{ $t('paginated-item-list-page.component.columns.addSubtitle') }}</v-card-subtitle>
                </v-col>
                <v-col cols="4">
                <!--<div v-if="lanSet" class="red--text">{{ $t('paginated-item-list-page.component.columns.language.error') }}</div>
                -->
                <v-toolbar flat>
                  <v-spacer />
                  <v-toolbar-items>
                      <v-btn text @click="onSaveAdd" color="accent" :disabled="!validAddItem"><v-icon>save_alt</v-icon>{{ $t('editable-item-list.component.editor.actions.save') }}</v-btn>
                      <v-btn text @click="hideJsonViewAdd" color="accent"><v-icon>close</v-icon>{{ $t('editable-item-list.component.editor.actions.cancel') }}</v-btn>
                  </v-toolbar-items>
              </v-toolbar>
            </v-col>
            </v-row>
                <v-card-text class="error white--text pa-5" v-if="showDuplicateWarning">
                    {{ $t('paginated-item-list-page.component.columns.duplicateWarning') }}
                </v-card-text>
                <v-card-text class="grey lighten-3 pa-5">
                    <v-select persistent-hint :hint="Language===''?$t('paginated-item-list-page.component.columns.language.error'):''" :label="$t('paginated-item-list-panel.component.progress.label.language')" filled :items="supportedLanguage" v-model="Language"></v-select>
            </v-card-text>
                <v-card-text class="grey lighten-3 pa-5">
                    <div @click="clickedOnATextAreas">
            <v-textarea  filled :label="$t('paginated-item-list-page.component.columns.summary')"  v-model="Summary" :disabled="Language==''">
            </v-textarea >
        </div>
              <v-textarea filled @focus="clickedOnATextAreas" :label="$t('paginated-item-list-page.component.columns.description')" v-model="Description" :disabled="Language==''">
              </v-textarea >
                  </v-card-text>
              <v-divider />
          </v-card>
        </v-dialog>
              </template>
  
  </v-card-text>
    </v-card>
  </template>
  
  
  <script>
    import { get, isEmpty, cloneDeep} from 'lodash';
    import vFlagIcons from 'v-flag-icons'
    import 'v-flag-icons/css/rectangular.min.css'

  export default {
      name : 'paginated-item-list-page',

      components: {
          'vf-icon': vFlagIcons
      },
      props: {
        hasEditRole: {
            type: Boolean,
            required: true,
            default: true
        },
        nameColumn: {
            type: String,
            required: true,
            default: 'Zusammenfassung'},
          bigbutton: {
              type: Boolean,
              required : false,
              default:false
          },
          centeredButton: {
              type: Boolean,
              required : false,
              default:true
          },
          color: {
              type: String,
              required: false,
              default: 'accent'
          },
          saveEnabled: {
              type: Boolean,
              required: false,
              default: true
          },
          pageSize: {
              type: Number,
              required : true,
              default:2
          },
          showFlags: {
              type: Boolean,
              required: false,
              default: false
          },
          loading: {
              type: Boolean,
              required: false,
              default: false
          },
          items: {
  
              type: Object,
              required: true
          },
          pagination: {
              type: Object,
             required: true,
          //default: () => ({from:0,pageSize:50,totalItemCount:3,currentPage:1,totalAvailableItems:6})
          },
  
          editItemIcon : {
              type: String,
              required: false,
              default: 'edit'
          },
  
          deleteItemIcon : {
              type: String,
              required: false,
              default: 'delete'
          },
  
          supportsAddItem: {
  
              type: Boolean,
              required: false,
              default: true,
              deprecated: true
          },
  
          hideNameColumn: {
  
              type: Boolean,
              required: false,
              default: false
          },
  
          hideI18nNameColumn: {
  
              type: Boolean,
              required: false,
              default: false
          },
  
          disableAddItem: {
              type: Boolean,
              required: false,
              default: false,
          },
  
          supportsEditItem: {
  
              type: Boolean,
              required: false,
              default: true,
              deprecated: true
          },
  
          disabled: {
              type: Boolean,
              required: false,
              default: false,
          },
  
          disableEditItem: {
              type: Boolean,
              required: false,
              default: false,
          },
  
          supportsDeleteItem: {
  
              type: Boolean,
              required: false,
              default: true,
              deprecated: true
          },
  
          disableDeleteItem: {
              type: Boolean,
              required: false,
              default: false,
          },
  
          supportsFilterItems: {
  
              type: Boolean,
              required: false,
              default: true,
              deprecated: true
          },
  
          supportsJsonView: {
  
              type: Boolean,
              required: false,
              default: true,
              deprecated: true
          },
           hideDeleteAction: {
              type: Boolean,
              required: false,
              default: false,
          },
          readonly:{
              type: Boolean,
              required:false,
              default:false
          },
          hideProgress:{
              type: Boolean,
              required:false,
              default:false
          }
      },
  
      data: () => ({
          step:1,
          refreshKey:0,
          filter: null,
          jsonView: false,
          showFilter: true,
          jsonViewItem: {},
          showAddDialogue: false,
          jsonViewSummary: false,
          clickedOnATextArea:false,
          jsonViewItemBackUp: null,
          jsonViewDescription: false,
          showDuplicateWarning: false,
          showDeleteConfirmationDialog: false,
          supportedLanguages: ['en','de','fr','es','it','nl','pl','pt','ru','tr','zh']
      }),
  
      methods: {
        clickedOnATextAreas(){
            this.clickedOnATextArea = true;
        },
        onAddItem(){
            this.showAddDialogue = true
        },
        onSave(){
            this.$emit('save', {
                locale: Object.keys(this.jsonViewItem)[0],
                item: cloneDeep(this.jsonViewItem)
            });
              this.jsonView = false;
              this.jsonViewItem = {};
              this.jsonViewDescription = false;
              this.jsonViewSummary = false;
        },
        onSaveAdd(){
            this.refreshKey = Math.random();
            let double = false
            for(let i in cloneDeep(this.itemList)){
                console.log(i)
                    if (i === Object.keys(this.jsonViewItem)[0]){
                        double = true;
                    }}
            if(double){
                this.showDuplicateWarning = true;
            }else{
            this.$emit('save', {
                locale: Object.keys(this.jsonViewItem)[0],
                item: cloneDeep(this.jsonViewItem)
            });
            
            this.showDuplicateWarning = false
              this.jsonView = false;
              this.jsonViewItem = {};
              this.jsonViewDescription = false;
              this.jsonViewSummary = false;
              this.showAddDialogue = false;
        }
        },
        summary(item){
                return get(item,Object.keys(item)[0]+"summary")
            },
          onAddNewItem() {
              this.$emit('item-added', {
                message:"new item to be added"
              });
          },
          
          onEdit(item) {
              this.$emit('item-edited', {
                  message:"item to be added",
                  item: item
              });
          }, 
  
          onDelete(item) {
              this.$emit('item-deleted-initiated', {
                  message:"item to be deleted"
              });
              this.item = item.locale;
              console.log(this.item) 
              this.showDeleteConfirmationDialog = true;
          },
  
          onDeleteConfirmed() {
              this.$emit('item-deleted', {
                  item: this.item
              });
  
              this.hideDeleteConfirmationDialog();
          },
  
          onDeleteCanceled() {
             this.hideDeleteConfirmationDialog(); 
          }, 
  
          hideDeleteConfirmationDialog() {
              this.showDeleteConfirmationDialog = false;
              this.item = null;
          },

          fireLoadMore() {
              console.log(this.amountOfLoadedItems)
              const page = this.pagination.pageSize + Number(this.pageSize) > this.pagination.totalItemCount?this.pagination.totalItemCount:this.pagination.pageSize + Number(this.pageSize);
              const pageinationCiteria = {
             from:this.itemsLength,
             pageSize: Number(this.pageSize),
             totalItemCount:this.pagination.totalItemCount,
              }
              console.log(pageinationCiteria)
              const event={
                nextPageSettings:pageinationCiteria,
              }
              this.pagination.pageSize = page;
              this.$emit('load-more', event);
              return null;
          },
  
          showEditView(item) {
              this.jsonView = true;
              const a = {}
              a[item.locale] = item.dat;
              this.jsonViewItem = a;
              this.jsonViewItemBackUp = cloneDeep(a);
          },
          showEditSingleView(item){
            if(item.focus === 'summary'){
                this.jsonViewSummary = true;}
                else{
                    this.jsonViewDescription = true;
                }
              const a = {}
              a[item.locale] = item.dat;
              this.jsonViewItem = a;
              this.jsonViewItemBackUp = cloneDeep(a);
          },
  
          hideJsonView() {
              this.jsonView = false;
              this.jsonViewItem = {};
              this.jsonViewDescription = false;
              this.jsonViewSummary = false;
              this.$emit('save', {
                  locale: Object.keys(this.jsonViewItemBackUp)[0],
                  item: cloneDeep(this.jsonViewItemBackUp)
                });
                this.jsonViewItemBackUp = null;
          },
          hideJsonViewAdd(){
            this.clickedOnATextArea = false;
              this.jsonView = false;
              this.jsonViewItem = {};
              this.jsonViewDescription = false;
              this.jsonViewSummary = false;
              this.showAddDialogue = false;
              this.showDuplicateWarning = false;
            this.jsonViewItemBackUp = null;

          },
          getModificationSubtitle(item) {
  
              if (!item.modificationDateTime && !item.modificationUser) {
                  return this.$t('paginated-item-list-page.component.columns.modificationUserAndDateTimeUnknown');
              
              } else {
                  let dateTime = this.$dxs.i18n.localizedDateTimeFormat(item.modificationDateTime, this.$i18n.locale);
                  let userId   = item.modificationUser;
  
                  return this.$t('paginated-item-list-page.component.columns.modificationUserAndDateTime', {dateTime, userId});
              }
          },
        copyJsonToClipboard(){
            if (navigator.clipboard) {
                navigator.clipboard.writeText(JSON.stringify(this.jsonViewItem, true, 2));
            } },
        preventClose(){
            this.showAddDialogue = true;
}
      },
  
      computed: 
      
      {supportedLanguage(){
        this.refreshKey+1;
            return this.supportedLanguages.filter((item) => {
                for(let i in get(this,"itemList")){
                    if (i == item){
                        console.log(i)
                        console.log(item)
                        return false;
                    }
                }
                return true;
            })
        },
  itemListEmpty() {
    this.refreshKey+1;
        return isEmpty(this.itemList);
  },
        lanSet(){
            return !this.clickedOnATextArea;
            },
        validAddItem(){
            if(this.Language === "" || this.Summary === "" || this.Description === ""){
                return false;
            }else{
                return true;
            }},
        Language:{
            get(){
                if(this.jsonViewItem == {} || !this.jsonViewItem[Object.keys(this.jsonViewItem)[0]]){
                    return "";}else{
                return Object.keys(this.jsonViewItem)[0]}
            },
            set(value){
                if (this.jsonViewItem == {} || !this.jsonViewItem[Object.keys(this.jsonViewItem)[0]]) {
                    let a = {};
                    a[value] ={summary:"",description:""};
                this.jsonViewItem = a
                    
                }else{
                    let sum = ""
                    if(this.jsonViewItem[Object.keys(this.jsonViewItem)[0]].summary!==""){
                        sum = this.jsonViewItem[Object.keys(this.jsonViewItem)[0]].summary;
                    }
                    let desc = ""
                    if(this.jsonViewItem[Object.keys(this.jsonViewItem)[0]].description!==""){
                        desc = this.jsonViewItem[Object.keys(this.jsonViewItem)[0]].description;
                    }
                    let a = {};
                    a[value] ={summary:sum,description:desc};
                this.jsonViewItem = a}
            }
        },
        Summary:{
            get(){
                if(this.jsonViewItem == {} || !this.jsonViewItem[Object.keys(this.jsonViewItem)[0]]){
                    return "";}else{
                return this.jsonViewItem[Object.keys(this.jsonViewItem)[0]].summary;}
            },
            set(value){
                if (this.jsonViewItem){ 
                this.jsonViewItem[Object.keys(this.jsonViewItem)[0]]["summary"] = value;}
            }
        },
        Description:{
            get(){if(this.jsonViewItem == {} || !this.jsonViewItem[Object.keys(this.jsonViewItem)[0]]){
                return "";}else{
                return this.jsonViewItem[Object.keys(this.jsonViewItem)[0]].description;}
            },
            set(value){
                if (this.jsonViewItem) {
                this.jsonViewItem[Object.keys(this.jsonViewItem)[0]]["description"] = value;}
            }
        },
        showSummary:{
            get(){
            if(this.jsonViewItem == {} || !this.jsonViewItem[Object.keys(this.jsonViewItem)[0]]){
                return "";
            }else{
                const locale = Object.keys(this.jsonViewItem)[0];
            return this.jsonViewItem[locale].summary;
        }}
        },
        showDescription:{
            get(){
            if(this.jsonViewItem == {} || !this.jsonViewItem[Object.keys(this.jsonViewItem)[0]]){
                return "";
            }else{
                const locale = Object.keys(this.jsonViewItem)[0];
            return this.jsonViewItem[locale].description;
        }}
        },
          amountOfLoadedItems(){
            if(this.pageSize)return this.pagination.totalItemCount <= this.itemsLength + Number(this.pageSize) ? this.pagination.totalItemCount-this.itemsLength : this.pageSize;
            return null;
          },
          itemsLength() {
              return isEmpty(this.items) ? 0 : this.items.length; 
          },
  
          itemsLengthFormatted() {
              return this.$core.filters.numericFormat(this.itemsLength, '#,###', this.$i18n.locale);
          },
  
          totalItemCount() {
              return get(this.pagination, 'totalItemCount', 0);
          },
  
          totalItemCountFormatted() {
              return this.$core.filters.numericFormat(this.totalItemCount, '#,###', this.$i18n.locale);
          },
  
          moreElements() {
  
              return this.itemsLength < this.totalItemCount;
          },
  
          loadingPercentage() {
              if (this.itemsLength === 0) return 0;
              else return this.itemsLength / ( this.totalItemCount / 100 ) ;
          },
  
          loadingPercentageRounded() {
              return this.$dxs.rounding.round(this.loadingPercentage, 2);
          },
  
          loadingPercentageRoundedFormatted() {
              return this.$core.filters.numericFormat(this.loadingPercentageRounded, '#,###', this.$i18n.locale);
          },
  
          noMoreItemsAvalaible(){
             return this.pagination.totalItemCount <= this.itemsLength;
          },
          Title(){
              return this.title;
          },
          Subtitle(){
              return this.subtitle
          },
          enabledViewAll(){
              return true;
          },
  
          itemList: {
            get() {
              return this.items ? this.items : [];
            },
            set(value) {
                this.items[value.locale] = value.dat;
            }
          },
  
          itemCount() {
              return this.itemList.length;
          },

          titleTruncation() {
  
              if (this.$vuetify.breakpoint.xsOnly) return 16;
              else if (this.$vuetify.breakpoint.smOnly) return 24;
              else if (this.$vuetify.breakpoint.mdOnly) return 32;  
              else return 48;
          },
  
          showDeleteAction() {
              if(this.hideDeleteAction){
                  return false
              }else{
                  return this.supportsDeleteItem
              }
          },
  
          paginationEmpty() {
              return isEmpty(this.pagination);
          },
  
          currentPageFrom() {
              // Add + 1 in order to avoid the User unfriendly start from index 0
              return !this.paginationEmpty ? this.pagination.from + 1 : 0;
          },
  
          currentPageTo() {
  
              if (this.paginationEmpty) {
                  return 0;
              }
  
              return (this.pagination.from + this.pagination.pageSize <= this.pagination.totalItemCount) 
                   ? this.pagination.from + this.pagination.pageSize
                   : this.pagination.totalItemCount;
  
          },
  
          /**
           * Returns true, if the current model represents the first page of the data set. Otherwise false is returned.
           */
          firstPage() {   
              return !this.paginationEmpty ? this.pagination.from === 0 : true;
          },
  
          /**
           * Returns true, if the current model represents the last page of the data set. Otherwise false is returned.
           */
          lastPage() {
              return !this.paginationEmpty ? this.pagination.from + this.pagination.pageSize >= this.pagination.totalItemCount : true;
          }
  
  
      }
  }
  </script>
  <style>
  .red-hint .v-messages__message {
    color: red;
  }
  </style>
  <i18n>
  {
      "en": {
        "paginated-item-list-page.component.columns.duplicateWarning": "This language is already present in the list. Please select another language.",
        "paginated-item-list-page.component.columns.addSubtitle": "Please select a language first and define the details of the safety instruction. Please provide a summary and a detailed description.",
          "paginated-item-list-page.component.columns.language.error": "Please select a language first.",
          "paginated-item-list-panel.component.progress.label" : "{currentItemCount} / {totalItemCount} Items ({loadingPercentage} %)",
          "paginated-item-list-panel.component.progress.label.language": "Language",
          "paginated-item-list-panel.component.progress.label.small" : "{currentItemCount} / {totalItemCount} ({loadingPercentage} %)",
          "paginated-item-list-panel.component.actions.loadMore" : "Load Next {pageSize} Items",
          "paginated-item-list-panel.component.results.loadFurtherItems" : "Please press this button in order to load another {pageSize}  items of ca. {totalItemCount} total items matching your search query",
          "paginated-item-list-panel.component.results.adjustFilterLessResults" : "In order to reduce the total number of items, refine your filter settings, please.",
          "paginated-item-list-panel.component.results.noMoreResults" : "You are currently viewing all {totalItemCount} items matching your search query.",
          "paginated-item-list-panel.component.results.adjustFilterMoreResults" : "In order to display additional items, refine your filter settings, please. ",
         "paginated-item-list-page.component.pagination.loadMore": "Load Next {pageSize} Items",
        "paginated-item-list-page.component.empty.title": "No elements are currently present.",
        "paginated-item-list-page.component.empty.subtitle": "You may add new elements by using the + button.",
        "paginated-item-list-page.component.actions.json": "Show JSON",
        "paginated-item-list-page.component.columns.addTitle":"Loacalized Safety Instruction Details",
        "paginated-item-list-page.component.actions.add": "Add New Item",
        "editable-item-list.component.editor.actions.cancel": "Cancel",
        "paginated-item-list-page.component.actions.add.xs": "Add",
        "paginated-item-list-page.component.actions.delete": "Delete Item",
        "paginated-item-list-page.component.actions.delete.xs": "Delete",
        "paginated-item-list-page.component.actions.applyFilter": "Apply Filter",
        "paginated-item-list-page.component.actions.applyFilter.xs": "Apply",
        "paginated-item-list-page.component.actions.refresh": "Reload List",
        "paginated-item-list-page.component.columns.name": "Tech. ID",
        "paginated-item-list-page.component.columns.i18nName": "Name",
        "paginated-item-list-page.component.columns.modificationDateTime": "Last Modified on",
        "paginated-item-list-page.component.columns.modificationUser": "Modified by",
        "paginated-item-list-page.component.columns.modificationUserAndDateTime": "Last modification on {dateTime} by {userId}.",
        "paginated-item-list-page.component.columns.modificationUserAndDateTimeUnknown": "No modification information available",
        "paginated-item-list-page.component.filter.label": "Enter Search Expression",
        "paginated-item-list-page.component.dialog.json.title": "JSON (Technical Representation)",
        "paginated-item-list-page.component.confirmRemove.title": "Do you want to remove this item?",
        "paginated-item-list-page.component.confirmRemove.description": "Please confirm removal of this item. Warning: Removal of an item can't be undone and might cause loss of data.",
        "paginated-item-list-page.component.confirmRemove.actions.confirm": "Remove Item",
        "paginated-item-list-page.component.confirmRemove.actions.cancel": "Cancel",
        "paginated-item-list-page.component.pagination.loadMore.small": "Load more...",
        "paginated-item-list-page.component.pagination.loadMore.smallest": "More",
        "paginated-item-list-page.component.title": "{ title }",
        "paginated-item-list-page.component.title.default": "Settings",
        "paginated-item-list-page.component.subtitle.default": "Here you can manage the properties of { name }",
        "paginated-item-list-page.component.subtitle": "{ subtitle }",
        "paginated-item-list-page.component.pagination.title": "{currentPageFrom} to {currentPageTo} of {totalItemCount} total",
        "paginated-item-list-page.component.pagination.currentPage": "Displaying items from {currentPageFrom} to {currentPageTo} of {totalItemCount} total",
        "paginated-item-list-page.component.pagination.currentPage.small": "Displaying items from",
        "paginated-item-list-page.component.pagination.currentPage.small.values": "{currentPageFrom} to {currentPageTo} of {totalItemCount} total.",
        "paginated-item-list-page.component.pagination.viewAll" : "View All Workplaces",
        "query-result-pagination-panel.component.results.loadFurtherItems" : "Please press this button in order to load another {pageSize}  items of ca. {totalItemCount} total items.",
          "query-result-pagination-panel.component.results.noMoreResults" : "You are currently viewing all {totalItemCount}",
    "paginated-item-list-page.confirmRemove.title" : "Do you want to remove this item?",
    "paginated-item-list-page.confirmRemove.description" : "Please confirm removal of this item. Warning: Removal of an item can't be undone and might cause loss of data.",
    "paginated-item-list-page.confirmRemove.actions.confirm" : "Remove Item",
        "paginated-item-list-page.component.columns.i18nName": "Summary",
        "paginated-item-list-page.component.columns.Name": "Description",
        "paginated-item-list-page.confirmRemove.actions.cancel" : "Cancel",
          "paginated-item-list-page.component.pagination.loadMore.all" : "All Items are loaded",
          "paginated-item-list-page.component.columns.lan" : "Language",
          "paginated-item-list-page.component.columns.summary":"Summary",
          "paginated-item-list-page.component.columns.description":"Description",
            "editable-item-list.component.editor.actions.save" : "Save",
            "safety-instruction-editor.view.tabs.details.add" : "Add new Item",
            "safety-instruction-editor.view.tabs.details": "Details"
      },
      "de": {
        "paginated-item-list-page.component.columns.duplicateWarning": "Diese Sprache ist bereits in der Liste vorhanden. Bitte wählen Sie eine andere Sprache.",
        "paginated-item-list-page.component.columns.addSubtitle":"Bitte wählen Sie eine Sprache aus und definieren Sie die Details der Sicherheitsanweisung. Bitte hinterlegen Sie eine Zusammenfassung und eine ausführliche Beschreibung.",
        "paginated-item-list-page.component.columns.addTitle":"Sprachabhängige Details der Sicherheitsanweisung",
        "paginated-item-list-page.component.columns.language.error": "Bitte wählen Sie zuerst eine Sprache aus.",
          "paginated-item-list-panel.component.progress.label" : "{currentItemCount} / {totalItemCount} Ergebnisse ({loadingPercentage} %)",
          "paginated-item-list-page.component.columns.Name":"Beschreibung",
          "paginated-item-list-panel.component.progress.label.language": "Sprache",
          "editable-item-list.component.editor.actions.cancel": "Abbrechen",
          "paginated-item-list-panel.component.progress.label.small" : "{currentItemCount} / {totalItemCount} ({loadingPercentage} %)",
          "paginated-item-list-panel.component.actions.loadMore" : "Nächste {pageSize} Dokumente laden...",
          "paginated-item-list-panel.component.results.loadFurtherItems" : "Bitte klicken Sie auf den Button um weitere {pageSize} Ergebnisse von insg. ca. {totalItemCount} Dokumente zu laden, die zu Ihren Filterkriterien passen.",
          "paginated-item-list-panel.component.results.adjustFilterLessResults" : "Bitte passen Sie die Filterkriterien an, um die Anzahl der Dokumente weiter einzuschränken,",
          "paginated-item-list-panel.component.results.noMoreResults" : "Sie sehen alle {totalItemCount} Dokumente, die zu Ihrer Filterung passen.",
          "paginated-item-list-panel.component.results.adjustFilterMoreResults" : "Bitte passen Sie Ihre Filterung an, um zusätzliche Dokumente anzuzeigen.",
        "paginated-item-list-page.component.pagination.loadMore": "Nächste {pageSize} Elemente laden...",
    "paginated-item-list-page.confirmRemove.title" : "Möchten Sie dieses Element wirklich löschen?",
    "paginated-item-list-page.confirmRemove.description" : "Bitte bestätigen Sie das Löschen dieses Elements. Bitte beachten Sie hierbei: Das Löschen eines Elements kann nicht rückgängig gemacht werden und kann zu Datenverlust führen.",
    "paginated-item-list-page.confirmRemove.actions.confirm" : "Element löschen",
    "paginated-item-list-page.confirmRemove.actions.cancel" : "Abbrechen",
        "paginated-item-list-page.component.pagination.loadMore.small": "Mehr laden...",
        "paginated-item-list-page.component.pagination.loadMore.smallest": "Mehr",
        "paginated-item-list-page.component.empty.title": "Momentan sind keine Elemente vorhanden.",
        "paginated-item-list-page.component.empty.subtitle": "Sie können durch die + Schaltfläche neue Elemente hinzufügen.",
        "paginated-item-list-page.component.actions.json": "Als JSON anzeigen",
        "paginated-item-list-page.component.actions.add": "Element hinzufügen",
        "paginated-item-list-page.component.actions.add.xs": "Hinzufügen",
        "paginated-item-list-page.component.actions.delete": "Element löschen",
        "paginated-item-list-page.component.actions.delete.xs": "Löschen",
        "paginated-item-list-page.component.actions.applyFilter": "Filter anwenden",
        "paginated-item-list-page.component.actions.applyFilter.xs": "Anwenden",
        "paginated-item-list-page.component.actions.refresh": "Liste Neu Laden",
        "paginated-item-list-page.component.columns.name": "Techn. Bezeichner",
        "paginated-item-list-page.component.columns.i18nName": "Zusammenfassung",
        "paginated-item-list-page.component.columns.modificationDateTime": "Geändert am",
        "paginated-item-list-page.component.columns.modificationUser": "Geändert von",
        "paginated-item-list-page.component.columns.modificationUserAndDateTime": "Letzte Änderung am {dateTime} von {userId}.",
        "paginated-item-list-page.component.columns.modificationUserAndDateTimeUnknown": "Es liegen keine Informationen zur letzten Änderung vor.",
        "paginated-item-list-paxge.component.filter.label": "Suchausdruck eingeben",
        "paginated-item-list-page.component.dialog.json.title": "JSON (Technische Darstellung)",
        "paginated-item-list-page.component.confirmRemove.title": "Möchten Sie dieses Element wirklich löschen?",
        "paginated-item-list-page.component.confirmRemove.description": "Bitte bestätigen Sie das Löschen dieses Elements. Bitte beachten Sie hierbei: Das Löschen eines Elements kann nicht rückgängig gemacht werden und kann zu Datenverlust führen.",
        "paginated-item-list-page.component.confirmRemove.actions.confirm": "Element löschen",
        "paginated-item-list-page.component.confirmRemove.actions.cancel": "Abbrechen",
        "paginated-item-list-page.component.title": "{Title}",
        "paginated-item-list-page.component.subtitle": "Test Subtitle",
        "paginated-item-list-page.component.title.default": "Einstellungen",
        "paginated-item-list-page.component.subtitle.default": "Here you can manage the properties of { name }",
        "paginated-item-list-page.component.pagination.title": "{currentPageFrom} bis {currentPageTo} von insg. {totalItemCount}",
        "paginated-item-list-page.component.pagination.currentPage": "Die Liste zeigt die Elemente {currentPageFrom} bis {currentPageTo} von insg. {totalItemCount} an.",
        "paginated-item-list-page.component.pagination.currentPage.small": "Liste zeigt Elemente",
        "paginated-item-list-page.component.pagination.currentPage.small.values": " {currentPageFrom} bis {currentPageTo} von {totalItemCount} an.",
        "paginated-item-list-page.component.pagination.currentPage.all": "Sie haben alle {length} Elemente geladen.",
        "paginated-item-list-page.component.pagination.currentPage.all.small": " Alle Elemente geladen.",
        "paginated-item-list-page.component.pagination.viewAll" : "Alle Arbeitsplätze anzeigen",
        "query-result-pagination-panel.component.results.loadFurtherItems" : "Bitte klicken Sie auf den Button um weitere {pageSize} Ergebnisse von insg. ca. {totalItemCount} Dokumente zu laden.",
          "query-result-pagination-panel.component.results.noMoreResults" : "Sie sehen alle {totalItemCount} Elemente.",
          "paginated-item-list-page.component.pagination.loadMore.all" : "Alle Elemente geladen",
          "paginated-item-list-page.component.columns.lan" : "Sprache",
          "safety-instruction-editor.view.tabs.details.add" : "Neues Element hinzufügen",
          "paginated-item-list-page.component.columns.summary":"Zusammenfassung",
          "paginated-item-list-page.component.columns.description":"Beschreibung",
          "editable-item-list.component.editor.actions.save": "Speichern",
          "safety-instruction-editor.view.tabs.details": "Details"
      }
    }
  </i18n>