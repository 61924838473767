<template>
  <v-container fluid class="secondary pt-10 pb-10">
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <c-base-dialog
          :value="batteryCategory"
          :title="$t('hazardous-substance-classifications-wizard.view.title')"
          :subtitle="$t('hazardous-substance-classifications-wizard.view.subtitle')"
          color="secondary"
          @dialog-saved="save"
          @dialog-canceled="cancel"
        >
          <template v-slot:default="props">
            <v-card flat>
              <v-card-text>
                <c-name-input v-model="props.item.name" filled />
                </v-card-text>
            </v-card>
            
          </template>
        </c-base-dialog>
        
      </v-col>
      
    </v-row>
    
  </v-container>
</template>

<script>
import BatteryCategoryWizardModule from "../../../store/masterdata/battery-categories/battery-categories-wizard.module";

const MODULE_NAME = "BatteryCategoryWizard";

export default {
  name: "battery-categories-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
  ],

  methods: {
    async save() {
            this.progressIndicatorService.show();
            try {


              await this.setTenantId(this.envContextService.tenantId);


              await this.$store.dispatch(MODULE_NAME + "/createBatteryCategory");

                this.$router.push({
                    name: "BatteryCategoryEditorView",
                    params: { name: this.batteryCategory.name},
                });

                this.reset();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

    cancel() {
      this.reset();
      this.$router.push({
        name: "BatteryCategoryListView",
      });
    },

    setTenantId(tenantId) {
      this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
    },

    setBatteryCategory(batteryCategory){
      this.$store.dispatch(MODULE_NAME+ "/updateBatteryCategory", batteryCategory);
    },
    
    reset() {
      this.$store.dispatch(MODULE_NAME + "/resetBatteryCategory");
      this.setTenantId(this.tenantId);
    },
  },

  computed: {
    
    batteryCategorys() {
            return this.$store.getters[MODULE_NAME + "/batteryCategory"];
        },
    batteryCategory: {
      get() {
        return this.$store.getters[MODULE_NAME + "/batteryCategory"];
      },

      set(item) {
        item.tenantId = this.envContextService.tenantId;
        this.$store
          .dispatch(MODULE_NAME + "/updateBatteryCategory", item)
          .catch(this.errorHandlerService.handleError);
      },
    },
    
    tenantId() {
        return this.envContextService.tenantId;
    }

  },


  async beforemount(){
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, BatteryCategoryWizardModule);
  }
    console.log("unmounting")
    this.$store.commit(MODULE_NAME + "/resetBatteryCategory");
    this.reset();
  },

async created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, BatteryCategoryWizardModule);
  }
    this.$store.commit(MODULE_NAME + "/resetBatteryCategory");
    this.reset();
  await this.$store.dispatch(MODULE_NAME + "/loadData");
},

destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>

<i18n>
{
    "en" : {
        "hazardous-substance-classifications-wizard.view.title" : "Create Battery Category",
        "hazardous-substance-classifications-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Battery Category.",
        "hazardous-substance-classification-wizard.view.subheader.batteryCategory" : "Battery Category"
    },

    "de" : {
        "hazardous-substance-classifications-wizard.view.title" : "Neue Batterie-Kategorie anlegen",
        "hazardous-substance-classifications-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage einer neuen Batterie-Kategorie.",
        "hazardous-substance-classification-wizard.view.subheader.batteryCategory" : "Batterie-Kategorie"
    }
}
</i18n>


