<template>
    
    <v-sheet class="secondary darken-3" >

        <v-container fluid>
            <v-row>
                <v-col>
                    <div class="text-h6 white--text text-center pt-5">
                        {{  $t('battery-passport-search.view.title') }}
                    </div>
                    <div class="secondary--text text-center pb-5">
                        {{  $t('battery-passport-search.view.subtitle') }}
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field solo :label="$t('battery-passport-search.view.search.label')" prepend-inner-icon="search" @input="onSearchInput" /> 
                </v-col>
            </v-row>
        </v-container>
        <v-divider />
        <v-sheet class="pa-5 secondary darken-3">
            <v-card flat class="secondary darken-2">
                <v-container fluid v-if="searchResultItemsNotEmpty">
                    <v-row align="center">
                        <v-col>
                            <div class="text-h6 white--text text-center pt-5">
                                {{ $t('battery-passport-search.searchResults.title') }}
                            </div>
                            <div class="white--text text-center pt-5">
                                {{ $t('battery-passport-search.searchResults.subtitle', {count: totalItemCount}) }}
                            </div>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col v-for="(item, index) of searchResultItems" :key="`search-result-${index}`" cols="12" md="6" lg="4" xl="3">
                            <v-card tile height="100%">
                                <v-card-text>
                                    <div class="text-h5"> {{ $dxs.i18n.localizedName(item, $i18n.locale) }}</div>
                                    <div class="text-h6"> {{ item.data.batteryPassportId ? item.data.batteryPassportId : '&nbsp;'  }}</div>
                                </v-card-text>
                                <v-divider />
                                <v-card-actions>
                                    <v-btn block text @click="openBatteryPassportDashboard(item.name)">{{  $t('battery-passport-search.searchResults.actions.openBatteryPassport') }}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" lg="4" xl="3" v-if="hiddenItemCount > 0">
                            <v-card tile height="100%">
                                <v-card-text>
                                    <div class="text-h5"> {{ $t("battery-passport-search.searchResults.moreResults.title", {count: hiddenItemCount}) }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container v-else>
                    <v-row>
                        <v-col>
                            <v-card tile>
                                <v-card-text class="text-center">
                                    <div class="text-h6">{{  $t('battery-passport-search.searchResults.noDataFound.title') }}</div>
                                    <div class="">{{  $t('battery-passport-search.searchResults.noDataFound.subtitle') }}</div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-sheet>
    </v-sheet>
</template>

<script>
const MODULE_NAME = 'battery-data-hub:apps:battery-passport:battery-passport-search'; 

import { debounce } from 'lodash';
import BatteryPassportSearchModule from '@/solutions/battery-data-hub/store/apps/battery-passport/battery-passport-search.module';

export default {

    name: 'battery-passport-search',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],
    
    methods: {

        onSearchInput: debounce(async function (input) {

            this.progressIndicatorService.show();
            
            try {
                
                // First make sure that the view is reset properly. 
                await this.$store.dispatch(`${MODULE_NAME}/search`, { searchExpression: input }); 

            } catch (error) {
                this.errorHandlerService.handleError(error);

            } finally {
                this.progressIndicatorService.hide();
            }
           
        }, 500),


        openBatteryPassportDashboard(name) {
            this.$router.push({
                name: 'BatteryPassportDashboardView',
                params: { name: name }
            })
        }
    },

    computed: {

        searchResult() {
            return this.$store.getters[`${MODULE_NAME}/searchResult`];
        },

        searchResultItems() {
            return this.searchResult.items;
        },

        searchResultPagination() {
            return this.searchResult.pagination;
        },

        totalItemCount() {
            return this.searchResultPagination.totalItemCount;
        },

        hiddenItemCount() {
            return this.totalItemCount - this.searchResultItems.length; 
        },

        searchResultItemsNotEmpty() {
            return this.searchResultItems && this.searchResultItems.length > 0;
        }
    },
    
    // ==========================================================================
    // CREATED HOOK
    // ==========================================================================
    created() {
        this.$log.debug(`Creating Battery Passport Dashboard.`)
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, BatteryPassportSearchModule);
            this.$log.debug(`Registered new Vuex Store ${MODULE_NAME}`);

        } else {
            this.$log.debug(`Vuex Store ${MODULE_NAME} is already registered.`)
        }        

    },

    // ==========================================================================
    // DESTROYED HOOK
    // ==========================================================================
    destroyed() {
        
        if (this.$store.hasModule(MODULE_NAME)) {
            this.$store.unregisterModule(MODULE_NAME);
            this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`)
        }        
    }
}
</script>

<i18n>
{
    "en" : {
        "battery-passport-search.view.title" : "Battery Passport",
        "battery-passport-search.view.subtitle" : "You can retrieve the battery pass information using the battery passport ID, the manufacturer's serial number or the technical name of the battery.",

        "battery-passport-search.view.search.label" : "Please enter Battery Passport ID, Manufacurer's Serial Number or the Technical Name of the battery. Partial input is supported.",

        "battery-passport-search.searchResults.title" : "Search Results",
        "battery-passport-search.searchResults.subtitle" : "{count} batteries matching your search term {searchTerm} were found.",
        "battery-passport-search.searchResults.actions.openBatteryPassport" : "Show Battery Passport...",
        "battery-passport-search.searchResults.moreResults.title" : "{count} more Batteries...",
        "battery-passport-search.searchResults.noDataFound.title" : "No Batteries found",
        "battery-passport-search.searchResults.noDataFound.subtitle" : "We could not find any matching batteries for your search term. Please check your search term and adjust your search accordingly."    
    },

    "de" : {
        "battery-passport-search.view.title" : "Batterie-Pass",
        "battery-passport-search.view.subtitle" : "Sie können die Informationen zum Batterie-Pass anhand der Batterie-Pass-Nummer, der Serialnummer des Herstellers oder des technischen Namens der Batterie abrufen.",

        "battery-passport-search.view.search.label" : "Bitte geben Sie die Batterie-Pass-Nummer, die Serialnummer des Herstellers oder den technischen Namen der Batterie ein. Teil-Eingaben werden unterstützt.",

        "battery-passport-search.searchResults.title" : "Suchergebnisse",
        "battery-passport-search.searchResults.subtitle" : "Zu Ihrem Suchbegriff {searchTerm} wurden {count} passende Batterien gefunden.",
        "battery-passport-search.searchResults.actions.openBatteryPassport" : "Batterie-Pass anzeigen...",
        "battery-passport-search.searchResults.moreResults.title" : "{count} weitere Batterien...",
        "battery-passport-search.searchResults.noDataFound.title" : "Keine Batterien gefunden",
        "battery-passport-search.searchResults.noDataFound.subtitle" : "Für Ihren Suchbegriff konnten wir leider keine passenden Batterien finden. Bitte überprüfen Sie Ihre Suche und passen Sie diese gegebenfalls an."    
    }
}
</i18n>