import { get, set } from 'lodash';
import api from '../../../api/battery-data-hub.api';

export default {
    namespaced: true,

    state: {
        batteryModel: createEmptyBatteryModel(),
        tenantId: null,
        batteryCategories: getDefaultResponse(),
        manufacturer: getDefaultResponse(),
        batteryChemistries: getDefaultResponse()
    },

    mutations: {
        setBatteryModel(state, batteryModel) {
            if (batteryModel) {
                state.batteryModel.tenantId                           = batteryModel.tenantId ? batteryModel.tenantId : null;
                state.batteryModel.name                               = batteryModel.name ? batteryModel.name : null;
                state.batteryModel.i18n                               = batteryModel.i18n ? batteryModel.i18n : {};
                state.batteryModel.active                             = batteryModel.active ? batteryModel.active : true;
                state.batteryModel.references                         = batteryModel.references ? batteryModel.references : {};
                state.batteryModel.data.batteryWeight                 = batteryModel.data.batteryWeight ? batteryModel.data.batteryWeight : 0;
                state.batteryModel.data.testReports                   = batteryModel.data.testReports ? batteryModel.data.testReports : [];
                state.batteryModel.data.idOfEuDeclerationOfConformity = batteryModel.data.idOfEuDeclerationOfConformity ? batteryModel.data.idOfEuDeclerationOfConformity : null;
                state.batteryModel.data.euDeclerationOfConformity     = batteryModel.data.euDeclerationOfConformity ? batteryModel.data.euDeclerationOfConformity : null;
            } else {
                state.batteryModel.tenantId = null;
                state.batteryModel.name = null;
                state.batteryModel.i18n = null;
                state.batteryModel.data = null;
                state.batteryModel.active = null;
                state.batteryModel.references = null;
            }
        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.batteryModel.tenantId = tenantId;
            } else {
                state.batteryModel.tenantId = null;
            }
        },

        setCategories(state, batteryCategories) {
            if (batteryCategories) {
                let items = [];
                if (batteryCategories.items) {
                    state.batteryCategories = batteryCategories.items;
                } else {
                    state.batteryCategories = items;
                }

                if (batteryCategories.tenantId) {
                    state.batteryCategories.tenantId = batteryCategories.tenantId;
                }

                if (batteryCategories.pagination) {
                    state.batteryCategories.pagination = batteryCategories.pagination;
                }
            }
        },
        setManufacturer(state, manufacturer) {
            if (manufacturer) {

                let items = [];
                if (manufacturer.items) {
                    state.manufacturer.items = manufacturer.items;
                } else {
                    state.manufacturer.items = items;
                }

                if (manufacturer.tenantId) {
                    state.manufacturer.tenantId = manufacturer.tenantId;
                }

                if (manufacturer.pagination) {
                    state.manufacturer.pagination = manufacturer.pagination;
                }

            }
        },

        setBatteryCategory(state, batteryCategory) {
            if (batteryCategory) {
                state.batteryModel.references.batterycategoryId = batteryCategory;
            } else {
                state.batteryModel.references.batterycategoryId = null;
            }
        },

        setBatteryChemistries(state, batteryChemistries) {
            if (batteryChemistries) {
                state.batteryChemistries = batteryChemistries;
            } else {
                state.batteryChemistries = getDefaultResponse();
            }
        },

        setBatteryChemistryId(state, batteryChemistryId) {
            if (batteryChemistryId) {
                state.batteryModel.references.batteryChemistry = batteryChemistryId;
            } else {
                state.batteryModel.references.batteryChemistry = null;
            }
        }, 

        async setManufacturerId(state, manufacturerId) {
            if (manufacturerId) {

                state.batteryModel.references.manufacturerId = manufacturerId;

            } else {
                state.batteryModel.references.manufacturerId = null;
            }
        },
    },

    actions: {
        async loadCategories(context) {
            try {
                let response = await api.masterdata.batteryModels.findBatteryCategoriesByFilter('*', 0, 100);
                context.commit('setCategories', response);
            } catch (error) {
                context.commit('setCategories', getDefaultResponse());
                throw error;
            }
        },


        async loadManufacturers(context) {

            try {
                let manufacturer = await api.masterdata.manufacturers.findByFilter('*', 0, 100);
                await context.commit('setManufacturer', manufacturer);
            } catch (error) {
                context.commit('setManufacturer', getDefaultResponse());
                throw error;
            }
        },

        async loadBatteryChemistries(context) {

            try {
                let batteryChemistries = await api.masterdata.batteryChemistries.findByFilter('*', 0, 100);
                await context.commit('setBatteryChemistries', batteryChemistries);
            } catch (error) {
                context.commit('setBatteryChemistries', getDefaultResponse());
                throw error;
            }
        },

        async createBatteryModel(context) {
            try {
                let request = context.getters.request;
                await api.masterdata.batteryModels.create(request);
            } catch (error) {
                console.log("An error has occured" + error);
                return Promise.reject(error);
            }
        },

        async resetBatteryModel(context) {
            context.commit('setBatteryModel', createEmptyBatteryModel());
        },

        async updateBatteryModel(context, batteryModel) {
            try {
                context.commit('setBatteryModel', batteryModel);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        updateBatteryCategory(context, batteryCategory) {
            try {
                context.commit('setBatteryCategory', batteryCategory);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async updateManufacturerId(context, manufacturerId) {
            try {
                context.commit('setManufacturerId', manufacturerId);
            } catch (error) {
                return Promise.reject(error);
            }

        },

        async updateBatteryChemistryId(context, batteryChemistryId) {
            try {
                context.commit('setBatteryChemistryId', batteryChemistryId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },

    getters: {
        request(state) {
            let payload = state.batteryModel;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        batteryModel(state) {
            return state.batteryModel ? state.batteryModel : createEmptyBatteryModel();
        },

        batteryCategories(state) {
            return state.batteryCategories ? state.batteryCategories : getDefaultResponse();
        },

        batteryChemistries(state) {
            return state.batteryChemistries ? state.batteryChemistries : getDefaultResponse();
        },

        batteryChemistryId(state) {
            return state.batteryModel && state.batteryModel.references ? state.batteryModel.references.batteryChemistry : null;
        },

        batteryCategory(state) {
            return get(state, 'batteryModel.references.batterycategoryId');
        },

        manufacturer(state) {
            return state.manufacturer ? state.manufacturer : getDefaultResponse();
        },
        
        manufacturerId(state) {
            return get(state, 'batteryModel.references.manufacturerId');
        },

    }
}

function createEmptyBatteryModel() {
    return {
        tenantId: null,
        name: null,
        i18n: {},
        data: {
            batteryWeight: 0,
            idOfEuDeclerationOfConformity: null,
            euDeclerationOfConformity: null,
            testReports: []
        },
        active: true,
        references: {
            batterycategoryId: null,
            manufacturerId: null,
            batteryChemistry: null
        },
    };
}

function getDefaultResponse() {
    return {
        tenantId: "",
        items: [],
        pagination: {}
    };
}

