import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {
    namespaced: true,

    state : {

        batteryChemistry: createDefaultBatteryChemistry(),
    },

    mutations: {  

        setBatteryChemistry(state, batteryChemistry) {

            if (batteryChemistry) {
                state.batteryChemistry.tenantId = batteryChemistry.tenantId;
                state.batteryChemistry.name = batteryChemistry.name;
                state.batteryChemistry.i18n = batteryChemistry.i18n;
                state.batteryChemistry.data = batteryChemistry.data;
                state.batteryChemistry.references = batteryChemistry.references;
                state.batteryChemistry.active = batteryChemistry.active;
                state.batteryChemistry.creationDateTime     = batteryChemistry.creationDateTime;
                state.batteryChemistry.creationUser         = batteryChemistry.creationUser;
                state.batteryChemistry.modificationDateTime = batteryChemistry.modificationDateTime;
                state.batteryChemistry.modificationUser     = batteryChemistry.modificationUser;
            }
            
        },
        
        async updateBatteryChemistrys(context, batteryChemistry) {

            try {
                context.commit('setBatteryChemistry', batteryChemistry);
            } catch (error) {
                return Promise.reject(error);
            }
        },        



    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.batteryChemistries.findById(name);
                get(response, 'item.hazardousSubstanceClassification.name', createDefaultBatteryChemistry())
                context.commit('setBatteryChemistry', get(response, 'item.batteryChemistry', createDefaultBatteryChemistry()));
            
            } catch (error) {
                context.commit('setBatteryChemistry', createDefaultBatteryChemistry());
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters.model, 'batteryChemistry');
                console.log(">>> VUEX SAVE DATA ", JSON.stringify(payload));
                let response = await api.masterdata.batteryChemistries.update(payload);
                console.log('#################', JSON.stringify(response));
                context.commit('setBatteryChemistry', get(response, 'item.batteryChemistry', createDefaultBatteryChemistry()));

            } catch (error) {
                console.log(error);
                context.commit('setBatteryChemistry', createDefaultBatteryChemistry());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            console.log('+++ updateModel ', JSON.stringify(model, true, 2));
            context.commit('setBatteryChemistry', get(model, 'batteryChemistry', createDefaultBatteryChemistry()));
        },

        updateBatteryChemistry(context, batteryChemistry) {
            console.log('+++ updateBatteryChemistry ', JSON.stringify(batteryChemistry, true, 2));
            context.commit('setBatteryChemistry', batteryChemistry);
        },
        
    },

    getters: {
        
        request(state) {
            let payload = state.batteryChemistry;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },
        batteryChemistry(state) {
            return state.batteryChemistry ? state.batteryChemistry : createDefaultBatteryChemistry();
        },
        
        model(state) {
                 return {
                    batteryChemistry: state.batteryChemistry
            }
        },
        
        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        
        
    }
}

function createDefaultBatteryChemistry() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {},
        active: null,
        references: {},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}
