<template>
    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog :value="batteryStatus" :title="$t('battery-status-wizard.view.title')"
                    :subtitle="$t('battery-status-wizard.view.subtitle')" color="secondary" @dialog-saved="save"
                    @dialog-canceled="cancel">
                    <template v-slot:default="props">
                        <v-card flat>
                            <v-card-text>
                                <c-name-input v-model="props.item.name" filled />
                                <v-card tile flat>
                                    <v-card-text>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                        <div>{{ $t('battery-status-editor.view.sections.color.subtitle') }}</div>
                                        <c-color-single-selector :items="defaultColors" v-model="statusColor" flat
                                            color="white" />
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </template>

                </c-base-dialog>
            </v-col>

        </v-row>
    </v-container>
</template>
  
<script>
import ColorItems from "../../../constants/colors.constants";
import BatteryStatusWizardModule from "../../../store/masterdata/battery-status/battery-status-wizard.module";

const MODULE_NAME = "batteryStatusWizard";

export default {
    name: "battery-status-wizard",

    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "envContextService",
    ],


    methods: {
        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/createBatteryStatus");

                this.$router.push({
                    name: "BatteryStatusEditorView",
                    params: { name: this.batteryStatus.name },
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({
                name: "BatteryStatusListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetBatteryStatus");
            this.setTenantId(this.tenantId);
        },
    },

    computed: {
        batteryStatus: {
            get() {
                return this.$store.getters[MODULE_NAME + "/batteryStatus"] || {};
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_NAME + "/updateBatteryStatus", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },
        statusColor: {
            get() {
                return this.$store.getters[MODULE_NAME + "/statusColor"];
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateColor", value);
            }
        },

        defaultColors() {
            return ColorItems;
        },

        tenantId() {
            return this.envContextService.tenantId;
        }

    },

    created() {
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, BatteryStatusWizardModule);
        }

        this.reset();
    },

    destroyed() {
        // Unregister the vuex store if it has been registered before.
        if (this.$store.hasModule(MODULE_NAME)) {
            this.$store.unregisterModule(MODULE_NAME);
            this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
        }
    }
};
</script>
  
<i18n>
  {
      "en" : {
          "battery-status-wizard.view.title" : "Create New Battery Status",
          "battery-status-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Battery Status.",
          "battery-status-editor.view.sections.color.subtitle" : "Please assign the Battery Status a color with which it can be displayed in the user interface."
        
      },
  
      "de" : {
          "battery-status-wizard.view.title" : "Neuen Batterie-Status anlegen",
          "battery-status-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Batterie-Status.",
          "battery-status-editor.view.sections.color.subtitle" : "Bitte weisen Sie dem Batterie-Status eine Farbe zu, damit er in der Benutzeroberfläche dargestellt werden kann."
      }
  }
  </i18n>
  
  
  