<template>
    <v-card :tile="tile" :flat="flat" height="100%" :class="{'grey lighten-3' : itemsEmpty}">
        
        <!-- Header -->
        <v-container fluid class="pa-3" >
            <v-row>
                <v-col cols="9" class="text-left">
                    <div class="text-h6 grey--text">{{title}}</div>
                    <div class="text-subtitle-1 grey--text">
                        {{subtitleOrDefault}}</div>
                </v-col>
                <v-col cols="3" class="text-right">
                    <v-menu v-if="!hideChartTypeSelector && !hideChart">
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                        </template>

                        <v-list>
                            <slot name="menu-items-prepend">
                                 <!-- Add further items -->
                            </slot>
                            <v-subheader>{{$t('data-distribution-card.component.menu.charts.title')}}</v-subheader>
                            <v-list-item @click="viewTypeInternal = 'treemap'">
                                <v-list-item-avatar>
                                    <v-icon v-if="viewTypeInternal === 'treemap'">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{$t('data-distribution-card.component.menu.charts.treemap')}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="viewTypeInternal = 'pie'">
                                <v-list-item-avatar>
                                    <v-icon v-if="viewTypeInternal === 'pie'">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{$t('data-distribution-card.component.menu.charts.pie')}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="viewTypeInternal = 'donut'">
                                <v-list-item-avatar>
                                    <v-icon v-if="viewTypeInternal === 'donut'">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{$t('data-distribution-card.component.menu.charts.donut')}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            
                            <slot name="menu-items-append">
                                <!-- Add further items -->
                            </slot>
                        </v-list>
                    </v-menu>
                </v-col> 
            </v-row>
        </v-container>
        
        <template v-if="!itemsEmpty">
        <!-- Chart -->
        <slot name="chart">
            <div v-if="!hideChart">
            <v-lazy>
                <c-metrics-distribution-chart :items="items.filter(e => e.value > 0)" :type="viewTypeInternal" show-value-label />
            </v-lazy>
            </div>
        </slot>
        <v-divider />

        <!-- Table -->
        <slot name="table">
            <div v-if="!hideTable">
            <v-simple-table>
                <thead v-if="$vuetify.breakpoint.mdAndUp">
                    <tr>
                        <th>{{$t('data-distribution-card.component.table.cols.name')}}</th>
                        <th class="text-right">{{$t('data-distribution-card.component.table.cols.shareAbsolute')}}</th>
                        <th class="text-right">{{$t('data-distribution-card.component.table.cols.sharePercent')}}</th>
                        <th>{{$t('data-distribution-card.component.table.cols.shareOfTotalPercent')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) of items" :key="'tr_overview_' + index" >
                        <template v-if="$vuetify.breakpoint.mdAndUp">
                        <td>{{$dxs.formatting.truncateMiddle($dxs.i18n.localizedName(item, $i18n.locale), 24)}}</td>
                        <td class="text-right">{{$dxs.formatting.formatInteger(item.value, $i18n.locale)}}</td>
                        <td class="text-right">{{$dxs.formatting.formatDecimal(toRoundedPercentage(item.value, itemSum), $i18n.locale)}}</td>
                        <td width="40%">
                            <v-progress-linear :color="item.color" background-color="grey lighten-3" :value="toRoundedPercentage(item.value, itemSum)"  height="20">

                            </v-progress-linear>
                        </td>
                        </template>
                        <template v-else>
                            <td>
                                <v-progress-linear class="white--text" :color="item.color" background-color="grey lighten-3" :value="toRoundedPercentage(item.value, itemSum)"  height="40">
                                    {{$dxs.formatting.truncateMiddle($dxs.i18n.localizedName(item, $i18n.locale), 24)}} | {{$dxs.formatting.formatInteger(item.value, $i18n.locale)}} | {{$dxs.formatting.formatDecimal(toRoundedPercentage(item.value, itemSum), $i18n.locale)}}%
                                </v-progress-linear>
                            </td>
                        </template>
                            
                    </tr>
                </tbody>
            </v-simple-table>
            </div>
        </slot>
        <slot name="actions">
            
        </slot>
        </template>
        <template v-else>
            <v-card-text />
            <v-card-text class="grey lighten-3 text-center pa-5">
                <div>
                    <v-icon x-large color="grey lighten-1">domain_disabled</v-icon>
                    <div class="text-subtitle-1 grey--text pt-5">{{$t('data-distribution-card.component.table.cols.shareOfTotalPercent.noData.title')}}</div>
                    <div class="text-body-2 grey--text pt-5">{{$t('data-distribution-card.component.table.cols.shareOfTotalPercent.noData.subtitle')}}</div>
                </div>
            </v-card-text>
        </template>
</v-card>
</template>

<script>


export default {

    props: {

        title: {
            type: String,
            required: true
        },

        subtitle: {
            type: String,
            required: false,
        },

        items : {
            type: Array,
            required: true,
            default: () => []
        },


        chartType: {
            type: String,
            required: true,
            default: 'treemap'
        },

        tile : {
            type: Boolean,
            required: false,
            default: false
        },

        flat : {
            type: Boolean,
            required: false,
            default: false
        },

        hideChartTypeSelector: {
            type: Boolean,
            required: false,
            default: false
        },

        hideChart: {
            type: Boolean,
            required: false,
            default: false
        },

        hideTable: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data: () => ({
        viewTypeInternal: 'treemap'
    }),

    watch: {
        chartType: {
            immediate: true,
            handler(value) {
                this.viewTypeInternal = value;
            }
        }
    },
    
    methods: {
        toRoundedPercentage(value, sum) {

            const result = (sum === 0) ? 0 : this.$dxs.rounding.round(this.$dxs.math.percentOf(value, sum), 2);
            this.$log.debug(`PERCENT OF (${value}, ${sum}) := ${result}`)
            return result;
        }
    },

    computed: {

        

        itemCount() {
            return this.items ? this.items.length : 0;
        },

        itemSum() {
            let sum = 0;
            if (this.items) {
                this.items.forEach(e => sum = sum + e.value);
            }
            return sum;
        },



        itemsEmpty() {
            return this.itemCount < 2;
        },

        /**
         * Returns the subtitle prop or a default value, if the prop is not defined. 
         */
        subtitleOrDefault() {
            
            return this.subtitle ? this.subtitle : this.$t('data-distribution-card.component.subtitle', { count : this.itemCount });
        }


    },

}
</script>

<i18n>
{
    "en" : {
        "data-distribution-card.component.subtitle" : "Top {count} Values",
        "data-distribution-card.component.menu.charts.title" : "Chart Type",
        "data-distribution-card.component.menu.charts.treemap" : "Treemap Chart",
        "data-distribution-card.component.menu.charts.pie" : "Pie Chart",
        "data-distribution-card.component.menu.charts.donut" : "Donut Chart",

        "data-distribution-card.component.table.cols.name" : "Name",
        "data-distribution-card.component.table.cols.shareAbsolute" : "Share #",
        "data-distribution-card.component.table.cols.sharePercent" : "Share %",
        "data-distribution-card.component.table.cols.shareOfTotalPercent" : "Share of Total",

        "data-distribution-card.component.table.cols.shareOfTotalPercent.noData.title" : "This attribute does not contain any values for display.",
        "data-distribution-card.component.table.cols.shareOfTotalPercent.noData.subtitle" : "The absence of values can possibly be caused by the filtering."

    },

    "de" : {
        "data-distribution-card.component.subtitle" : "Top {count} Werte",
        "data-distribution-card.component.menu.charts.title" : "Diagramm-Typ",
        "data-distribution-card.component.menu.charts.treemap" : "Treemap-Diagramm",
        "data-distribution-card.component.menu.charts.pie" : "Kuchen-Diagramm",
        "data-distribution-card.component.menu.charts.donut" : "Donut-Diagramm",

        "data-distribution-card.component.table.cols.name" : "Name",
        "data-distribution-card.component.table.cols.shareAbsolute" : "Anteil #",
        "data-distribution-card.component.table.cols.sharePercent" : "Anteil %",
        "data-distribution-card.component.table.cols.shareOfTotalPercent" : "Anteil Gesamtmenge",

        "data-distribution-card.component.table.cols.shareOfTotalPercent.noData.title" : "Dieses Attribut besitzt keine Werte zur Anzeige.",
        "data-distribution-card.component.table.cols.shareOfTotalPercent.noData.subtitle" : "Das Fehlen von Werten kann u.U. durch die Filterung verursacht werden."
    }
}
</i18n>