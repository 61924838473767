<template>
    <v-card tile flat>
        <c-sticky-content>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('shift-event-log-dashboard.view.title', {name}) }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items v-if="hasReadPermissions">
                    <v-btn icon @click="add" v-if="hasCreatePermissions"><v-icon class="mr-3">add</v-icon></v-btn>
                    <v-btn icon @click="showFilter"><v-icon>filter_list</v-icon></v-btn>
                    <v-btn icon @click="refresh"><v-icon>refresh</v-icon></v-btn>
                    <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <dxs-shift-event-log-filter-summary :value="filterSummary" v-if="$vuetify.breakpoint.mdAndUp"/>
            <v-divider />
        </c-sticky-content>

        
        <template v-if="!hasReadPermissions">
           <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <dxs-required-roles-missing-card :items="missingRoles" tile />
                    </v-col>
                </v-row>
           </v-container>
        </template>
        <template v-else>
            <!-- *************************** --> 
            <!-- Filter -->
            <!-- *************************** -->
            <v-navigation-drawer
                fixed
                right
                temporary
                v-model="filterDialog.display"
                width="500"
                >
                <v-card tile height="100%" >
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('shift-event-log-dashboard.view.filter.title') }}</v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items>
                            <v-btn color="accent" @click="applyFilter" text>{{ $t('shift-event-log-dashboard.view.filter.actions.apply') }}</v-btn>
                            <v-btn color="accent" @click="resetFilter" text>{{ $t('shift-event-log-dashboard.view.filter.actions.reset') }}</v-btn>
                            
                            <v-btn color="grey" @click="hideFilter" icon><v-icon>close</v-icon></v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-divider />
                    <v-subheader>{{ $t('shift-event-log-dashboard.view.filter.timeRange.title')}}</v-subheader>
                    <v-card-text>
                        <c-date-time-selector 
                            :label="$t('shift-event-log-dashboard.view.filter.timeRange.fromDateTime.label')" 
                            :value="dateTimeFromFilter"
                            @selection-changed="dateTimeFromFilter = $event.date" />
                        <c-date-time-selector 
                            :label="$t('shift-event-log-dashboard.view.filter.timeRange.untilDateTime.label')" 
                            :value="dateTimeUntilFilter"
                            @selection-changed="dateTimeUntilFilter = $event.date"
                        />
                    </v-card-text>
                    <v-divider />
                    <v-subheader>{{ $t('shift-event-log-dashboard.view.filter.shiftEventTypes.title')}}</v-subheader>
                    <v-card-text>
                        <c-item-checkbox-multi-selector 
                            v-model="shiftEventTypeFilter" 
                            :items="shiftEventTypes" 
                            />
                    </v-card-text>
                    <v-divider />
                    <v-subheader>{{ $t('shift-event-log-dashboard.view.filter.workplaces.title')}}</v-subheader>
                    <v-card-text>
                        <c-item-checkbox-multi-selector 
                            v-model="workplaceFilter" 
                            :items="workplaces" 
                            />
                    </v-card-text>
                </v-card>
            </v-navigation-drawer>

            <!-- *************************** --> 
            <!-- Content -->
            <!-- *************************** -->        

            <template v-if="eventsEmpty">
                <v-container fluid class="grey lighten-3">
                    <v-row>
                        <v-col cols="12">
                            <v-card tile height="100%">
                                <v-card-text class="text-center">
                                    <div class="text-h6">
                                        {{ $t('shift-event-log-dashboard.view.noEvent.title') }}
                                    </div>
                                    <div class="text-body-2">
                                        {{ $t('shift-event-log-dashboard.view.noEvent.subtitle') }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn fab color="accent" x-large @click="add"  v-if="hasCreatePermissions"><v-icon>add</v-icon></v-btn>
                                    </v-card-text>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-else>
                <v-btn color="accent" x-large fab fixed bottom right @click="add"  v-if="hasCreatePermissions"><v-icon>add</v-icon></v-btn>
                <v-container fluid class="grey lighten-3">
                    <v-row>
                        <v-col lg="2" md="3" cols="12">
                            <dxs-shift-event-log-result-summary :items="items"></dxs-shift-event-log-result-summary>
                        </v-col>
                        <v-col lg="10" md="9" cols="12">
                            <v-container fluid>
                                <v-row>
                                    <v-col>
                                        <v-card tile>
                                            <v-card-text class="text-h6">{{ $t('shift-event-log-dashboard.view.shiftEvents.title', {count: $dxs.formatting.formatInteger(eventCountCurrent, $i18n.locale), total: $dxs.formatting.formatInteger(eventCountTotal, $i18n.locale)})}}</v-card-text>
                                            <v-divider />
                                            <v-card-text class="grey lighten-3">
                                                <v-timeline :dense="$vuetify.breakpoint.mdAndDown">
                                                    <v-timeline-item v-for="(item, index) of items" :key="`timeline-item-${index}`" color="secondary">
                                                        <template v-slot:opposite>
                                                            <span class="text-body-1">{{ $dxs.formatting.formatDateTime(getDateTime(item), $i18n.locale) }}</span>
                                                        </template>
                                                        <dxs-shift-event-tile :value="item" />
                                                    </v-timeline-item>
                                                </v-timeline>
                                            </v-card-text>
                                            <c-query-result-pagination-panel
                                                :items="items"
                                                :pagination="pagination"
                                                @load-next-page="onLoadMore"
                                                />
                                        </v-card>

                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </template>

        </template>

    </v-card>
</template>

<script>
import Roles from '@/roles';
import { get } from 'lodash';
import { DateTime, Duration } from 'luxon';
import ShiftEventLogDashboardModule from '@/solutions/smart-factory-services/store/apps/shift-event-log/shift-event-log-dashboard.module';
import ShiftEventTileComponent from '@/solutions/smart-factory-services/components/shift-events/shift-event-tile.component';
import ShiftEventLogFilterSummaryComponent from '@/solutions/smart-factory-services/components/shift-events/shift-event-log-filter-summary.component';
import ShiftEventLogResultSummaryComponent from '@/solutions/smart-factory-services/components/shift-events/shift-event-log-result-summary.component';
import RequiredRolesMissingCardComponent from '@/core/components/usermgmt/required-roles-missing-card.component';

const MODULE_NAME = 'smart-factory-services:apps:shift-event-log:shift-event-log-dashboard';

export default {

    name: 'shift-event-log-dashboard',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    components: {
        'dxs-shift-event-tile' : ShiftEventTileComponent,
        'dxs-shift-event-log-filter-summary' : ShiftEventLogFilterSummaryComponent,
        'dxs-shift-event-log-result-summary' : ShiftEventLogResultSummaryComponent,
        'dxs-required-roles-missing-card' : RequiredRolesMissingCardComponent
    },

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {

        return {
            filterDialog: {
                display: false,
            }
        }
    },

    
    watch: {

        value: {
            
            async handler(newValue, oldValue) {


                this.$log.debug(`Watcher: Update on 'value': Change from ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)} `);

                const newName = get(newValue, 'name', null);
                const oldName = get(oldValue, 'name', null);
                if (newName !== oldName) {
                    if (newName) {
                        await this.init(newValue);
                    }
                } else {
                    this.$log.debug(`New and old value '${newName}' are the same. Re-initialization will be omitted.`);
                }
            },

        }
    },

    methods: {

        async init(name) {

            // Set the site id
            this.$log.debug(`Applying site id '${name}' as filter criterion.`)
            await this.$store.dispatch(`${MODULE_NAME}/updateSiteIdFilter`, name);
            

            // 
            await this.resetFilter();

            /*            
            // Initialize the time range filter.
            const dateTimeFrom = DateTime.now().minus(Duration.fromMillis(1000 * 3600 * 8)).toISO();
            await this.$store.dispatch(`${MODULE_NAME}/updateDateTimeFromFilter`, dateTimeFrom);
            
            const dateTimeUntil = DateTime.now().toISO();
            await this.$store.dispatch(`${MODULE_NAME}/updateDateTimeUntilFilter`, dateTimeUntil);

            // Trigger a reload of data.
            this.$log.debug(`Call refresh() from init(${name}) method.`);
            await this.refresh();
            */
        },

        showFilter() {
            this.filterDialog.display = true;
        },

        hideFilter() {
            this.filterDialog.display = false;
        },

        async applyFilter() {
            await this.refresh();
            this.hideFilter();
        },  

        async resetFilter() {

            const now = DateTime.now();
            this.dateTimeFromFilter  = now.minus(Duration.fromMillis(1000 * 3600 * 8)).toISO();
            this.dateTimeUntilFilter = now.toISO();
            
            this.workplaceFilter = [];
            this.shiftEventTypeFilter = [];

            await this.applyFilter();
        },


        async refresh() {

            this.$log.debug(`Refresh: Resetting model and reloading data with current filter settings.`)
            this.progressIndicatorService.show();
            
            try {
                this.$log.debug(`Resetting the model ${this.name}`);
                await this.$store.dispatch(`${MODULE_NAME}/resetModel`);
                this.$log.debug(`Loading shift event logs for site ${this.name}`);
                await this.$store.dispatch(`${MODULE_NAME}/loadData`);
            
            } catch (error) {
                this.errorHandlerService.handleError(error);
            
            } finally {
                this.progressIndicatorService.hide();
            }            
        },

        async onLoadMore(){
            this.progressIndicatorService.show();
            
            try {

                await this.$store.dispatch(`${MODULE_NAME}/loadData`);
            
            } catch (error) {
                this.errorHandlerService.handleError(error);
            
            } finally {
                this.progressIndicatorService.hide();
            } 
        },  

        add() {
            // This action redirects to the shift event log wizard
            this.openShiftEventLogWizard(this.site);
        },  

        openShiftEventLogWizard({name}) {
            this.$log.debug(`Opening shift event log wizard for site ${name}.`);
            this.$router.push({
                name: "ShiftEventLogWizardView",
                params: {
                    siteId: name,
                },
            });
        },

        close() {
            this.$router.go(-1);
        },

        getDateTime(item) {
            const dateTime = get(item, 'shiftEvent.data.dateTime', null);
            return dateTime;
        },
    },

    computed: {

        
        filterSummary() {
            return {
                filter: this.filter,
                workplaces: this.workplaceMap,
                shiftEventTypes: this.shiftEventTypeMap
            }
        },

        // ============================================
        // Filter
        // ============================================

        filter() {
            return this.$store.getters[`${MODULE_NAME}/filter`];
        },

        dateTimeFromFilter: {
            
            get() {
                return get(this.filter, 'dateTimeFrom', DateTime.now().toISO());
            },

            set(dateTimeFrom) {
                
                this.$store.dispatch(`${MODULE_NAME}/updateDateTimeFromFilter`, dateTimeFrom);
            }
        },

        dateTimeUntilFilter: {
            
            get() {
                return get(this.filter, 'dateTimeUntil', DateTime.now().toISO());
            },

            set(dateTimeUntil) {
                
                this.$store.dispatch(`${MODULE_NAME}/updateDateTimeUntilFilter`, dateTimeUntil);
            }
        },

        workplaceFilter: {
            get() {
                return get(this.filter, 'workplaces');
            },

            set(workplaces) {
                this.$store.dispatch(`${MODULE_NAME}/updateWorkplaceFilter`, workplaces);
            }
        },

        shiftEventTypeFilter: {
            get() {
                return get(this.filter, 'shiftEventTypes');
            },

            set(workplaces) {
                this.$store.dispatch(`${MODULE_NAME}/updateShiftEventTypeFilter`, workplaces);
            }
        },

        // ============================================
        // Model
        // ============================================
        name() {
            return get(this.value, 'name');
        },

        siteId() {
            return this.name;
        },

        model() {
            return this.$store.getters[`${MODULE_NAME}/model`];
        },

        events() {
            return get(this.model, 'events', []);
        },

        eventsEmpty() {
            return this.events.length <= 0;
        },  

        site() {
            return get(this.model, 'site');
        },

        workplaces() {
            return get(this.model, 'workplaces', []);
        },

        /**
         * Provide a mapped view of the workplaces array in order to enable fast lookups.
         */
        workplaceMap() {

            const workplaceMap = {};
            for (const workplace of this.workplaces) {
                workplaceMap[workplace.name] = workplace;
            }
            
            return workplaceMap;
            
        },

        shiftEventTypes() {
            return get(this.model, 'shiftEventTypes', []);
        }, 

        shiftEventTypeMap() {
            const shiftEventTypeMap = {};
            for (const shiftEventType of this.shiftEventTypes) {
                shiftEventTypeMap[shiftEventType.name] = shiftEventType;
            }
            return shiftEventTypeMap;
        },

        /**
         * Wraps each shift event into a context object with its required masterdata.
         */
        items()  {
            const items = [];
            
            for (const event of this.events) {


                //Lookup corresponding workplace for this event
                const workplaceId = get(event, 'data.workplace', null);
                const workplace = this.workplaceMap[workplaceId];
                if (!workplace) {
                    this.$log.warn(`The workplace '${workplaceId}' for event ${event.id} could not be found. Does this workplace actually exist?`);
                }

                const shiftEventTypeId = get(event, 'data.eventType', null);
                const shiftEventType = this.shiftEventTypeMap[shiftEventTypeId];
                if (!shiftEventType) {
                    this.$log.warn(`The Shift Event Type '${shiftEventType}' for event ${event.id} could not be found. Does this shift event type actually exist?`);
                }


                items.push({
                    site: this.site,
                    shiftEvent: event,
                    workplace: workplace,
                    shiftEventType: shiftEventType
                });
            }

            return items;
        },

        pagination() {
            return this.$store.getters[`${MODULE_NAME}/pagination`];
        },

        eventCountCurrent() {
            return this.items.length;
        },

        eventCountTotal() {
            return get(this.pagination, 'totalItemCount', 0);
        },

        requiredReadPermissions() {
            return [
                Roles.SHIFTEVENTS_READ,
                Roles.SITES_READ,
                Roles.WORKPLACE_READ,
                Roles.WORKPLACETYPES_READ,
                Roles.SHIFTEVENTTYPE_READ
            ];
        },

        missingRoles() {

            const missingRoles = [];
            for (const permission of this.requiredReadPermissions) {
                if (!this.userContextService.hasRole(permission)) {
                    missingRoles.push(permission);
                }
            }

            return missingRoles;
        },

        hasReadPermissions() {
            return this.userContextService.hasAllRoles(this.requiredReadPermissions);
        },

        hasCreatePermissions() {
            return this.userContextService.hasRole(Roles.SHIFTEVENTS_CREATE);
        }


    },

    async created() {
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME,ShiftEventLogDashboardModule);
            this.$log.debug(`Registered new Vuex Store ${MODULE_NAME}`);

            // Only initialize a new view. Otherwise keep the populated values.
            if (this.value && this.value.name) {
                this.$log.warn(`Initializating view for ${this.value.name}.`);
                await this.init(this.value.name);
            } else {
                this.$log.warn(`The prop 'value.name' is not set: Initialization is skipped.`);
            }
            
        } else {
            this.$log.debug(`Vuex Store ${MODULE_NAME} is already registered.`)
        }        
    },

    destroyed() {
        if (this.$store.hasModule(MODULE_NAME)) {
            this.$store.unregisterModule(MODULE_NAME);
            this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`)
        }        
    }
    
}
</script>

<i18n>
{
    "en" : {
        "shift-event-log-dashboard.view.title" : "Shift Event Log {name}",

        "shift-event-log-dashboard.view.noEvent.title" : "No Shift Events Found",
        "shift-event-log-dashboard.view.noEvent.subtitle" : "There are no shift events corresponding to your current filter settings: Please adjust your filter settings, in order to display further shift events.",

        "shift-event-log-dashboard.view.filter.title" : "Filter",
        "shift-event-log-dashboard.view.filter.actions.apply" : "Apply",
        "shift-event-log-dashboard.view.filter.actions.reset" : "Reset",
        "shift-event-log-dashboard.view.filter.timeRange.title" : "Time Range",
        "shift-event-log-dashboard.view.filter.timeRange.fromDateTime.label" : "From (Date / Time)",
        "shift-event-log-dashboard.view.filter.timeRange.untilDateTime.label" : "Until (Date / Time)",
        "shift-event-log-dashboard.view.filter.shiftEventTypes.label" : "Shift Event Types",
        "shift-event-log-dashboard.view.filter.workplaces.label" : "Workplaces",

        "shift-event-log-dashboard.view.filter.workplaces.title" : "Workplaces",
        "shift-event-log-dashboard.view.filter.shiftEventTypes.title" : "Shift Event Types",

        "shift-event-log-dashboard.view.shiftEvents.title" : "Shift Events ({count} of {total})",

        "shift-event-log-dashboard.view.actions.add" : "Add"
    },

    "de" : {
        "shift-event-log-dashboard.view.title" : "Schichtbuch {name}",
        
        "shift-event-log-dashboard.view.noEvent.title" : "Keine Schichtmeldungen zur Anzeige vorhanden",
        "shift-event-log-dashboard.view.noEvent.subtitle" : "Für diese Filterbedingungen gibt es keine Schichtmeldungen zur Anzeige. Bitte passen Sie die Filtereinstellungen an, um weitere Schichtmeldungen anzuzeigen.",

        "shift-event-log-dashboard.view.filter.title" : "Filter",
        "shift-event-log-dashboard.view.filter.actions.apply" : "Anwenden",
        "shift-event-log-dashboard.view.filter.actions.reset" : "Zurücksetzen",
        "shift-event-log-dashboard.view.filter.timeRange.title" : "Zeitraum",
        "shift-event-log-dashboard.view.filter.timeRange.fromDateTime.label" : "Von (Datum / Uhrzeit)",
        "shift-event-log-dashboard.view.filter.timeRange.untilDateTime.label" : "Bis (Datum / Uhrzeit)",
        "shift-event-log-dashboard.view.filter.workplaces.title" : "Arbeitsplätze",
        "shift-event-log-dashboard.view.filter.workplaces.label" : "Arbeitsplätze",
        "shift-event-log-dashboard.view.filter.shiftEventTypes.label" : "Schichtmeldungsarten",
        "shift-event-log-dashboard.view.filter.shiftEventTypes.title" : "Schichtmeldungsarten",


        "shift-event-log-dashboard.view.shiftEvents.title" : "Schichtmeldungen ({count} von {total})",

        "shift-event-log-dashboard.view.actions.add" : "Neu"
    }
}
</i18n>