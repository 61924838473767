<template>
    <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form :title="$t('batteryPack-editor.view.title', { name: name })" :value="batteryPack"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole">

            <template v-slot:default>
                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('batteryPack-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('batteryPack-editor.view.tabs.settings') }}</v-tab>
                        <v-tab :key="2">{{ $t('batteryPack-editor.view.tabs.json') }}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="batteryPack" :disabled="!hasEditRole"/>
                            </v-tab-item>

                            <!-- manufacturerplant Settings (manufacturerplant, etc.) -->
                            <v-tab-item :key="1">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('batteryPack-editor.view.component.title') }}</div>
                                        <div>{{ $t('batteryPack-editor.view.component.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12"  lg="6">
                                        <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("batteryPack-editor.view.sections.manufacturerplant.title") }}</div>
                                        <div>{{ $t('batteryPack-editor.view.sections.manufacturerplant.subtitle') }}</div>
                                        <c-item-selector :items="manufacturerplant.items" 
                                          :disabled="!hasEditRole"
                                          :readonly="!hasEditRole"
                                          v-model="manufacturerplantid" :return-object="false" filled
                                          :chips="false"
                                          :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')]"
                                          :label="$t('batteryPack-wizard.view.subheader.manufacturerplant')"></c-item-selector>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                    
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                        {{ $t("batteryPack-editor.view.sections.batterystatus.title") }}</div>
                                    <div>{{ $t('batteryPack-editor.view.sections.batterystatus.subtitle') }}</div>
                                    <c-item-selector :items="batterystatuss.items" v-model="batterystatusid" :return-object="false" filled
                                      :chips="false"
                                      :disabled="!hasEditRole"
                                      :readonly="!hasEditRole"
                                      :label="$t('batteryPack-wizard.view.subheader.batterystatus')"
                                      :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')]"
                                        ></c-item-selector>
                                    </v-col>
                                  <v-col cols="12">
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                        {{ $t("batteryPack-editor.view.sections.batteryModel.title") }}</div>
                                    <div>{{ $t('batteryPack-editor.view.sections.batteryModel.subtitle') }}</div>
                                    <v-text-field :value= batteryModel filled
                                    :disabled="true"
                                    />
                                  </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("batteryPack-editor.view.sections.location.title") }}</div>
                                    <div>{{ $t('batteryPack-editor.view.sections.location.subtitle') }}</div>
                                
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="batteryPack.data.batteryPassportId" filled
                                    :label="batteryPassportIdLabel"
                                    :disabled="!hasEditRole"
                                    :readonly="!hasEditRole"
                                    />
                                  </v-col>

                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="batteryPack.data.manufacturerSerialId" filled
                                    :label="manufacturerSerialIdLabel"
                                    :disabled="!hasEditRole"
                                    :readonly="!hasEditRole"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field v-model="batteryPack.data.weightKg" filled
                                    :label="weightKgLabel"
                                    :hint= "$t('name-input.component.weightKgLabel.hint')"
                                    suffix="Kg"
                                    type="number"
                                    :disabled="!hasEditRole"
                                    :readonly="!hasEditRole"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" v-if="hasEditRole">
                                        <c-date-time-selector initialValue="batteryPack.data.manufacturingDate" @input-attempt="refresh" @selection-changed="batteryPack.data.manufacturingDate = $event.date" filled
                                    :label="manufacturingDateLabel"
                                    :optional="true"
                                    />
                                  </v-col>
                                  <v-col cols="12" v-else>
                                        <v-text-field v-model="batteryPack.data.manufacturingDate" filled
                                    :label="manufacturingDateLabel"
                                    :disabled="true"
                                    readonly="true"
                                    />
                                  </v-col>
                              </v-row>
                            </v-card-text>
                            </v-card>
                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
        </c-item-editor-form>
    </v-sheet>
</template>

<script>
import {cloneDeep, get } from 'lodash';

import BatteryPackEditorModule from "../../../store/masterdata/battery-packs/battery-pack-editor.module";
import Roles from '@/roles';

const MODULE_ID = 'BatteryPackEditor';

export default {
    name: 'batteryPack-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],



    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/saveData");
                await this.$store.dispatch(MODULE_ID + "/updateModel",this.$store.getters[MODULE_ID + '/batteryPack']);
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
                await this.refresh();
            } finally {
                this.progressIndicatorService.hide();
                await this.refresh();
            }
        },
        async reset(){
            await this.$store.dispatch(MODULE_ID + "/resetBatteryPack");
        },

cancel() {
    this.reset();
    this.$router.push({
        name: "BatteryPackListView",
    });
}
    },

    computed: {

        batteryPassportId:{
            get() {
                return this.$store.getters[MODULE_ID + '/batteryPack'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryPack", value);
            }
        },
        date:{
            get() {
                return this.$store.getters[MODULE_ID + '/batteryPack'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryPack", value);
            }
        },
        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        batteryPassportIdLabel(){
            return this.$t("name-input.component.batteryPassportIdLabel")
        },
        manufacturerSerialIdLabel(){
            return this.$t("name-input.component.manufacturerSerialIdLabel")
        },
        manufacturingDateLabel(){
            return this.$t("name-input.component.manufacturingDateLabel")
        },
        weightKgLabel(){
            return this.$t("name-input.component.weightKgLabel")
        },
        cityLabel(){
            return this.$t("name-input.component.cityLabel")
        },
        batteryModel(){
            return this.$store.getters[MODULE_ID + '/batteryModel']
        },
        provinceLabel(){
            return this.$t("name-input.component.provinceLabel")
        },


        hasEditRole() {
            return this.userContextService.hasRole(Roles.BATTERYPACKS_EDIT);
        },

        batteryPackid: {

            get() {
                return this.$store.getters[MODULE_ID + '/batteryPack'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryPack", value);
            }
        },
        batteryPack: {

        get() {
            return this.$store.getters[MODULE_ID + '/batteryPack'];
        },

        set(value) {
            this.$store.dispatch(MODULE_ID + "/updateBatteryPack", value);
        }
},

        manufacturerplant: {
            get() {
                return this.$store.getters[MODULE_ID + '/manufacturerplants'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateManufacturerplantId", value);
            }

        },
        

        
manufacturerplants() {
    return this.$store.getters[MODULE_ID + "/manufacturerplants"];
},

batterystatuss() {
    return this.$store.getters[MODULE_ID + "/batterystatuss"];
},
manufacturerplantid: {
    get() {
        return this.$store.getters[MODULE_ID + "/manufacturerplantid"];
    },
    

    set(item) {
        this.$store
            .dispatch(MODULE_ID + "/updateManufacturerplantId", item)
            .catch(this.errorHandlerService.handleError);
    },
},
batterystatusid: {
    get() {
        return this.$store.getters[MODULE_ID + "/batterystatusid"];
    },
    

    set(item) {
        console.log("set has been called, reroute to store.");
        this.$store
            .dispatch(MODULE_ID + "/updatebatterystatusId", item)
            .catch(this.errorHandlerService.handleError);
    },
},
batterystatus: {
    get() {
        return this.$store.getters[MODULE_ID + "/batterystatusid"];
    },
    

    set(item) {
        this.$store
            .dispatch(MODULE_ID + "/updatebatterystatusId", item)
            .catch(this.errorHandlerService.handleError);
    },
},
        model() {
            return {manufacturerPlant: cloneDeep(this.batteryPack)};
        }
 
    },

    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatteryPackEditorModule);
        }

        this.refresh();

    },
  destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_ID)) {
        this.$store.unregisterModule(MODULE_ID);
        this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
    }
  }
}
</script>

<i18n>
   
{
    "en" : {
        "batteryPack-editor.view.title" : "Battery Pack {name}",

        "batteryPack-editor.view.tabs.general" : "General",
        "batteryPack-editor.view.tabs.settings" : "Settings",
        "batteryPack-editor.view.tabs.json" : "JSON",
        "batteryPack-editor.view.component.title":"Settings",
        "batteryPack-editor.view.component.subtitle": "This page contains settings for the selected Battery Pack.",
        "batteryPack-editor.view.sections.manufacturerplant.title":"Manufacturer Plants",
        "batteryPack-editor.view.sections.manufacturerplant.subtitle" : "Please assign the respective Manufacturer Plant to this Battery Pack.",
        "batteryPack-editor.view.sections.batterystatus.title":"Battery Status",
        "batteryPack-editor.view.sections.batterystatus.subtitle" : "Here you assign a Battery Status to the Battery Pack. (A type of battery)",
        "batteryPack-editor.view.sections.location.title":"Battery Pack Properties",
        "batteryPack-editor.view.sections.location.subtitle" : "Here you can edit the properties of the Manufacturer Plant.",
        "batteryPack-wizard.view.subheader.manufacturerplant" : "Manufacturer Plants",
        "batteryPack-wizard.view.subheader.batterystatus" : "Battery Status",
        "name-input.component.batteryPassportIdLabel" : "Battery Passport Id",
        "name-input.component.manufacturerSerialIdLabel" : "Manufacturer Serial Id",
        "name-input.component.manufacturingDateLabel" : "Manufacturing Date",
        "name-input.component.weightKgLabel" : "Weight in kg",
        "name-input.component.validation.notEmpty" : "This field must be filled.",
        "batteryPack-editor.view.sections.batteryModel.title" : "Battery Model",
        "name-input.component.weightKgLabel.hint" : "Decimal Format: [xy.xy] e.g.: 0.23 oder 234.0903",
        "batteryPack-editor.view.sections.batteryModel.subtitle" : "Here you see the associated Battery Model. This cannot be changed after creation.",
        "name-input.component.validation.onlyValidCharacters" : "Please only use valid numbers consisting of the numbers 0 to 9, please separate decimals with dot [.] or comma [,]."
    },

    "de" : {
        "batteryPack-editor.view.title" : "Batterie {name}",
        "batteryPack-editor.view.tabs.general" : "Allgemein",
        "batteryPack-editor.view.tabs.settings" : "Einstellungen",
        "batteryPack-editor.view.tabs.json" : "JSON",
        "batteryPack-editor.view.sections.batterystatus.title":"Batterie-Status",
        "batteryPack-wizard.view.subheader.batterystatus" : "Batterie-Status",
        "batteryPack-editor.view.component.title":"Einstellungen",
        "batteryPack-editor.view.sections.batterystatus.subtitle" : "Bitte weisen Sie der Batterie einen Status aus der untenstehenden Liste zu.",
        "batteryPack-editor.view.component.subtitle": "Diese Seite beinhaltet Einstellungen für die ausgewählte Batterie.",
        "batteryPack-editor.view.sections.manufacturerplant.title":"Hersteller-Werk",
        "batteryPack-editor.view.sections.manufacturerplant.subtitle" : "Bitte weisen Sie der Batterie ein Hersteller-Werk aus der untenstehenden Liste zu.",
        "batteryPack-editor.view.sections.location.title":"Batterie-Eigenschaften",
        "batteryPack-editor.view.sections.location.subtitle" : "Hier können Sie die Eigenschaften der Batterie verwalten.",
        "batteryPack-wizard.view.subheader.manufacturerplant" : "Hersteller-Werk",
        "name-input.component.batteryPassportIdLabel" : "Batterie-Pass Id",
        "name-input.component.manufacturerSerialIdLabel" : "Seriennummer des Herstellers",
        "name-input.component.manufacturingDateLabel" : "Fertigungsdatum",
        "name-input.component.weightKgLabel" : "Gewicht in Kg",
        "name-input.component.validation.notEmpty" : "Dieses Feld kann nicht leer sein.",
        "batteryPack-editor.view.sections.batteryModel.title" : "Batterie-Modell",
        "name-input.component.weightKgLabel.hint" : "Dezimalzahlenformat: [xy,xy] Beispiel: 0,23 oder 234,0903",
        "batteryPack-editor.view.sections.batteryModel.subtitle" : "Hier sehen Sie das zugehörige Batterie-Modell. Es kann nach der Erstellung nicht mehr verändert werden.",
        "name-input.component.validation.onlyValidCharacters" : "Bitte nutzen Sie ausschließlich Zahlen die die Nummern 0 bis 9 enthalten; Bitte nutzen Sie entweder Punkt [.] oder Komma [,] zur Trennung von Dezimalzahlen."
    }
}
</i18n>