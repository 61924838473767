import api from '@/store/api';
import { isEmpty } from 'lodash';

export default {

    namespaced : true,

    state: {

        meta: {

            collectionSettings: {},

            schema: {
                attributes: []
            }
        },

        settings: {
            filter: {
                filterQuery: '*', 
                filterQueryLanguage: 'SIMPLE', 
                facetFilters: [],
                rangeFilters: []
            },

            aggregation: {
                dimensions: { row: [] },
                measures: []
            }
        },

        model: {

            items: [],
            pagination: {

            },
        }
    },
    
    mutations: {

        setCollectionSettings(state, collectionSettings) {

            if (!isEmpty(collectionSettings)) {
                state.meta.collectionSettings = collectionSettings;
            } else {
                state.meta.collectionSettings = {};
            }
        },

        setSchema(state, schema) {
            
            if (!isEmpty(schema)) {
                state.meta.schema = schema;
            } else {
                state.meta.collectionSettings = { attributes: []};
            }
        }
    },

    actions: {

        async loadMetadata(context, { collectionId }) {

            const response = await api.collections.findById(collectionId);

            context.commit('setCollectionSettings', response ? response.collectionSettings : {});
            context.commit('setSchema', response ? response.schema : {});
        }
    },

    getters: {
        
        collectionMetaData(state) {
            return state.meta;
        },

        model(state) {
            return state.model;
        },

        settings(state) {
            return state.settings;
        }
    }
}