import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {
    namespaced: true,


    state : {
        tenantId : "",
        batteryPack: createDefaultBatteryPack(),
        manufacturerplantid : null,
        manufacturerplant : {"items": []},
        batterystatus : null,
        batterystatuss : {"items": []}
    },

    mutations: {  

        setBatteryPack(state, batteryPack) {
            
            if (batteryPack) {
                state.batteryPack.tenantId = batteryPack.tenantId;
                state.batteryPack.name = batteryPack.name;
                state.batteryPack.i18n = batteryPack.i18n;
                state.batteryPack.data = batteryPack.data;
                state.batteryPack.references = batteryPack.references;
                state.batteryPack.active = batteryPack.active;
                state.batteryPack.creationDateTime     = batteryPack.creationDateTime;
                state.batteryPack.creationUser         = batteryPack.creationUser;
                state.batteryPack.modificationDateTime = batteryPack.modificationDateTime;
                state.batteryPack.modificationUser     = batteryPack.modificationUser;
            }
            
        },
        
        async updateBatteryPacks(context, batteryPack) {

            try {
                context.commit('setBatteryPack', batteryPack);
            } catch (error) {
                return Promise.reject(error);
            }
        },        
        async setManufacturerplantId(state, manufacturerId) {
            if (manufacturerId) {
                //const payload = await api.masterdata.manufacturers.findById(manufacturerId);
                state.batteryPack.references.manufacturerplantsid = manufacturerId;
                
                state.manufacturerplantid = manufacturerId;
            } else {
                state.batteryPack.references.manufacturerplantid = null;
            }
        },
        setManufacturerplants(state, manufacturerplant){ 
            if (manufacturerplant) {

            let items = [];
            if (manufacturerplant.items) {
                state.manufacturerplant.items = manufacturerplant.items;
            } else {
                state.manufacturerplant.items = items;
            }

            if (manufacturerplant.tenantId) {
                state.manufacturerplant.tenantId = manufacturerplant.tenantId;
            }

            if (manufacturerplant.pagination) {
                state.manufacturerplant.pagination = manufacturerplant.pagination;
            }

        }
        },
        async setbatterystatusId(state, batterystatusid) {

            console.log("set has been found in backend")
            if (batterystatusid) {
                console.log("status was actually there");
                //const payload = await api.masterdata.manufacturers.findById(manufacturerId);
                state.batteryPack.references.batterystatus = batterystatusid;
                state.batterystatus = batterystatusid;
                console.log(state.batteryPack)
            } else {
                state.batteryPack.references.batterystatus = null;
            }
        },
        setbatterystatuss(state, batterystatuss){ 
            if (batterystatuss) {

            let items = [];
            if (batterystatuss.items) {
                state.batterystatuss.items = batterystatuss.items;
            } else {
                state.batterystatuss.items = items;
            }

            if (batterystatuss.tenantId) {
                state.batterystatuss.tenantId = batterystatuss.tenantId;
            }

            if (batterystatuss.pagination) {
                state.batterystatuss.pagination = batterystatuss.pagination;
            }

        }
        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.batteryPacks.findById(name);
                    let manufacturerplants = await api.masterdata.manufacturerplant.findByFilter('*', 0, 100);
                    let batterystatuss = await api.masterdata.batteryStatus.findByFilter('*', 0, 100);


                    context.commit('setManufacturerplants', manufacturerplants)
                    context.commit('setbatterystatuss', batterystatuss)
                
                //get(response, 'item.manufacturer.name', createDefaultBatteryPack())
                context.commit('setBatteryPack', get(response, 'item.batteryPack', createDefaultBatteryPack()));
                context.commit('setManufacturerplantId', get(response, 'item.manufacturerPlant.name', null));
                context.commit('setbatterystatusId', get(response, 'item.batteryStatus.name', null));
            
            } catch (error) {
                context.commit('setBatteryPack', createDefaultBatteryPack());
                context.commit('setManufacturerplantId', '');
                context.commit('setManufacturerplants', []);
                throw error;
            }
        },

        async saveData(context) {

            try {
                
                const payload = context.getters.request;
                console.log(payload);
                let response = await api.masterdata.batteryPacks.update(payload);
                console.log(response)
                context.commit('setBatteryPack', get(response, 'item.batteryPack.references.batterystatus', null));

            } catch (error) {
                console.log(error);
                context.commit('setBatteryPack', createDefaultBatteryPack());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            context.commit('setBatteryPack', model);
        },

        updateBatteryPack(context, batteryPack) {
            console.log('+++ updateBatteryPack ', batteryPack);
            context.commit('setBatteryPack', batteryPack);
        },
        async updateManufacturerplantId(context, manufacturerId) {
            try {
                context.commit('setManufacturerplantId', manufacturerId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
        async updatebatterystatusId(context, manufacturerId) {
            console.log("update has been called")
            try {
                context.commit('setbatterystatusId', manufacturerId);
            } catch (error) {
                return Promise.reject(error);
            }

        },async resetBatteryPack(context) {
            await context.commit('setBatteryPack', createDefaultBatteryPack());
            await context.commit('setbatterystatus', null);
            await context.commit('setBatteryModel', null);
        },
    },

    getters: {
        
        request(state) {
            let payload = state.batteryPack;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },
        batteryPack(state) {
            return state.batteryPack ? state.batteryPack : createDefaultBatteryPack();
        },
        batteryModel(state){
            return state.batteryPack.references.batterymodel ? state.batteryPack.references.batterymodel : null;
        },
        
        model(state) {
                 return {
                    batteryPack: state.batteryPack
            }
        },
        manufacturerplants(state) {
            return state.manufacturerplant ? state.manufacturerplant : {items:[]};
        },
        batterystatuss(state) {
            return state.batterystatuss ? state.batterystatuss : {items:[]};
        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        manufacturerplantid(state) {
            return get(state, 'batteryPack.references.manufacturerplantsid',get(state,'manufacturerplantid'));
        },
        batterystatusid(state) {
            return state.batteryPack.references.batterystatus?state.batteryPack.references.batterystatus:state.batterystatus;
        },
        
    }
}

function createDefaultBatteryPack() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {
            batteryPassportId: null,
            manufacturerSerialId: null,
            manufacturingDate: null,
            weightKg:null},
        active: true,
        references: {
            manufacturerplantsid : null,
            batterystatus : null,
            batterymodel : null},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}