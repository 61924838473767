<template>
  <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form 
            :title="$t('substances-recycling-share-editor.view.title', { name: name })" 
            :value="substancesRecyclingShare"
            @update-confirmed="save"
            @update-canceled="cancel"
            :editable="hasEditRole"
            >
            
            <template v-slot:default>
                <v-sheet color="grey lighten-3">
                    
                    <v-tabs 
                        v-model="selectedTab" 
                        :vertical="$vuetify.breakpoint.mdAndUp" 
                        color="grey" 
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                        slider-color="grey" 
                        show-arrows 
                        :fixed-tabs="!$vuetify.breakpoint.mdAndUp">
                        

                        <!-- Tabs -->
                        <v-tab :key="0">{{$t('substances-recycling-share-editor.view.tabs.general')}}</v-tab>
                        <v-tab :key="1">{{$t('substances-recycling-share-editor.view.tabs.settings')}}</v-tab>
                        <v-tab :key="2">{{$t('substances-recycling-share-editor.view.tabs.json')}}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="substancesRecyclingShare" :disabled="!hasEditRole"/>
                            </v-tab-item>

                            <!-- Settings -->
                            <v-tab-item :key="1">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('substances-recycling-share-editor.view.sections.props.title') }}</div>
                                        <div>{{ $t('substances-recycling-share-editor.view.sections.props.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("substances-recycling-share-editor.view.sections.values.title") }}</div>
                                    <div>{{ $t('substances-recycling-share-editor.view.sections.values.subtitle') }}</div>
                                    <p></p>
                                    <p></p>
                                        <v-row>
                                            <v-col cols="12" lg="4">
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.values.pre.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.values.pre.subtitle') }}</div>
                                                <c-percentage-input-recycling :disabled="!hasEditRole" v-model="substancesRecyclingShare.data.preConsumerShare"      :label = "$t('substance-recycling-share-editor.view.subheader.preConsumerShare')" filled> </c-percentage-input-recycling>
                                            </v-col>
                                            <v-col cols="12" lg="4">
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.values.post.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.values.post.subtitle') }}</div>
                                                <c-percentage-input-recycling :disabled="!hasEditRole" v-model="substancesRecyclingShare.data.postConsumerShare"     :label = "$t('substance-recycling-share-editor.view.subheader.postConsumerShare')" filled> </c-percentage-input-recycling>
                                            </v-col>
                                            <v-col cols="12" lg="4">
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.values.prim.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.values.prim.subtitle') }}</div>
                                                <c-percentage-input-recycling :disabled="!hasEditRole" v-model="substancesRecyclingShare.data.primaryMaterialShare"  :label = "$t('substance-recycling-share-editor.view.subheader.primaryMaterialShare')" filled> </c-percentage-input-recycling>
                                            </v-col>
                                        </v-row>
                                       

                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("substances-recycling-share-editor.view.sections.props.title") }}</div>
                                    <div>{{ $t('substances-recycling-share-editor.view.sections.props.substances.subtitle.props') }}</div>
                                    <p></p>
                                    <p></p>
                              <v-row> 
                                  <v-col cols="12" >
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.props.models.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.props.models.subtitle') }}</div>
                                      <c-item-selector :items="batteryModels.items" v-model="substancesRecyclingShare.references.batterymodel" :return-object="false"
                                          :chips="false" filled
                                          :label="$t('substance-recycling-share-editor.view.subheader.model')"
                                          :readonly="((!hasEditRole) && (!hasReadMRole)) || (!hasReadMRole) || (!hasEditRole) || (!hasReadSRole)"
                                          :disabled="((!hasEditRole) && (!hasReadMRole)) || (!hasReadMRole) || (!hasEditRole) || (!hasReadSRole)"
                                          :rules="[v => v && !!v.trim() || $t('battery-model-editor.view.fields.schema.validation.notEmpty')]">BatteryModels</c-item-selector>
                                  </v-col>
                                  <v-col cols="12" >
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.props.substances.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.props.substances.subtitle') }}</div>
                                      <c-item-selector :items="batterySubstances.items" v-model="substancesRecyclingShare.references.batterysubstance" :return-object="false" filled
                                          :chips="false"
                                          :readonly="((!hasEditRole) && (!hasReadSRole)) || (!hasReadSRole) || (!hasEditRole) || (!hasReadMRole)"
                                          :disabled="((!hasEditRole) && (!hasReadSRole)) || (!hasReadSRole) || (!hasEditRole) || (!hasReadMRole)"
                                          :label="$t('substance-recycling-share-editor.view.subheader.substance')"
                                          :rules="[v => v && !!v.trim() || $t('battery-substance-editor.view.fields.schema.validation.notEmpty')]">BatterySubstances</c-item-selector>
                                  </v-col>
                              </v-row>
                          </v-card-text>
                      </v-card>
                            </v-tab-item>
                            

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>
                            
                            

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
             
                
                 
            </template>
        </c-item-editor-form>
  </v-sheet>
</template>


<script>
import { cloneDeep, get } from 'lodash';

import SubstancesRecyclingShareEditorModule from "../../../store/masterdata/substance-recycling-share/substance-recycling-shares-editor.module";
import DxsPercentageInputRecycling from "../../../components/masterdata/substance-recycling-shares/percentage-input-recycling.component.vue";

import Roles from '@/roles';

const MODULE_ID = 'SubstancesRecyclingShareEditor';

export default {

    name: 'substance-recycling-share-editor',

  components: {
    "c-percentage-input-recycling": DxsPercentageInputRecycling,
  },

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],



    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        nextPage(){
        this.$router.push({
          name: "SubstancesRecyclingShareEditorView",
          params: {
            name: this.substancesRecyclingShare.name,
          },
        })
        },


        async save() {
            this.progressIndicatorService.show();
            try{
                await this.$store.dispatch(MODULE_ID + "/saveData");
                } catch(error){
                    const err = Error(this.$t('substances-recycling-share-editor.view.subheader.errorm'))
                    this.errorHandlerService.handleError(err);
                    this.progressIndicatorService.hide();
                } finally {
                    this.progressIndicatorService.hide();
                }
            this.refresh();
        },

        cancel() {
            this.$router.push({
                name: "SubstancesRecyclingShareListView",
            });
        }
    },

    computed: {

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.RECYCLINGSHARE_EDIT);
        },

    hasReadSRole() {
    return this.userContextService.hasRole(Roles.BATTERYSUBSTANCE_READ);
},
    hasReadMRole() {
    return this.userContextService.hasRole(Roles.BATTERYMODEL_READ);
},

        substancesRecyclingShare: {

            get() {
                return this.$store.getters[MODULE_ID + '/substancesRecyclingShare'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateSubstancesRecyclingShare", value);
            }
        },

        model() {
            return cloneDeep(this.substancesRecyclingShare);
        },

        batterySubstances : {

            get() {
                return this.$store.getters[MODULE_ID + '/batterySubstances'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatterySubstance", value);
            }
        },

        batteryModels : {

            get() {
                return this.$store.getters[MODULE_ID + '/batteryModels'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryModel", value);
            }
        }

    },

   async created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, SubstancesRecyclingShareEditorModule);
        }
        this.refresh();

    },
  destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_ID)) {
        this.$store.unregisterModule(MODULE_ID);
        this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
    }
  }
}
</script>

<i18n>
{
    "en" : {
        "substances-recycling-share-editor.view.title":"Substance Recycling Share {name}",
        "substance-recycling-shares-editor.view.subtitle":"This editor will guide you through the required steps for creating a new Substance Recycling Share.",
        "substance-recycling-share-editor.view.subheader.model":"Battery Model",
        "substance-recycling-share-editor.view.subheader.substance":"Battery Substance",
        "battery-model-editor.view.fields.schema.validation.notEmpty":"Please assign a Battery Model.",
        "battery-substance-editor.view.fields.schema.validation.notEmpty":"Please assign a Substance.",
        "substance-recycling-share-editor.view.subheader.primaryMaterialShare":"Primary Material Share",
        "substance-recycling-share-editor.view.subheader.preConsumerShare":"Pre-Consumer-Recycling Share",
        "substance-recycling-share-editor.view.subheader.postConsumerShare":"Post-Consumer-Recycling Share",
        "substances-recycling-share-editor.view.tabs.general":"General",
        "substances-recycling-share-editor.view.tabs.settings":"Settings",
        "substances-recycling-share-editor.view.sections.values.title":"Shares",
        "substances-recycling-share-editor.view.sections.values.subtitle":"Here you can manage the respective share of this Substance Recycling Share.",
        "substances-recycling-share-editor.view.sections.values.pre.title":"Pre-Consumer-Recycling Share",
        "substances-recycling-share-editor.view.sections.values.pre.subtitle":"Please enter the Substance Recycling Share before the battery has been used.",
        "substances-recycling-share-editor.view.sections.values.post.title":"Post-Consumer-Recycling Share",
        "substances-recycling-share-editor.view.sections.values.post.subtitle":"Please enter the Substance Recycling Share after the battery has been used.",
        "substances-recycling-share-editor.view.sections.values.prim.title":"Primary Material Share",
        "substances-recycling-share-editor.view.sections.values.prim.subtitle":"Please enter the actual Substance Recycling Share value of the battery.",
        "substances-recycling-share-editor.view.sections.props.title":"Properties",
        "substances-recycling-share-editor.view.sections.props.subtitle":"This page contains settings for the selected Substance Recycling Share.",
        "substances-recycling-share-editor.view.sections.props.models.title":"Battery Model",
        "substances-recycling-share-editor.view.sections.props.models.subtitle":"Please enter the Battery Model to which the Substance Recycling Share is assigned.",
        "substances-recycling-share-editor.view.sections.props.substances.title":"Battery Substance",
        "substances-recycling-share-editor.view.sections.props.substances.subtitle":"Please enter the Battery Element to which the Substance Recycling Share is assigned.",
        "substances-recycling-share-editor.view.sections.props.substances.subtitle.props":"Please enter data such as Battery Model and Battery Element for the Substance Recycling Share.",
        "substances-recycling-share-editor.view.tabs.json":"JSON",
        "substances-recycling-share-editor.view.subheader.errorm":"This Battery Substance in combination with this Battery Model already exists."
        
    },

    "de" : {
        "substances-recycling-share-editor.view.title":"Wiederverwertbarkeitsanteil {name}",
        "substance-recycling-shares-editor.view.title":"Neuen Wiederverwertbarkeitsanteil anlegen",
        "substance-recycling-shares-editor.view.subtitle":"Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Wiederverwertbarkeitsanteils.",
        "substance-recycling-share-editor.view.subheader.model":"Batterie-Modell",
        "substance-recycling-share-editor.view.subheader.substance":"Batterie-Element",
        "battery-model-editor.view.fields.schema.validation.notEmpty":"Bitte ordnen Sie dem Wiederverwertbarkeitsanteil ein Batterie-Modell zu.",
        "battery-substance-editor.view.fields.schema.validation.notEmpty":"Bitte ordnen Sie dem Wiederverwertbarkeitsanteil ein Batterie-Element zuordnest.",
        "substance-recycling-share-editor.view.subheader.primaryMaterialShare":"Material Anteil",
        "substance-recycling-share-editor.view.subheader.preConsumerShare":"Materialanteil vor Nutzung",
        "substance-recycling-share-editor.view.subheader.postConsumerShare":"Materialanteil nach Nutzung",
        "substances-recycling-share-editor.view.tabs.general":"Allgemein",
        "substances-recycling-share-editor.view.tabs.settings":"Einstellungen",
        "substances-recycling-share-editor.view.sections.values.title":"Anteile",
        "substances-recycling-share-editor.view.sections.values.subtitle":"Hier können Sie die zu diesem Wiederverwertbarkeitsanteil gehörigen Anteile verwalten.",
        "substances-recycling-share-editor.view.sections.values.pre.title":"Materialanteil vor Nutzung",
        "substances-recycling-share-editor.view.sections.values.pre.subtitle":"Bitte erfassen Sie hier den Wiederverwertbarkeitsanteil vor Nutzung der Batterie.",
        "substances-recycling-share-editor.view.sections.values.post.title":"Materialanteil nach Nutzung",
        "substances-recycling-share-editor.view.sections.values.post.subtitle":"Bitte erfassen Sie hier den Wiederverwertbarkeitsanteil nach Nutzung der Batterie.",
        "substances-recycling-share-editor.view.sections.values.prim.title":"Material Anteil",
        "substances-recycling-share-editor.view.sections.values.prim.subtitle":"Bitte erfassen Sie hier den tatsächlichen Anteil des Batterie-Elements.",
        "substances-recycling-share-editor.view.sections.props.title":"Eigenschaften",
        "substances-recycling-share-editor.view.sections.props.subtitle":"Diese Seite enthält Einstellungen zum ausgewählten Wiederverwertbarkeitsanteil.",
        "substances-recycling-share-editor.view.sections.props.models.title":"Batterie-Model",
        "substances-recycling-share-editor.view.sections.props.models.subtitle":"Bitte erfassen Sie hier das Batterie-Modell für den Wiederverwertbarkeitsanteil.",
        "substances-recycling-share-editor.view.sections.props.substances.title":"Batterie-Element",
        "substances-recycling-share-editor.view.sections.props.substances.subtitle":"Bitte erfassen Sie hier das Batterie-Element für den Wiederverwertbarkeitsanteil.",
        "substances-recycling-share-editor.view.tabs.json":"JSON",
        "substances-recycling-share-editor.view.sections.props.substances.subtitle.props":"Bitte weisen Sie hier Daten zum Wiederverwertbarkeitsanteil wie Batterie-Modell und Batterie-Element zu.",
        "substances-recycling-share-editor.view.subheader.errorm":"Dieses Batterie-Element mit diesem Batterie-Modell existiert bereits."
    }
}
</i18n>