<template>
    <div>
        <!-- Ensure data is being rendered correctly -->
        <div>
            <c-editable-item-list 
                v-model="batteryMaterialCompositions" 
                :batteryMaterials="batteryMaterials"
                :new-item="flatEmptyItem"
                :title="$t('battery-material-composition-editor.view.title')" 
                :edit-dialog-title="({ item }) => editorHeaderText(item.name)" edit-dialog-style="window" 
                :readonly="false"
                headerStyle="lite" 
                :flat="true" 
                @item-removed="fireItemRemoved" 
                @item-updated="fireItemUpdated" 
                :disabled="disabled">

                <template v-slot:item-list-head>
                    <thead>
                        <tr>
                            <th>{{ $t('battery-material-composition-editor.view.tableHeader.edit') }}</th>
                            <th>{{ $t('battery-material-composition-editor.view.tableHeader.delete') }}</th>
                            <th>{{ $t('battery-material-composition-editor.view.tableHeader.batterymaterial') }}</th>
                            <th>{{ $t('battery-material-composition-editor.view.tableHeader.share') }}</th>
                            <!-- <th>JSON</th> -->
                        </tr>
                    </thead>
                </template>

                <template v-slot:item-list-body-row="props">
                    <td>{{ props.item.batterymaterialId }}</td>
                    <td>{{ $dxs.formatting.formatDecimal(props.item.share, $i18n.locale) }}</td>
                    <!-- <td>{{  JSON.stringify(props.item) }}</td> -->
                </template>

                <template v-slot:item-list-editor="props">
                    <v-card tile flat>
                        <v-card-text v-if="!props.isNewItem">
                            <div class="text-h5">{{ $t('battery-material-composition-editor.view.tabs.editor.settings') }}
                            </div>
                            <div>{{ $t('battery-material-composition-editor.view.tabs.editor.subtitle') }}</div>
                            <p></p>
                            <v-divider></v-divider>
                            <p></p>

                            <v-text-field filled v-model="props.item.batterymaterialId"
                                :label="$t('battery-material-composition-editor.view.label.batterymaterials.materialid')"
                                :disabled="!props.isNewItem" />
                            <div class="text-subtitle-1 font-weight-medium pb-3">
                                {{ $t("battery-material-composition-editor.view.sections.share.title") }}
                            </div>
                            <div>
                                {{ $t('battery-material-composition-editor.view.sections.share.subtitle') }}
                            </div>
                            <p></p>

                            <c-numeric-input v-model="props.item.share"
                                :label="$t('battery-material-composition-editor.view.label.share')" filled />

                        </v-card-text>

                        <v-card-text v-else>
                            <div>{{ $t('battery-material-composition-editor.view.tabs.wizard.subtitle') }}</div>
                            <p></p>
                            <v-divider></v-divider>
                            <p></p>

                            <c-item-selector v-model="props.item.batterymaterialId" :items="batteryMaterials"
                                :return-object="false" filled
                                :label="$t('battery-material-composition-editor.view.label.batterymaterials.materialid')"
                                :rules="[v => v && !!v.trim() || $t('battery-material-composition-editor.view.batterymaterials.valuecheck')]" />
                            <c-numeric-input v-model="props.item.share"
                                :label="$t('battery-material-composition-editor.view.label.share')" filled />
                        </v-card-text>
                    </v-card>

                </template>

            </c-editable-item-list>


        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
    name: 'composition',
    props: {
        items: {
            type: Array,
            required: true
        },
        batteryMaterials: {
            type: Array,
            required: true
        },
        changedIndexList: {
            type: Array,
            required: false,
            default: () => {
                return []
            }
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }




    },
    model: {
        prop: "items",
        event: "input"
    },

    data() {
        return {
            compositions: cloneDeep(this.items),
            flatEmptyItem: {
                tenantId: 'amentis',
                name: null,
                share: "0.0",
                active: true,
                batterymodelId: null,
                batterymaterialId: null,
            },


        };
    },
    computed: {
        batteryMaterialCompositions: {
            get() {

                return this.compositions;
            },
            set(value) {

                this.compositions = value;
                this.$emit('input', value);
            }
        },


    },
    methods: {
        editorHeaderText(name) {
            return name ? this.$t('battery-material-composition-editor-edit-dialog.title', { name: name }) : this.$t('battery-material-composition-editor.view.editor.title.new');
        },
        fireItemRemoved(event) {

            this.$emit('material-removed', event);
        },
        fireItemUpdated(event) {

            this.$emit('material-updated', event);
        },


    }
}
</script>

<i18n>
    {
        "en" : {
            "battery-material-composition-editor-edit-dialog.title" : "Edit Material for {name}",
            "battery-material-composition-editor.view.title" : "Material",
            "battery-material-composition-editor.view.tableHeader.edit":"Edit",
            "battery-material-composition-editor.view.tableHeader.delete":"Delete",
            "battery-material-composition-editor.view.tableHeader.batterymaterial":"Batterymaterial",
            "battery-material-composition-editor.view.tableHeader.share":"Share",
            "battery-material-composition-editor.view.editor.title.new" : "Create New Material",
            "battery-material-composition-editor.view.tabs.wizard.subtitle":"This wizard will guide you through the required steps for creating a new Material.",
            "battery-material-composition-editor.view.tabs.editor.settings" :"Settings",
            "battery-material-composition-editor.view.tabs.editor.subtitle":"This page contains settings for the selected Material.",
            "battery-material-composition-editor.view.sections.share.title":"Share",
            "battery-material-composition-editor.view.sections.share.subtitle":"The Share can be entered here.",
            "battery-material-composition-editor.view.valuecheck": "Negative and empty values are not allowed for Share. Please adjust your input."   ,
            "battery-material-composition-editor.view.batterymaterials.valuecheck" :"Please assign a Material.",
            "battery-material-composition-editor.view.label.batterymaterials.materialid" : "Battery Material",
            "battery-material-composition-editor.view.label.share":"Share"
            
        },
    
        "de" : {
            "battery-material-composition-editor-edit-dialog.title" : "Material für {name} bearbeiten",
            "battery-material-composition-editor.view.title" : "Material",
            "battery-material-composition-editor.view.tableHeader.edit":"Bearbeiten",
            "battery-material-composition-editor.view.tableHeader.delete":"Löschen",
            "battery-material-composition-editor.view.tableHeader.batterymaterial":"Batterie-Material",
            "battery-material-composition-editor.view.tableHeader.share":"Anteil",
            "battery-material-composition-editor.view.editor.title.new" : "Neues Material anlegen",
            "battery-material-composition-editor.view.tabs.wizard.subtitle":"Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Materials.",
            "battery-material-composition-editor.view.tabs.editor.settings" :"Einstellungen",
            "battery-material-composition-editor.view.tabs.editor.subtitle":"Diese Seite enthält Einstellungen zum ausgewählten Materials. ",
            "battery-material-composition-editor.view.sections.share.subtitle":"Hier kann der Anteil erfasst werden.",
            "battery-material-composition-editor.view.sections.share.title":"Anteil",
            "battery-material-composition-editor.view.valuecheck": "Negative und leere Werte sind nicht zulässig für den Anteil. Bitte passen Sie Ihre Eingabe an.",
            "battery-material-composition-editor.view.batterymaterials.valuecheck"    :"Bitte wählen sie ein Material aus.",
            "battery-material-composition-editor.view.label.batterymaterials.materialid" : "Batterie-Material",
            "battery-material-composition-editor.view.label.share": "Anteil"
            
        }
    }
    </i18n>
