import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Configuration from './utils/configuration'

Vue.use(VueI18n)

function loadLocaleMessages () {
  
  const messages = {}

  // Load core localization
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  });

  // Load customer localization - customer is identified by the TENANT_ID
  // TODO: Open

  return messages
}

export default new VueI18n({
  locale: Configuration.value('locale') || 'en',

  // Defines the fallback locale. When no fallback locale has been defined, 'en' will be assumed automatically.
  fallbackLocale: Configuration.value('fallbackLocale') || 'en',
  
  // This option will silence warnings when VueI18N finds no component-level translation and will fall back to global translations...
  // ... actually this is no error at all, isn't it?
  silentFallbackWarn: true,

  // Load messages
  messages: loadLocaleMessages()
})
