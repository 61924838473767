<template>
  <v-container fluid class="secondary pt-10 pb-10">
      <v-row justify="center">
          <v-col cols="12" lg="9">
              <c-base-dialog :value="batteryPack" :title="$t('battery-pack-wizard.view.title')"
                  :subtitle="$t('battery-pack-wizard.view.subtitle')" color="secondary"
                  @dialog-saved="save"
                  @dialog-canceled="cancel">
                  <template v-slot:default>
                      <v-card flat>
                          <v-card-text>
                              <v-row>
                                  <v-col cols="12">
                                      <c-name-input v-model="batteryPack.name" filled />
                                  </v-col>
                              </v-row>
                              <v-row> 
                                  <v-col cols="12">
                                    <c-item-selector :items="manufacturerplants.items" v-model="manufacturerplantid" :return-object="false" filled
                                          :chips="false"
                                          :label="$t('batteryPack-wizard.view.subheader.manufacturerplant')"
                                          :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"></c-item-selector>
                                    <c-item-selector :items="batterystatuss.items" v-model="batteryPack.references.batterystatus" :return-object="false" filled
                                      :chips="false"
                                      :label="$t('batteryPack-wizard.view.subheader.batterystatus')"
                                      :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"></c-item-selector>
                                    </v-col>
                                  <v-col cols="12">
                                    <c-item-selector :items="batteryModel.items" v-model="batteryPack.references.batterymodel" :return-object="false" filled
                                          :chips="false"
                                          :label="$t('batteryPack-wizard.view.subheader.batteryModel')"
                                          :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"></c-item-selector>
                                    </v-col>
                                </v-row>
                                <v-row>
                                  <!--<v-col cols="12" md="6">
                                    <v-text-field v-model="batteryPack.data.batteryPassportId" filled
                                    :label="batteryPassportIdLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>-->

                                  <v-col cols="12">
                                    <v-text-field v-model="batteryPack.data.manufacturerSerialId" filled
                                    :label="manufacturerSerialIdLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>

                                </v-row>
                                <v-row>
                                  
                              <!--<v-col cols="12">
                                    <v-text-field v-model="batteryPack.data.weightKg" filled
                                    :label="weightKgLabel"
                                    :hint= "$t('name-input.component.weightKgLabel.hint')"
                                    suffix="Kg"
                                    type="number"
                                    :rules="[
                                        v => (v && !!v.trim())  || $t('name-input.component.validation.notEmpty'),
                                        v => v && v.match(/^[0-9]*[.,]?[0-9]*$/) || $t('name-input.component.validation.onlyValidCharacters'),
                                        
                                    ]"/>
                                  </v-col>-->
                                  
                                </v-row>
                                <v-row>
                                    <!--<v-col cols="12">
                                    <c-date-selector v-model="manufacturingDate" @selection-changed="batteryPack.data.manufacturingDate = $event.date" filled
                                    :label="manufacturingDateLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>-->
                              </v-row>
                          </v-card-text>
                      </v-card>
                  </template>
              </c-base-dialog>
          </v-col>
      </v-row>
  </v-container>
</template>


<script>
import BatteryPackWizardModule from "../../../store/masterdata/battery-packs/battery-pack-wizard.module";
import {get,cloneDeep} from "lodash";
import Roles from "@/roles";
const MODULE_NAME = "BatteryPackWizard";

export default {
  name: "battery-pack-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
    "userContextService",
  ],

  methods: {
    async save() {
            this.progressIndicatorService.show();

            try {
              
                await this.$store.dispatch(MODULE_NAME + "/createBatteryPack");
                this.$router.push({
                    name: "BatteryPackEditorView",
                    params: { name: this.batteryPack.name },
                });
                this.reset();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            
        this.reset();
            this.$router.push({
                name: "BatteryPackListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetBatteryPack");
            this.setTenantId(this.tenantId);
        }
  },

  computed: {
    tenantId() { return this.envContextService.tenantId;},

name: {

    get() {
        return get(this.value, 'name');
    }
},

batteryPassportIdLabel(){
    return this.$t("name-input.component.batteryPassportIdLabel")
},
manufacturerSerialIdLabel(){
    return this.$t("name-input.component.manufacturerSerialIdLabel")
},
manufacturingDateLabel(){
    return this.$t("name-input.component.manufacturingDateLabel")
},
weightKgLabel(){
    return this.$t("name-input.component.weightKgLabel")
},
cityLabel(){
    return this.$t("name-input.component.cityLabel")
},
provinceLabel(){
    return this.$t("name-input.component.provinceLabel")
},

weightKg : {
    get() {
        let value = this.$store.getters[MODULE_NAME + '/weight'];
        if (value.endswith(".")) {
            return value+"0" ;
        }else{
            return value;
        }
    },
    set(value) {
        this.$store.dispatch(MODULE_NAME + "/updateWeight", value);
    }
},
hasEditRole() {
    return this.userContextService.hasRole(Roles.BATTERYPACKS_CREATE);
},




manufacturingDate: {

    get() {
        return this.$store.getters[MODULE_NAME + '/manufacturingDate'];
    },

    set(value) {
        this.$store.dispatch(MODULE_NAME + "/updateManufacturingDate", value);
    }
},
batteryPack: {

get() {
    return this.$store.getters[MODULE_NAME + '/batteryPack'];
},

set(value) {
    this.$store.dispatch(MODULE_NAME + "/updateBatteryPack", value);
}
},

manufacturerplant: {
    get() {
        return this.$store.getters[MODULE_NAME + '/manufacturerplant'];
    },

    set(value) {
        this.$store.dispatch(MODULE_NAME + "/updateManufacturer", value);
    }

},
manufacturerplants() {
return this.$store.getters[MODULE_NAME + "/manufacturerplants"];
},
batteryModel:{
    get(){ return this.$store.getters[MODULE_NAME + "/batteryModel"]; },
    set(value){this.$store.dispatch(MODULE_NAME + "/updateBatteryModel", value)}
},

batterystatuss() {
return this.$store.getters[MODULE_NAME + "/batterystatuss"];
},
manufacturerplantid: {
get() {
return this.$store.getters[MODULE_NAME + "/manufacturerplantid"];
},


set(item) {
this.$store
    .dispatch(MODULE_NAME + "/updateManufacturerplantId", item)
    .catch(this.errorHandlerService.handleError);
},
},
batterystatus: {
get() {
return this.$store.getters[MODULE_NAME + "/batterystatus"];
},


set(item) {
this.$store
    .dispatch(MODULE_NAME + "/updatebatterystatus", item)
    .catch(this.errorHandlerService.handleError);
},
},
model() {
    return {batteryPack: cloneDeep(this.batteryPack)};
}

},

async beforemount(){
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, BatteryPackWizardModule);//todo
  }
    console.log("unmounting")
    this.$store.commit(MODULE_NAME + "/resetBatteryPack");//todo
    this.reset();
  },

async created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, BatteryPackWizardModule);//todo
  }
    this.$store.commit(MODULE_NAME + "/resetBatteryPack");//todo
    this.reset();
  await this.$store.dispatch(MODULE_NAME + "/loadData");//todo
},

destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>

<i18n>
{
    "en" : {
        "battery-pack-wizard.view.title" : "Create New Battery Pack",
        "battery-pack-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Battery Pack.",
        

        "batteryPack-editor.view.tabs.general" : "General",
        "batteryPack-editor.view.tabs.settings" : "Settings",
        "batteryPack-editor.view.tabs.json" : "JSON",
        "batteryPack-editor.view.component.title":"Settings",
        "batteryPack-editor.view.component.subtitle": "This page contains settings for the selected Manufacturer.",
        "batteryPack-editor.view.sections.manufacturerplant.title":"Manufacturer Plants",
        "batteryPack-editor.view.sections.manufacturerplant.subtitle" : "Please assign the respective Manufacturer Plant to this Battery Pack.",
        "batteryPack-editor.view.sections.batterystatus.title":"Battery Status",
        "batteryPack-editor.view.sections.batterystatus.subtitle" : "Please assign a Battery Status to the Manufacturer Plant. (A type of battery)",
        "batteryPack-wizard.view.subheader.manufacturerplant" : "Manufacturer Plants",
        "batteryPack-wizard.view.subheader.batterystatus" : "Battery Status",
        "name-input.component.batteryPassportIdLabel" : "Battery Passport Id",
        "name-input.component.manufacturerSerialIdLabel" : "Manufacturer Serial Id",
        "name-input.component.manufacturingDateLabel" : "Manufacturing Date",
        "batteryPack-wizard.view.subheader.batteryModel" : "Battery Model",
        "name-input.component.weightKgLabel" : "Weight in kg",
        "name-input.component.weightKgLabel.hint" : "Decimal Format: [xy.xy] Example: 0.23 or 234.0903; This Input cannot end on a decimal seperator.",
        "name-input.component.validation.notEmpty" : "This field must be filled.",
        "name-input.component.validation.wrongEnding" : "This field cannot and on a decimal seperator.",
        "name-input.component.validation.onlyValidCharacters" : "Please only use valid numbers consisting of the numbers 0 to 9, please separate decimals with dot [.] or comma [,].",
        "date.unchanged" : "The Date has not been changed."
    },

    "de" : {
        "battery-pack-wizard.view.title" : "Neues Batterie anlegen",
        "battery-pack-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage einer neuen Batterie.",
        "name-input.component.validation.wrongEnding" : "Die Eingabe kann nicht auf ein Dezimalzahlentrennzeichen enden.",
        "batteryPack-editor.view.title" : "Hersteller-Werk {name}",
        "batteryPack-editor.view.tabs.general" : "Allgemein",
        "batteryPack-editor.view.tabs.settings" : "Einstellungen",
        "batteryPack-editor.view.tabs.json" : "JSON",
        "batteryPack-editor.view.sections.batterystatus.title":"Battery-Status",
        "batteryPack-editor.view.component.title":"Einstellungen",
        "batteryPack-editor.view.component.subtitle": "Diese Seite beinhaltet Einstellungen für den ausgewählten Hersteller.",
        "batteryPack-editor.view.sections.manufacturerplant.title":"Hersteller-Werk",
        "batteryPack-editor.view.sections.manufacturerplant.subtitle" : "Bitte weisen Sie der Batterie das zugehörige Hersteller-Werk zu.",
        "batteryPack-editor.view.sections.location.title":"Standort-Daten",
        "batteryPack-editor.view.sections.location.subtitle" : "Bitte weisen Sie dem Hersteller-Werk einen Standort zu.",
        "batteryPack-wizard.view.subheader.manufacturerplant" : "Hersteller-Werk",
        "name-input.component.batteryPassportIdLabel" : "Batterie-Pass Id",
        "name-input.component.manufacturerSerialIdLabel" : "Seriennummer des Herstellers",
        "name-input.component.manufacturingDateLabel" : "Fertigungsdatum",
        "name-input.component.weightKgLabel" : "Gewicht in Kg",
        "name-input.component.validation.notEmpty" : "Dieses Feld kann nicht leer sein.",
        "name-input.component.validation.onlyValidCharacters" : "Bitte nutzen Sie ausschließlich Zahlen die die Nummern 0 bis 9 enthalten; Bitte nutzen Sie entweder Punkt [.] oder Komma [,] zur Trennung von Dezimalzahlen. Dieses Feld kann nicht auf einem Dezimaltrennzeichen enden.",
        "batteryPack-wizard.view.subheader.batterystatus" : "Batterie-Status",
        "name-input.component.weightKgLabel.hint" : "Dezimalzahlenformat: [xy,xy] Beispiel: 0,23 oder 234,0903",
        "batteryPack-wizard.view.subheader.batteryModel" : "Batterie-Modell",
        "date.unchanged" : "Das Datum wurde nicht verändert."
    }
}
</i18n>

<style scoped>
.red-text {
  color: rgba(179, 167, 167, 0.783);
}

.small-margin-top {
  margin-top: 0px;
}
</style>