
import { get, isEmpty } from 'lodash';
import CollectionDocumentsDataModule from './collection-documents-data.module';

export function newInstance({moduleId}) {

    return {

        inject: ['progressIndicatorService', 'errorHandlerService'],

        computed: {

            documentListModel() {
                let model = this.$store.getters[moduleId + '/documentListModel'];
                return model;
            },

            items() {
                return get(this.documentListModel, 'items' ,[]);
            },
    
            itemsEmpty() {
                return isEmpty(this.items);
            },

            pagination() {
                return this.documentListModel.pagination;
            },
    
            totalItemCount() {
                return get(this.documentListModel, 'pagination.totalItemCount', 0);
            },
    
            totalItemCountFormatted() {
                return this.$dxs.formatting.formatInteger(this.totalItemCount, this.$i18n.locale);
            },
        },

        methods: {
            
            /**
             * Loads the initial page of data from the server.
             */
            async loadDocuments(collectionId, listTemplate) {

                this.$log.debug(`>>> [${collectionId}] : Loading documents for list template: ${JSON.stringify(listTemplate)} `)
                
                this.progressIndicatorService.show();

                try {
                    await this.$store.dispatch(moduleId + '/loadData', {collectionId, listTemplate});
                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                    this.submitted = false;
                }
            },

            /**
             * Loads the next page of data from the server.
             */
            async loadMoreDocuments(collectionId, listTemplate) {

                this.$log.debug(`>>> [${collectionId}] : Loading more documents for list template: ${JSON.stringify(listTemplate)} `)
                
                this.progressIndicatorService.show();

                try {
                    await this.$store.dispatch(moduleId + '/loadMoreData', {collectionId, listTemplate});
                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                    this.submitted = false;
                }
            },

            resetDocuments() {
                this.progressIndicatorService.show();

                try {
                     this.$store.dispatch(moduleId + '/resetData');
                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                    this.submitted = false;
                }
            },

            async exportDocuments(collectionId, listTemplate, format, charset) {

                this.progressIndicatorService.show();
    
                try {
                    await this.$store.dispatch(moduleId + '/exportData', {
                        collectionId: collectionId, 
                        listTemplate: listTemplate,
                        format: format,
                        charset: charset
                    });
                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                }
            },

            async deleteDocumentById(collectionId, documentId) {

                this.progressIndicatorService.show();
                let result = false;
                try {
                    result = await this.$store.dispatch(moduleId + '/deleteDocumentById', {
                        collectionId : collectionId,
                        documentId: documentId,
                    });
                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                }

                return result;
            }
        },

        // ==================================================================
        // HOOKS
        // ==================================================================
        created() {
            
            // Dynamically register the vuex store module for managing the data.
            if (!this.$store.hasModule(moduleId)){
                this.$store.registerModule(moduleId, CollectionDocumentsDataModule);
            }
        }
    };

    
}