<template>
    <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form :title="$t('safety-instruction-editor.view.title', { name: name })" :value="safetyInstruction"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole">

            <template v-slot:default>
                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('safety-instruction-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('safety-instruction-editor.view.tabs.settings') }}</v-tab>
                        <v-tab :key="2">{{ $t('safety-instruction-editor.view.tabs.json') }}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="safetyInstruction" :disabled="!hasEditRole" />
                            </v-tab-item>



                            <!-- Settings -->
                            <v-tab-item :key="1">
                                <br/>
                                <v-card flat>
                                    <v-card-text>
                                <div class="text-h5">{{ $t('safety-instruction-editor.view.tabs.settings') }} </div>
                                <div>{{ $t('safety-instruction-editor.view.tabs.settings.subtitle') }}</div>
                                <br/>
                                <div class="text-subtitle-1 font-weight-medium pb-3">      {{$t('safety-instruction-editor.view.tabs.severity')}}</div>      
                                <v-row>
                                    <v-col cols="12">
                                        <c-item-selector :items="severities" v-model="safetyInstruction.references.severity"
                                        :disabled="!hasEditRole"
                                        :label="$t('safety-instruction-editor.view.tabs.severity')" :return-object="false" filled
                                        :chips="false"
                                        dism
                                        :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                    </v-col>
                                </v-row>
                                </v-card-text>
                                <v-card-text>
                                <div class="text-subtitle-1 font-weight-medium pb-3">{{ $t('safety-instruction-editor.view.tabs.details') }}</div>
                                <div style="overflow-y: hidden">   
                                <dxs-localized-instruction-list
                                :title="$t('safety-instruction-editor.view.tabs.details')"
                                @item-deleted="onItemDeleted"
                                @item-edited="onItemEdited"
                                @item-added="onItemAdded"
                                @save="onSave"
                                @load-more = "onShowMoreDetails"
                                :hasEditRole = "hasEditRole"
                                :items = "details.items"
                                :nameColumn="$t('safety-instruction-editor.view.tabs.settings.summary')"
                                :hideDeleteAction = "true"
                                :hideProgress = "true"
                                :pagination = "pagination"
                                :pageSize=2
                                >
                                </dxs-localized-instruction-list>
                                </div>
                                    
                                <!--
                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('safety-instruction-editor.view.tabs.settings') }}
                                        </div>
                                        <div>{{ $t('safety-instruction-editor.view.tabs.settings.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" lg="6">
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("safety-instruction-editor.view.sections.settings") }}
                                                </div>
                                                <div>
                                                    {{ $t('safety-instruction-editor.view.sections.settings.subtitle') }}
                                                </div>
                                                <p></p>
                                                <v-text-field :disabled="!hasEditRole" v-model="casIdentifier"
                                                    :label="$t('safety-instruction-editor.view.tabs.settings.text')"
                                                    filled />
                                            </v-col>
                                            <v-col cols="12" lg="6">
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("safetyInstruction-editor.view.sections.hazard.title") }}</div>
                                                <div>{{ $t('safetyInstruction-editor.view.sections.hazard.subtitle') }}</div>
                                                <p></p>
                                                <c-item-selector :disabled="!hasEditRole" :items="hazards.items"
                                                    v-model="safetyInstruction.references.hazardoussubstanceclassification"
                                                    :return-object="false" filled :chips="false"
                                                    :label="$t('safetyInstruction-wizard.view.subheader.hazard')"></c-item-selector>
                                            </v-col>

                                        </v-row>
                                        <v-card-text>
                                            <div class="text-subtitle-1 font-weight-medium pb-3">
                                                {{ $t('safety-instruction-editor.view.sections.color.title') }}</div>
                                            <div>{{ $t('safety-instruction-editor.view.sections.color.subtitle') }}
                                            </div>
                                            <c-color-single-selector :items="defaultColors" v-model="color" flat
                                                color="white" />
                                        </v-card-text>

                                    </v-card-text>


                                </v-card>
                            --></v-card-text>
                            </v-card>
                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="safetyInstruction" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>



            </template>
        </c-item-editor-form>
    </v-sheet>
</template>
  
<script>

import { get } from 'lodash';
import SafetyInstructionEditorModule from "../../../store/masterdata/safety-instructions/safety-instruction-editor.module";
import DxsLocalizedIstruction from "../../../components/masterdata/safety-instructions/localized-instruction-list.component.vue";
import Roles from '@/roles';
import ColorItems from "../../../constants/colors.constants";

const MODULE_ID = 'safetyInstructionEditor';

export default {

    name: 'safety-instruction-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],
    components: {'dxs-localized-instruction-list' : DxsLocalizedIstruction},

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async onItemDeleted(value){
            await this.$store.dispatch(MODULE_ID + "/deleteLocale",value);
        },
        async onSave(item){
            await this.$store.dispatch(MODULE_ID + "/addLocale",item);
        },
        async refresh() {
            this.progressIndicatorService.show();
            try {
                this.$log.debug(`Loading information for battery material ${this.name}`);
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name })
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/saveData");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({
                name: "SafetyInstructionListView",
                params: { name: this.safetyInstruction.name },
            });
        },
        onItemEdited(value){
            console.log(value);
        },
        onItemAdded(value){
            console.log(value);
        },
        onShowMoreDetails(value){
            console.log(value);
        },
        localize(value, locale, localeObject) {	
            const lookup = locale + '.' + value;
            return get(localeObject, lookup, '');
        }
    },

    computed: {
        safetyInstruction: {
            get() {
                return this.$store.getters[MODULE_ID + '/safetyInstruction'];
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateSafetyInstruction", value);
            }
        },
        severities() {
                return this.$store.getters[MODULE_ID + '/severities'];
            },
        title(){
            return 'testTitle'
            //return this.$store.getters[MODULE_ID + '/title']
        },
        subtitle(){
            return 'testTitle'
            //return this.$store.getters[MODULE_ID + '/subtitle']
        },
        pagination(){
            return {from:0, pageSize:2,totalItemCount:3}
            //return this.$store.getters[MODULE_ID + '/pagination']
        },
        details(){
                return {items: this.$store.getters[MODULE_ID + '/details']};
            
        },

        name: {
            get() {
                return get(this.value, 'name');
            }
        },

        localeSummary: {
            get() {
                const summary = this.$store.getters[MODULE_ID + '/localeSummary'];
                return this.localize("summary",summary.locale,summary.i18n);
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateLocaleSummary", value);
            }
        },
        localeDescription: {
            get() {
                const description = this.$store.getters[MODULE_ID + '/localeSummary'];
                return this.localize("description",description.locale,description.i18n);
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateLocaleDescription", value);
            }
        },
        defaultColors() {
            return ColorItems;
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.SECURITYINSTRUCTIONS_EDIT);
        },
/** 
        safetyInstruction: {

            get() {
                return this.$store.getters[MODULE_ID + '/safetyInstruction'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateSafetyInstruction", value);
            }
        },
*/
        casIdentifier: {
            get() {
                return this.$store.getters[MODULE_ID + '/casIdentifier'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateCasIdentifier", value);
            }
        },


    },

    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, SafetyInstructionEditorModule);
        }

        this.refresh();
    },
    destroyed() {
        // Unregister the vuex store if it has been registered before.
        if (this.$store.hasModule(MODULE_ID)) {
            this.$store.unregisterModule(MODULE_ID);
            this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
        }
    }
}
</script>

<i18n>
  {
      "en" : {
        "safety-instruction-editor.view.sections.settings" : "CAS Number",
        "safety-instruction-editor.view.sections.settings.subtitle" : "The CAS Number of the Battery Material can be entered here.",
          "safety-instruction-editor.view.title" : "Security Instruction {name}",
          "safety-instruction-editor.view.tabs.settings" : "Settings",
          "safety-instruction-editor.view.tabs.settings.text" : "Details",
          "safety-instruction-editor.view.tabs.settings.subtitle" : "This page contains settings for the selected safety instruction. Define the internationalization and detail description here.",
          "safety-instruction-editor.view.tabs.general" : "General",
          "safety-instruction-editor.view.tabs.settings" : "Settings",
          "safety-instruction-editor.view.tabs.json" : "JSON",
          "safetyInstruction-editor.view.sections.hazard.title":"Hazardous Substance Classification",
          "safetyInstruction-editor.view.sections.hazard.subtitle":"The Hazardous Substance Classification of the Battery Material can be entered here.",
          "safetyInstruction-wizard.view.subheader.hazard":"Hazardous Substance Classification",
          "safety-instruction-editor.view.sections.color.title" : "Color",
          "safety-instruction-editor.view.sections.color.subtitle":"Please assign the Battery Material a color with which it can be displayed in the user interface.",
          "safety-instruction-editor.view.tabs.settings.summary":"Summary",
          "safety-instruction-editor.view.tabs.settings.description":"Description",
          "safety-instruction-editor.view.tabs.links" : "Linked Models",
          "safety-instruction-editor.view.tabs.details":"Details",
          "safety-instruction-editor.view.tabs.severity" : "Severity",
          "name-input.component.validation.notEmpty": "This field cannot be empty."
      },
  
      "de" : {
        "safety-instruction-editor.view.sections.settings" : "CAS-Nummer",
        "safety-instruction-editor.view.sections.settings.subtitle" : "Hier kann die CAS-Nummer des Batterie-Materials erfasst werden.",
          "safety-instruction-editor.view.title" : "Sicherheitsanweisung {name}",
          "safety-instruction-editor.view.tabs.settings" : "Einstellungen",
          "safety-instruction-editor.view.tabs.settings.text" : "Details",
          "safety-instruction-editor.view.tabs.settings.subtitle" : "Diese Seite enthält Einstellungen zum für die Sicherheitsanweisung. Legen Sie hier die Internationalisierung und Detailbeschreibung fest.",
          "safety-instruction-editor.view.tabs.general" : "Allgemein",
          "safety-instruction-editor.view.tabs.settings" : "Einstellungen",
          "safety-instruction-editor.view.tabs.json" : "JSON",
          "safetyInstruction-editor.view.sections.hazard.title":"Gefahrenstoffklasse",
          "safetyInstruction-editor.view.sections.hazard.subtitle":"Hier kann die Gefahrenstoffklasse des Batterie-Materials erfasst werden.",
          "safetyInstruction-wizard.view.subheader.hazard":"Gefahrenstoffklasse",
          "safety-instruction-editor.view.sections.color.title" : "Farbe",
          "safety-instruction-editor.view.sections.color.subtitle":"Bitte weisen Sie dem Batterie-Material eine Farbe zu, damit er in der Benutzeroberfläche dargestellt werden kann.",
          "safety-instruction-editor.view.tabs.settings.summary":"Zusammenfassung",
          "safety-instruction-editor.view.tabs.settings.description":"Beschreibung",
          "safety-instruction-editor.view.tabs.links" : "Verknüpfte Modelle",
          "safety-instruction-editor.view.tabs.details":"Details",
            "safety-instruction-editor.view.tabs.severity" : "Schweregrad",
          "name-input.component.validation.notEmpty": "Dieses Feld darf nicht leer sein."
      }
  }
  </i18n>