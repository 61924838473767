<template>
    <v-sheet>
        <dxs-category-section :title="$t('battery-general-info.page.sections.general.title')">
            <v-container fluid class="grey lighten-3">
                <v-row>
                    <v-col lg="4" cols="12">
                        <v-card tile height="100%">
                            <v-card-text>
                                <div class="text-h6 grey--text">Identifikation</div>
                            </v-card-text>
                            <v-subheader>Modell-Nummer</v-subheader>
                            <v-card-text>M-41698615</v-card-text>


                            <v-subheader>Serien-Nummer</v-subheader>
                            <v-card-text>{{batteryPack.data.manufacturerSerialId}}</v-card-text>

                        </v-card>
                    </v-col>
                    <v-col lg="8" cols="12">
                        <v-container fluid class="pa-0">
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6 grey--text">{{ $t('battery-general-info.page.sections.general.tiles.status.title')}}</div>
                                        </v-card-text>
                                        <v-card-text class="text-h4 text-right">
                                            {{  $dxs.i18n.localizedName(batteryStatus, $i18n.locale) }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6 grey--text">{{ $t('battery-general-info.page.sections.general.tiles.category.title')}}</div>
                                        </v-card-text>
                                        <v-card-text class="text-h4 text-right">
                                            {{  $dxs.i18n.localizedName(batteryCategory, $i18n.locale) }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6 grey--text">{{ $t('battery-general-info.page.sections.general.tiles.weight.title')}}</div>
                                        </v-card-text>
                                        <v-card-text class="text-h4 text-right">
                                            {{ $dxs.formatting.formatDecimal(batteryPack.data.weightKg, $i18n.locale) }} kg
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6 grey--text">{{ $t('battery-general-info.page.sections.general.tiles.manufacturer.title')}}</div>
                                        </v-card-text>
                                        <v-card-text class="text-h4 text-right">
                                            {{  $dxs.i18n.localizedName(manufacturer, $i18n.locale) }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6 grey--text">{{ $t('battery-general-info.page.sections.general.tiles.manufacturingDate.title')}}</div>
                                        </v-card-text>
                                        <v-card-text class="text-h4 text-right">
                                            {{  $dxs.formatting.formatDate(batteryPack.data.manufacturingDate, $i18n.locale) }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6 grey--text">{{ $t('battery-general-info.page.sections.general.tiles.plant.title')}}</div>
                                        </v-card-text>
                                        <v-card-text class="text-h4 text-right">
                                            {{  $dxs.i18n.localizedName(manufacturerPlant, $i18n.locale) }}
                                        </v-card-text>
                                        <v-divider />
                                        <v-card-text class="text-right">
                                            <div>{{  manufacturerPlant.data.street }}</div>
                                            <div>{{  manufacturerPlant.data.zipCode }} {{  manufacturerPlant.data.city }}</div>
                                            <div>{{  manufacturerPlant.data.province }} {{  manufacturerPlant.data.country }}</div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>

                        
                    </v-col>
                </v-row>
            </v-container>
        </dxs-category-section>
    </v-sheet>
</template>

<script>
import { get } from 'lodash';
import CategorySectionComponent from '@/solutions/battery-data-hub/components/common/category-section.component.vue';

export default {

    name: 'battery-general-info-page',

    components: {
        'dxs-category-section' : CategorySectionComponent,
    },

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    computed: {

        batteryPack() {
            return get(this.value, 'batteryPack', { data: [] })
        },

        batteryModel() {
            return get(this.value, 'batteryModel', { data: [] })
        },

        batteryStatus() {
            return get(this.value, 'batteryStatus', { data: [] })
        },

        batteryCategory() {
            return get(this.value, 'batteryCategory', { data: [] })
        },

        manufacturerPlant() {
            return get(this.value, 'manufacturerPlant', { data: [] })
        },

        manufacturer() {
            return get(this.value, 'manufacturer', { data: [] })
        },
    }
}
</script>

<i18n>
{
    "en" : {
        "battery-general-info.page.sections.general.title" : "General Information",

        "battery-general-info.page.sections.general.tiles.manufacturer.title" : "Manufacturer",
        "battery-general-info.page.sections.general.tiles.plant.title" : "Plant",
        "battery-general-info.page.sections.general.tiles.manufacturingDate.title" : "Manufacturing Date",
        "battery-general-info.page.sections.general.tiles.status.title" : "Status",
        "battery-general-info.page.sections.general.tiles.category.title" : "Category",
        "battery-general-info.page.sections.general.tiles.weight.title" : "Weight "
    },

    "de" : {
        "battery-general-info.page.sections.general.title" : "Allgemeine Informationen",

        
        "battery-general-info.page.sections.general.tiles.manufacturer.title" : "Hersteller",
        "battery-general-info.page.sections.general.tiles.plant.title" : "Werk",
        "battery-general-info.page.sections.general.tiles.manufacturingDate.title" : "Herstellungsdatum",
        "battery-general-info.page.sections.general.tiles.status.title" : "Status",
        "battery-general-info.page.sections.general.tiles.category.title" : "Kategorie",
        "battery-general-info.page.sections.general.tiles.weight.title" : "Gewicht"
    }
}
</i18n>