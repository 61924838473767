<template>
    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9" v-if="EmptyAssignmentCall">
              <v-card tile>
                <v-container fluid class="grey lighten-5">
                  <v-row justify="center">
                    <v-col cols="10">
                      <div class="text-center">
                        {{ $t('assignment-wizard.view.component.selection.emptyInput', $i18n.locale) }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                    <div class="text-center">
                    <v-icon color="accent" large>assignment_turned_in</v-icon></div>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                  <v-card-actions >
                      <v-btn color="accent" @click="cancel">{{ $t('assignment-wizard.view.component.button.cancel', $i18n.locale) }}</v-btn> 
                  </v-card-actions>
                </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="12" lg="9" v-else>
                <c-base-dialog :value="assignment" :title="$t('battery-model-wizard.view.title')"
                    :subtitle="$t('battery-model-wizard.view.subtitle')" color="secondary" @dialog-saved="save"
                    @dialog-canceled="cancel">
                    <template>
                        <v-card flat>
                            <v-card-text>
                                <c-item-checkbox-multi-selector v-model="assignment" :items="safetyInstructions"
                                    :rules="[v => v && !!v.trim() || $t('battery-model-wizard.view.category.valuecheck')
                                     ,v => v && v.length > 0 || $t('battery-model-wizard.view.category.valuecheck')]"
                                    
                                    :label="$t('battery-model-wizard.view.category')" filled></c-item-checkbox-multi-selector>
                                  </v-card-text>
                                </v-card>
                              </template>
                </c-base-dialog>
            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
import { isEmpty } from "lodash";
import AssignmentWizardModule from "../../../store/masterdata/battery-models/assignemnt-wizard.module.js";
import Roles from "@/roles";
const MODULE_NAME = "AssignmentWizard";

export default {
  name: "assignment-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
    "userContextService",
  ],

  props: {

value: {
    type: Object,
    required: true,
}
},
  methods: {
    async cancel(){
        this.$router.push({
                    name: "BatteryModelEditorView",
                    params: { name: this.value.name },
                    query: { tabIndex: 4 }
                });
        },
    async save() {
      const itemsSelected = this.$store.getters[MODULE_NAME + '/novel'];
      // Check if the user has selected any items. If not the save action will work as cancel.
      if(isEmpty(itemsSelected)){
        this.cancel();
        return
      }
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_NAME + "/createAssignment");
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
        this.$router.push({
                    name: "BatteryModelEditorView",
                    params: { name: this.value.name },
                    query: { tabIndex: 4 }
                });
      }
    },
    hasEditRole() {
      return this.userContextService.hasRole(Roles.BATTERYMODEL_EDIT);
    },
    hasReadRole() {
      return this.userContextService.hasRole(Roles.SECURITYINSTRUCTIONS_READ);
    },
    async reset(name){
      console.log("*******************RESET******************")
      console.log(name);
      console.log("*************************************")
        await this.$store.dispatch(MODULE_NAME + "/reset");
        await this.$store.dispatch(MODULE_NAME + "/loadData",name);
    }
  },
computed: {
  
  EmptyAssignmentCall(){
    if(this.loaded){
      return isEmpty(this.safetyInstructions);
    }else{
      return false;
    }},
    loaded(){
        return this.$store.getters[MODULE_NAME + '/loaded'];
    },
    safetyInstructions(){
            return this.$store.getters[MODULE_NAME + '/possibleAssignments'];
        },
    assignment:{
        get(){
            return this.$store.getters[MODULE_NAME + '/assignment'];
        },
        set(value){
            this.$store.dispatch(MODULE_NAME + '/setAssignment', value);
        }
    },
},
async beforemount(){
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, AssignmentWizardModule);//todo
  }
  },

async created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      await this.$store.registerModule(MODULE_NAME, AssignmentWizardModule);//todo
  }
  const name = this.value.name;
  this.reset(name);
    console.log("*******************CREATED******************")
    console.log(name);
    console.log("*************************************")
  await this.$store.dispatch(MODULE_NAME + "/loadData",name);//todo
},

destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>
  
<i18n>
  {
      "en" : {
          "battery-model-wizard.view.title" : "Assign Safety Instructions",
          "battery-model-wizard.view.subtitle" : "This wizard will guide you through the required steps for assigning a Safety Instruction.",
          "battery-model-wizard.view.category":"Safety Instructions",
          "battery-model-wizard.view.category.valuecheck":"Please assign a Safetey Instruction.",
          "assignment-wizard.view.component.selection.emptyInput":"You have already assigned all possible Safety Instructions, please return to the Battery Model Editor.",
          "assignment-wizard.component.empty.title":"No further Safety Instructions available.",
          "assignment-wizard.view.component.button.cancel": "Okay, return to Battery Model Editor"
      },
   
      "de" : {
        "battery-model-wizard.view.title" : "Sicherheitsanweisungen zuweisen",
        "battery-model-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die erforderlichen Schritte zum Zuweisen einer Sicherheitsanweisung.",
        "battery-model-wizard.view.category":"Sicherheitsanweisungen",
        "battery-model-wizard.view.category.valuecheck":"Bitte weisen Sie eine Sicherheitsanweisung zu.",
        "assignment-wizard.view.component.selection.emptyInput":"Sie haben bereits alle möglichen Sicherheitsanweisungen zugewiesen, bitte kehren Sie zum Battery-Modell Editor zurück.",
        "assignment-wizard.component.empty.title":"Keine weiteren Sicherheitsanweisungen verfügbar.",
        "assignment-wizard.view.component.button.cancel": "Okay, zurück zum Battery-Modell Editor"
      }
  }
  </i18n>