
import Configuration from '@/utils/configuration';

import { axios } from '@/store/axios';

const BACKEND_HOST = Configuration.value('BACKEND_HOST');
const BACKEND_PORT = Configuration.value('BACKEND_PORT');
const TENANT_ID = Configuration.value('TENANT_ID');
const API_VERSION = 'v1';
const COLLECTIONS = "collections";
const BACKENDROOT = BACKEND_HOST + (BACKEND_PORT ? (':' + BACKEND_PORT) : '');
const BACKEND = BACKENDROOT + '/' + API_VERSION + '/' + TENANT_ID;
const PREDICTION_BACKEND = BACKEND + '/prediction/scenarios'

const DOCUMENT_GW_HOST = Configuration.value('DOCUMENT_GW_HOST'); // "http://localhost:8084";
const DOCUMENT_GW_PORT = Configuration.value('DOCUMENT_GW_PORT');

const DOCUMENT_GW_URL = DOCUMENT_GW_HOST + (DOCUMENT_GW_PORT ? (':' + DOCUMENT_GW_PORT) : '') + '/' + API_VERSION + '/' + TENANT_ID;




/*
const DATA_SERVICE_URL = BACKEND + '/collections';
const PLANNING_AREA_SERVICE_URL = BACKEND  + '/planningAreas';
const SCHEMA_SERVICE_URL = BACKEND;
*/

const TIMEOUT = 6000;
const TIMEOUT_LONG_RUNNING = TIMEOUT * 10;
const TIMEOUT_VERY_LONG_RUNNING = TIMEOUT_LONG_RUNNING * 10; 

export default {

    collections: {

        findAllByFilter: (filter) => {

            let url = BACKEND + '/collections';

            return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        findById(collectionId) {
            let url = BACKEND + '/collections/' + collectionId;

            return axios.get(url, { timeout: TIMEOUT })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        correlation: {

            getCorrelation(collectionId, request) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/' + COLLECTIONS + '/' + collectionId + '/correlation';

                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        //console.log("Hello World" + JSON.stringify(error));
                        return Promise.reject(error);
                    });
            },
        },
        metrics: {
            getMetrics(collectionId, request){
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/collections/' + collectionId + '/metrics';
                return axios.post(url, request, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }
        },

        // New Time Series
        timeSeries2: {

            get(collectionId, request) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/collections/' + collectionId + '/timeseries2';
                return axios.post(url, request, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }
        },

        // Legacy
        timeSeries: {
            getTimeSeries(collectionId, request) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/collections/' + collectionId + '/timeseries';
                return axios.post(url, { timeSeries: request }, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            exportTimeSeries(collectionId, request) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/collections/' + collectionId + '/export/timeseries';

                return axios.post(url, { timeSeries: request }, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
        },

        series: {

            getSeries(collectionId, request) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/' + collectionId + '/series';
                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        //console.log("Hello World" + JSON.stringify(error));
                        return Promise.reject(error);
                    });
            },

            exportSeries(collectionId, request) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/collections/' + collectionId + '/export/series';

                return axios.post(url, request, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
        },

        stats: {

            getCollectionStatistics(collectionId, payload) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING * 3
                };

                const url = BACKEND + '/collections/' + collectionId + '/stats';

                return axios.post(url, payload, requestConfig)
                    .then(response => {
                        return Promise.resolve(response.data);
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }
        },

        distribution: {
            getDistribution(collectionId, payload) {
                const requestConfig = { timeout: TIMEOUT_LONG_RUNNING * 3 };
                const url = `${BACKEND}/collections/${collectionId}/distribution`;

                return axios.post(url, payload, requestConfig).then(response => {
                    return Promise.resolve(response.data);
                }).catch(error => { return Promise.reject(error) })
            },

            export(collectionId, request) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/collections/' + collectionId + '/export/distribution';

                return axios.post(url, request, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            getCollectionHistogramDistribution(collectionId, filter, histogramSize, dateTimeDimension, timeInterval) {
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };
                let url = BACKEND + '/collections/' + collectionId + '/distribution/date-histogram';
                let payload = {histogramSize, dateTimeDimension, timeInterval, filter };
                return axios.post(url, payload, requestConfig)
                            .then(response => {
                                return response.data;
                            })
                            .catch(error => {
                                return Promise.reject(error);
                            });
            },
        },

        dimensions : {

            getDistribution(collectionId, attributeName, filter, size) {
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };
                let url = BACKEND + '/collections/' + collectionId + '/dimensions/' + attributeName + '/distribution';
                let payload = { size, filter };
                return axios.post(url, payload, requestConfig)
                            .then(response => {
                                return response.data;
                            })
                            .catch(error => {
                                return Promise.reject(error);
                            });
            },

            getHistogramDistribution(collectionId, filter, histogramSize, dateTimeDimensionId, timeInterval, dimensions) {
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };
                let url = BACKEND + '/collections/' + collectionId + '/dimensions/' + dimensions + '/distribution/date-histogram';
                let payload = { histogramSize, dateTimeDimensionId, timeInterval, dimensions, filter };
                return axios.post(url, payload, requestConfig)
                            .then(response => {
                                return response.data;
                            })
                            .catch(error => {
                                return Promise.reject(error);
                            });
            }
        },

        measures : {

            getQuartilesDistribution(collectionId, attributeName, filter) {
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };
                let url = BACKEND + '/collections/' + collectionId + '/measures/' + attributeName + '/distribution/quartiles';
                let payload = { filter };
                return axios.post(url, payload, requestConfig)
                            .then(response => {
                                return response.data;
                            })
                            .catch(error => {
                                return Promise.reject(error);
                            });
            },

            getPercentilesDistribution(collectionId, attributeName, filter, percents) {
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };
                let url = BACKEND + '/collections/' + collectionId + '/measures/' + attributeName + '/distribution/percentiles';
                let payload = { percents, filter };
                return axios.post(url, payload, requestConfig)
                            .then(response => {
                                return response.data;
                            })
                            .catch(error => {
                                return Promise.reject(error);
                            });
            }

        },

        query: {


        },
        /*
                query: (collectionId) => {
                   return createListDataService({url: BACKEND + '/' + collectionId, timeout: TIMEOUT});
                },
        */

        documents: {

            findByQuery(collectionId, payload, loadEmbeddedDocuments=false) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };
                let url = BACKEND + '/' + collectionId + '/query';
                if (loadEmbeddedDocuments) {
                    url = url + '?loadEmbeddedDocuments=true'
                }

                return axios.post(url, payload, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            groupByQuery(collectionId, payload) {
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                let url = BACKEND + '/' + collectionId + '/query/groupBy';

                return axios.post(url, payload, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            exportDocument(collectionId, payload) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/collections/' + collectionId + '/export/documents';

                return axios.post(url, payload, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findById(collectionId, documentId) {
                let url = BACKEND + '/collections/' + collectionId + '/documents/' + documentId;

                return axios.get(url, { timeout: TIMEOUT_LONG_RUNNING })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(collectionId, documentId) {
                let url = DOCUMENT_GW_URL + '/collections/' + collectionId + '/documents/' + documentId;

                return axios.delete(url, { timeout: TIMEOUT_LONG_RUNNING })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            import(collectionId, documentRecords) {
                let url = DOCUMENT_GW_URL + '/documentimport/collections/' + collectionId
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };
                const payload = {
                    documents: documentRecords
                }

                return axios.post(url, payload, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }
        },

        notifications: {
            findByQuery: (payload) => {
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/users/me/messages';

                return axios.post(url, payload, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
            findById(documentId) {
                let url = BACKEND + '/collections/Notification' + '/documents/' + documentId;

                return axios.get(url, { timeout: TIMEOUT_LONG_RUNNING })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
            count: () => {
                const payload = {
                    filter: {
                        facetFilters: [],
                        filterQuery: "*",
                        filterQueryLanguage: "SIMPLE",
                        rangeFilters: []
                    },
                    sort: {
                        sortCriteria: []
                    },
                    pagination: {
                        from: 0,
                        searchAfter: [],
                        size: 50
                    },
                    queryTimeoutMiliseconds: 25000
                }
                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/users/me/messages/count';

                return axios.post(url, payload, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
            exportDocument(collectionId, payload) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/collections/' + collectionId + '/export/documents';

                return axios.post(url, payload, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }
        },
    },

    timeSeries: {

        get(/*{collectionId, measureId, timeDimensionId, fromDateTime, untilDateTime, filter: *, max: 5.000}*/) {

            return {
                tenant: {},
                collectionSettings: {},
                schema: {},
                request: {},

                items: [
                    {
                        dateTime: '2022-05-24T06:0023.000+01:00',
                        rawValue: 21.987652,
                        value: 22.0
                    }
                ],

                stats: {
                    avg: 25.0
                }

            }
        }
    },

    prediction: {

        createPredictionScenario(scenarioId, request) {
            const requestConfig = {
                timeout: TIMEOUT_LONG_RUNNING
            };

            const url = PREDICTION_BACKEND + '/' + scenarioId;

            return axios.post(url, request, requestConfig)
                .then(response => {

                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        createPredictionModelConfiguration(modelId, configurationId, request) {

            const requestConfig = {
                timeout: TIMEOUT_LONG_RUNNING
            };

            const url = PREDICTION_BACKEND + '/' + modelId + '/configurations/' + configurationId;

            return axios.post(url, request, requestConfig)
                .then(response => {

                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        deletePredictionModelConfiguration(modelId, configurationId) {

            const requestConfig = {
                timeout: TIMEOUT_LONG_RUNNING
            };

            const url = PREDICTION_BACKEND + '/' + modelId + '/configurations/' + configurationId;

            return axios.delete(url, {}, requestConfig)
                .then(response => {

                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        updateI18n(modelId, request) {

            const requestConfig = {
                timeout: TIMEOUT_LONG_RUNNING
            };

            const url = PREDICTION_BACKEND + '/' + modelId + '/i18n';

            return axios.post(url, request, requestConfig)
                .then(response => {

                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },



        findAllPredictionScenarios() {
            const url = PREDICTION_BACKEND;
            return axios.get(url, { timeout: TIMEOUT })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        findPredictionScenarioById(scenarioId) {
            const url = PREDICTION_BACKEND + '/' + scenarioId;
            return axios.get(url, { timeout: TIMEOUT })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    Promise.reject(error);
                });
        },

        models: {

            createModel(scenarioId, modelId, request) {

                const url = PREDICTION_BACKEND + '/' + scenarioId + '/models/' + modelId;

                return axios.post(url, request, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }
        }

    },

    me: {
        listTemplates: {

            update(name, request) {
                let url = BACKEND + '/me/list-templates/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            delete(name) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                let url = BACKEND + '/me/list-templates/' + name;

                return axios.delete(url, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByName(name) {
                let url = BACKEND + '/me/list-templates/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByFilter(filter, from, pageSize) {
                let url = BACKEND + '/me/list-templates/';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByCollectionId(collectionId, filter) {
                let url = BACKEND + '/me/list-templates/collection/' + collectionId;

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter }  })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
        },
    },

    catalog: {

        tenant: {

            find() {
                let url = BACKEND + '/catalog/tenant';

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update(tenant) {
                let url = BACKEND + '/catalog/tenant/';

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, tenant, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        
        /**
         * The endpoint for managing tags.
         * @since DXS 3.5.0
         */
        tags: {
            
            create(request) {
                let url = BACKEND + '/catalog/tags/' + request.name;
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update: (name, request) => {
                let url = BACKEND + '/catalog/tags/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findById(name) {
                let url = BACKEND + '/catalog/tags/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAll(filter="*", from=0, pageSize=50) {

                let url = BACKEND + '/catalog/tags';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(name) {
                let url = BACKEND + '/catalog/tags/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.delete(url, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            }
        },

         /**
         * The endpoint for managing collection settings.
         * @since DXS 3.0.0
         */
        collectionSettings: {

            findById(collectionId) {
                let url = BACKEND + '/catalog/collectionSettings/' + collectionId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAll: (filter) => {

                let url = BACKEND + '/catalog/collectionSettings';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            create: (name, request) => {
                let url = BACKEND + '/catalog/collectionSettings/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            update: (name, request) => {
                let url = BACKEND + '/catalog/collectionSettings/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            deleteById(name) {
                let url = BACKEND + '/catalog/collectionSettings/' + name;

                const requestConfig = {
                    timeout: TIMEOUT_VERY_LONG_RUNNING
                };

                return axios.delete(url, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            }

        },

        
         /**
         * The endpoint for managing collection settings.
         * @since DXS 3.3.0
         */
        systemCollectionSettings: {
            findById(collectionId) {
                let url = BACKEND + '/catalog/systemCollectionSettings/' + collectionId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAll: (filter="*", from=0, pageSize=50) => {

                let url = BACKEND + '/catalog/systemCollectionSettings';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

        },

        
         /**
         * The endpoint for managing collection settings.
         * @since DXS 3.0.0
         */
        schemas: {

            findById(schemaId) {
                let url = BACKEND + '/catalog/schemas/' + schemaId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAll: (filter) => {

                let url = BACKEND + '/catalog/schemas';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            create(request) {
                let url = BACKEND + '/catalog/schemas';
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update(name, request) {
                let url = BACKEND + '/catalog/schemas/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },

        /**
         * The endpoint for managing user list templates.
         * @since DXS 3.4.0
         */
        userListTemplates: {

            update(userId, collectionId, templateId, request) {
                let url = BACKEND + '/usermgmt/userListTemplates/' + userId + '/' + collectionId + '/' + templateId;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            delete(userId, collectionId, name) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                let url = BACKEND + '/usermgmt/userListTemplates/' + userId + '/' + collectionId + '/' + name;

                return axios.delete(url, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByName(name) {
                let url = BACKEND + '/usermgmt/userListTemplates/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByFilter: (filter="*", from=0, pageSize=50) => {

                let url = BACKEND + '/usermgmt/userListTemplates';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByUserId(filter, userId) {
                let url = BACKEND + '/usermgmt/userListTemplates/X/' + userId;

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByUserIdAndCollectionId(filter, userId, collectionId) {
                let url = BACKEND + '/usermgmt/userListTemplates/X/' + userId + "/" + collectionId;

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByUserIdAndCollectionIdAndName(userId, collectionId, name) {
                let url = BACKEND + '/usermgmt/userListTemplates/X/' + userId + "/" + collectionId + "/" + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

        },

        /**
         * The endpoint for managing tenant list templates.
         * @since DXS 3.5.0
         */
        tenantListTemplates: {

            update(collectionId, templateId, request) {
                let url = BACKEND + '/catalog/tenantListTemplates/' + collectionId + '/' + templateId;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            delete(collectionId, name) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                let url = BACKEND + '/catalog/tenantListTemplates/' + collectionId + '/' + name;

                return axios.delete(url, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByName(name) {
                let url = BACKEND + '/catalog/tenantListTemplates/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByFilter: (filter="*", from=0, pageSize=50) => {

                let url = BACKEND + '/catalog/tenantListTemplates';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByCollectionId(filter, collectionId) {
                let url = BACKEND + '/catalog/tenantListTemplates/' + "/" + collectionId;

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findByCollectionIdAndName(collectionId, name) {
                let url = BACKEND + '/catalog/tenantListTemplates/' + "/" + collectionId + "/" + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

        },
       

         /**
         * The endpoint for managing system parameters.
         * @since DXS 3.0.0
         */
        systemParameters: {

            create(name, request) {
                let url = BACKEND + '/catalog/systemParameters/' + name;
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update(name, request) {
                let url = BACKEND + '/catalog/systemParameters/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            delete(name) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                let url = BACKEND + '/catalog/systemParameters/' + name;

                return axios.delete(url, requestConfig)
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findById(name) {
                let url = BACKEND + '/catalog/systemParameters/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAll(filter) {

                let url = BACKEND + '/catalog/systemParameters';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
        },


         /**
         * The endpoint for managing mapping job templates.
         * @since DXS 3.3.0
         */
        mappingJobTemplates: {

            create(request) {
                let url = BACKEND + '/catalog/mappingJobTemplates/' + request.name;
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update: (name, request) => {
                let url = BACKEND + '/catalog/mappingJobTemplates/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findById(name) {
                let url = BACKEND + '/catalog/mappingJobTemplates/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAll(filter, from, pageSize) {

                let url = BACKEND + '/catalog/mappingJobTemplates';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(name) {
                let url = BACKEND + '/catalog/mappingJobTemplates/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.delete(url, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            }
        },




        findAllCollectionSettings: () => {

            let url = BACKEND + '/catalog/collectionSettings';

            return axios.get(url, { timeout: TIMEOUT })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        findCollectionSettingsById: (collectionId) => {

            let url = BACKEND + '/catalog/collectionSettings/' + collectionId;

            return axios.get(url, { timeout: TIMEOUT })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    Promise.reject(error);
                });
        }


    },

    dataPreparation: {

        mappingJobInstances: {

            startJob(jobInstanceId, jobTemplateId) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/mappingJobs/templates/' + jobTemplateId + '/instances/' + jobInstanceId;

                return axios.post(url, {}, requestConfig)
                        .then(response => {
                            return response.data;
                        })
                        .catch(error => {
                            return Promise.reject(error);
                        });
            },

            startReindex(collectionId, jobInstanceId) {

                const requestConfig = {
                    timeout: TIMEOUT_LONG_RUNNING
                };

                const url = BACKEND + '/mappingJobs/reindex/' + collectionId + '/' + jobInstanceId;

                return axios.post(url, {}, requestConfig)
                        .then(response => {
                            return response.data;
                        })
                        .catch(error => {
                            return Promise.reject(error);
                        });
            }
        },
    },


    upload: {
        uploadFile(collectionId, file, fileName, fileFormat) {
            let url = "";
            if(fileFormat == 'CSV'){
               url = DOCUMENT_GW_URL + '/upload/csv/' + collectionId;
            }else if(fileFormat == 'JSON'){
               url = DOCUMENT_GW_URL + '/upload/json/' + collectionId;
            }

            const requestConfig = {
                timeout: TIMEOUT_LONG_RUNNING * 5,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            };
            const formData = new FormData();
            formData.append('file', file);
            formData.append('filename', fileName);
            return axios.post(url, formData, requestConfig)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        }
    },

    deadLetter: {
        reingest(documentId, documentRecord) {

            let url = DOCUMENT_GW_URL + '/deadLetterDocuments/documents/' + documentId + '/reingest';

            const requestConfig = {
                timeout: TIMEOUT_LONG_RUNNING
            };
            return axios.post(url, documentRecord, requestConfig)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },
    },

    userMgmt: {

        userAccounts: {
            findAll(filter) {

                let url = BACKEND + '/usermgmt/userAccounts';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findById(name) {
                let url = BACKEND + '/usermgmt/userAccounts/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            create(request) {
                let url = BACKEND + '/usermgmt/userAccounts/' + request.name;
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update(name, request) {
                let url = BACKEND + '/usermgmt/userAccounts/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            deleteById(name) {
                let url = BACKEND + '/usermgmt/userAccounts/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.delete(url, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            }
        },

        userGroupAccounts: {
            findAll(filter) {

                let url = BACKEND + '/usermgmt/userGroupAccounts';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findById(name) {
                let url = BACKEND + '/usermgmt/userGroupAccounts/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            create(request) {
                let url = BACKEND + '/usermgmt/userGroupAccounts/' + request.name;
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update(name, request) {
                let url = BACKEND + '/usermgmt/userGroupAccounts/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            deleteById(name) {
                let url = BACKEND + '/usermgmt/userGroupAccounts/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.delete(url, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            }

        },

        users: {

            findById(name) {
                let url = BACKEND + '/usermgmt/users/' + name;

                // Keycloak communication tends to be slow. We therefore apply a long timeout.
                return axios.get(url, { timeout : TIMEOUT_VERY_LONG_RUNNING })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAll(filter, from=0, pageSize=50) {

                let url = BACKEND + '/usermgmt/users';
                filter = "a";

                // Keycloak communication tends to be slow. We therefore apply a long timeout.
                return axios.get(url, { timeout: TIMEOUT_VERY_LONG_RUNNING, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
        },

        userGroups: {

            findById(name) {
                let url = BACKEND + '/usermgmt/userGroups/' + name;

                // Keycloak communication tends to be slow. We therefore apply a long timeout.
                return axios.get(url, { timeout: TIMEOUT_VERY_LONG_RUNNING })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findAll(filter, from=0, pageSize=50) {

                let url = BACKEND + '/usermgmt/userGroups';

                // Keycloak communication tends to be slow. We therefore apply a long timeout.
                return axios.get(url, { timeout: TIMEOUT_VERY_LONG_RUNNING, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },
        },

        accessControlLists: {

            findAll(filter, from=0, pageSize=50) {

                let url = BACKEND + '/usermgmt/accessControlLists';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findById(name) {
                let url = BACKEND + '/usermgmt/accessControlLists/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            create(request) {
                let url = BACKEND + '/usermgmt/accessControlLists/' + request.name;
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update(name, request) {
                let url = BACKEND + '/usermgmt/accessControlLists/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            deleteById(name) {
                let url = BACKEND + '/usermgmt/accessControlLists/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.delete(url, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            }
        },

        /**
         * Represents the current authorization state of a given user.
         * This is the effective authorization state which is assembled
         * from the user account, group memberships, etc.
         */
        userAuthorization: {

            findById(userId) {

                let url = BACKEND + '/usermgmt/userAuthorization/' + userId;

                return axios.get(url, { timeout: TIMEOUT_LONG_RUNNING })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }
        },

    },

    system: {
        serviceAvailability: {
            getStatus() {

                let url = BACKEND + '/system/serviceAvailability';
                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        }
    },

    webhooks: {
        webhookSubscriptions: {
            findAll(filter='*', from=0, pageSize=50) {

                let url = BACKEND + '/webhookSubscriptions';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findById(name) {
                let url = BACKEND + '/webhookSubscriptions/' + name;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            create(request) {
                let url = BACKEND + '/webhookSubscriptions/' + request.name;
                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.post(url, request, requestConfig)
                    .then(response => {

                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            update(name, request) {
                let url = BACKEND + '/webhookSubscriptions/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            deleteById(name) {
                let url = BACKEND + '/webhookSubscriptions/' + name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.delete(url, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            }

        }
    }
}