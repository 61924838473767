<template>
    <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form :title="$t('battery-substance-editor.view.title', { name: name })" :value="batteryChemistry"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole&hasReadRole">

            <template v-slot:default>
                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('battery-substance-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('battery-substance-editor.view.tabs.json') }}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="batteryChemistry" :disabled="!hasEditRole"/>
                            </v-tab-item>

                            

                            <!-- JSON -->
                            <v-tab-item :key="1">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
        </c-item-editor-form>
    </v-sheet>
</template>

<script>
import { cloneDeep, get } from 'lodash';

import BatteryChemistryEditorModule from "../../../store/masterdata/battery-chemistries/battery-chemistries-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'BatteryChemistryEditor';

export default {

    name: 'battery-substance-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],



    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/saveData");
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
      this.$router.push({
        name: "BatteryChemistryListView",
      });
      }
    },

    computed: {

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.BATTERYCHEMISTRY_EDIT);
        },

        hasReadRole() {
            return this.userContextService.hasRole(Roles.BATTERYCHEMISTRY_READ);
        },

        batteryChemistry: {

            get() {
                return this.$store.getters[MODULE_ID + '/batteryChemistry'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryChemistry", value);
            }
        },



        batteryChemistrysId: {

set(item) {
    this.$store
        .dispatch(MODULE_ID + "/updateBatteryChemistrys", item)
        .catch(this.errorHandlerService.handleError);
},
},
        
        model() {
            return cloneDeep(this.batteryChemistry);
        }

    },

    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatteryChemistryEditorModule);
        }

        this.refresh();

    },
  destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_ID)) {
        this.$store.unregisterModule(MODULE_ID);
        this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
    }
  }
}
</script>g

<i18n>
   
{
    "en" : {
        "battery-substance-editor.view.title" : "Battery Chemistry {name}",

        "battery-substance-editor.view.tabs.general" : "General",
        "battery-substance-editor.view.tabs.settings" : "Settings",
        "battery-substance-editor.view.tabs.json" : "JSON",
        "battery-substance-editor.view.component.title":"Settings",
        "battery-substance-editor.view.component.subtitle": "This page contains settings for the selected Battery Chemistry.",
        "battery-substance-editor.view.sections.hazardousSubstanceClassification.title":"Hazardous Substance Classification",
        "battery-substance-editor.view.sections.hazardousSubstanceClassification.subtitle" : "Here you assign the Battery Chemistry the respective Hazardous Substance Classification.",
        "battery-substance-editor.view.fields.schema.validation.notEmpty":"This field cannot be empty."
    },

    "de" : {
        "battery-substance-editor.view.title" : "Batterie-Chemie {name}",
        "battery-substance-editor.view.tabs.general" : "Allgemein",
        "battery-substance-editor.view.tabs.settings" : "Einstellungen",
        "battery-substance-editor.view.tabs.json" : "JSON",
        "battery-substance-editor.view.component.title":"Einstellungen",
        "battery-substance-editor.view.component.subtitle": "Diese Seite beinhaltet Einstellungen für das ausgewählte Batterie-Chemie.",
        "battery-substance-editor.view.fields.schema.validation.notEmpty":"Dieses Feld kann nicht leer sein."
    }
}
</i18n>