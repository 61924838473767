<template>
    
    <v-sheet class="secondary darken-3" >
        <c-sticky-content>
                <v-toolbar color="secondary darken-3" flat>
                    <v-toolbar-items>
                        <v-btn icon color="white" @click="goBack"><v-icon>arrow_back</v-icon></v-btn>
                    </v-toolbar-items>
                    <v-toolbar-title class="white--text" v-if="!headerArea.visible">{{ batteryDashboardTitle }}</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-btn icon @click="refresh" color="white"><v-icon>refresh</v-icon></v-btn>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" color="white"><v-icon >more_vert</v-icon></v-btn>
                            </template>
                            <v-list>
                                <v-list-item two-line @click="openBatteryPack">
                                    <v-list-item-avatar>
                                        <v-icon>battery_full</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('battery-passport-dashboard.view.actions.goToBatteryPack') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $dxs.i18n.localizedName(this.batteryPack, $i18n.locale) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item  two-line @click="openBatteryModel">
                                    <v-list-item-avatar>
                                        <v-icon>battery_full</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title >{{ $t('battery-passport-dashboard.view.actions.goToBatteryModel') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $dxs.i18n.localizedName(this.batteryModel, $i18n.locale) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider />
                                <v-list-item @click="showJsonModel">
                                    <v-list-item-avatar>
                                        <v-icon>code</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title >{{ $t('battery-passport-dashboard.view.actions.goToJsonModel') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider v-if="!headerArea.visible" />
        </c-sticky-content>

        <v-sheet class="secondary darken-3" v-intersect="onIntersectHeaderArea">
            
            <div class="text-h6 white--text text-center pt-5">
                Battery Passport
            </div>
            <div class="text-h5 secondary--text text-center pb-5">
               {{ batteryDashboardTitle }}
            </div>
        </v-sheet>
<!--
        <v-img src="@/assets/img/header-application.svg" />
-->    
        <!-- =============================================================================== -->
        <!-- QUICK FACTS                                                                     -->
        <!-- =============================================================================== -->
        
        <v-container class="secondary darken-3 mt-5 mb-5" fluid>
            <v-row>
                <v-col cols="12" md="6" lg="3">
                    <v-card color="secondary darken-4" flat>
                        <v-card-text>
                            <div class="text-strong white--text">{{ $t('battery-passport-dashboard.view.facts.weight.title')}}</div>
                            <div class="text-h6 white--text">{{$dxs.formatting.formatDecimal(batteryWeight, $i18n.locale)}} kg</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                    <v-card color="secondary darken-4" flat>    
                        <v-card-text>
                            <div class="text-strong white--text">{{ $t('battery-passport-dashboard.view.facts.carbonFootprint.title')}}</div>
                            <div class="text-h6 white--text">96.2 kg CO₂e / kWh</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                    <v-card color="secondary darken-4" flat>
                        <v-card-text>
                            <div class="text-strong white--text">{{ $t('battery-passport-dashboard.view.facts.manufacturer.title')}}</div>
                            <div class="text-h6 white--text">{{ $dxs.i18n.localizedName(manufacturer, $i18n.locale)}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                    <v-card color="secondary darken-4" flat>
                        <v-card-text>
                            <div class="text-strong white--text">{{ $t('battery-passport-dashboard.view.facts.batteryChemistry.title')}}</div>
                            <div class="text-h6 white--text">{{$dxs.i18n.localizedName(batteryChemistry, $i18n.locale)}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-card flat tile>
            <v-tabs 
                v-model="navigation.selectedTab"
                centered 
                color="accent" 
                background-color="secondary darken-3"
                dark
                :show-arrows="$vuetify.breakpoint.smAndDown"
                >
        

                <v-tab>{{$t('battery-passport-dashboard.view.navigation.general.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.composition.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.carbonFootprint.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.circularity.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.supplyChain.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.compliance.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.performance.title')}}</v-tab>

                <v-tabs-items v-model="navigation.selectedTab">


                    <v-tab-item>
                        <dxs-battery-general-info-page :value="batteryGeneralInfoPageModel" />                       
                    </v-tab-item>

                    <v-tab-item>
                        <dxs-battery-material-composition-page :value="batteryMaterialCompositionPageModel" />
                    </v-tab-item>
                    
                    <v-tab-item>
                        <dxs-carbon-footprint-page :value="{}" />
                    </v-tab-item>
                    
                    <v-tab-item>
                        <dxs-battery-circularity-page :value="batteryCircularityPageModel" />
                    </v-tab-item>
                    
                    <v-tab-item>
                        <dxs-supply-chain-page :value="supplyChainPageModel" />
                    </v-tab-item>
                    
                    <v-tab-item>
                        <dxs-battery-compliance-page :value="batteryCompliancePageModel" />
                    </v-tab-item>
                    
                    <v-tab-item></v-tab-item>

                </v-tabs-items> 
            </v-tabs>
        </v-card>
                 
        <v-dialog fullscreen v-model="jsonModelDialog.display">
            <v-card tile flat>
                <v-toolbar flat>
                    <v-toolbar-title>JSON Model</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-btn icon @click="hideJsonModel"><v-icon>close</v-icon></v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-tabs v-model="jsonModelDialog.selectedTab" :vertical="$vuetify.breakpoint.mdAndUp"  >
                    
                    <v-tab>{{$t('battery-passport-dashboard.view.jsonModelDialog.tabs.battery')}}</v-tab>
                    <v-tab>{{$t('battery-passport-dashboard.view.jsonModelDialog.tabs.batteryModel')}}</v-tab>

                    <v-tabs-items v-model="jsonModelDialog.selectedTab">

                        <v-tab-item>
                            <c-json-viewer :value="battery"></c-json-viewer>
                        </v-tab-item>

                        <v-tab-item>
                            <c-json-viewer :value="batteryModel"></c-json-viewer>
                        </v-tab-item>
                    
                    </v-tabs-items>

                </v-tabs>
            </v-card>
        </v-dialog>


    </v-sheet>
</template>

<script>

import BatteryGeneralInfoPage from './pages/battery-general-info.page';
import BatteryMaterialCompositionPage from './pages/battery-material-composition.page';
import BatteryCircularityPage from './pages/battery-circularity.page';
import CarbonFootprintPage from './pages/carbon-footprint.page';
import SupplyChainPage from './pages/supply-chain.page';
import BatteryCompliancePage from './pages/battery-compliance.page';

import BatteryPassportDashboardModule from '@/solutions/battery-data-hub/store/apps/battery-passport/battery-passport-dashboard.module';

import { get } from 'lodash';

const MODULE_NAME = 'battery-data-hub:apps:battery-passport:battery-passport-dashboard'; 

export default {

    name: 'battery-passport-dashboard',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    data() {

        return {

            headerArea: {
                visible: true
            },

            navigation: {
                selectedTab: 0
            },

            jsonModelDialog: {
                display: false,
                selectedTab: 0
            }
        }
    },

    props: {
        value: {
            type: Object,
            required: true
        }
    }, 

    components: {
        'dxs-battery-general-info-page' : BatteryGeneralInfoPage,
        'dxs-battery-material-composition-page' : BatteryMaterialCompositionPage,
        'dxs-battery-circularity-page' : BatteryCircularityPage,
        'dxs-carbon-footprint-page' : CarbonFootprintPage,
        'dxs-supply-chain-page' : SupplyChainPage,
        'dxs-battery-compliance-page' : BatteryCompliancePage
    },

    methods: {
        onIntersectHeaderArea(entries) {
            // More information about these options
            // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
            this.headerArea.visible = entries[0].isIntersecting;
        },

        async refresh() {
            this.$log.debug(`Refreshing view for battery pack ${this.batteryName}.`)
            await this.init(this.batteryName);
        }, 

        async init(name) {
            this.$log.debug(`Loading battery passport for battery pack ${name}.`)
            this.progressIndicatorService.show();

            try {
                
                // First make sure that the view is reset properly. 

                // First load the battery masterdata
                this.progressIndicatorService.updateText(this.$t('battery-passport-dashboard.view.progress.battery'));
                await this.$store.dispatch(`${MODULE_NAME}/loadBatteryData`, { name });

                // Now load the associated battery model
                // Some metadata is not available  from the battery pack ifself, therefore we still need to access the 
                // associated battery model.
                this.progressIndicatorService.updateText(this.$t('battery-passport-dashboard.view.progress.batteryModel'));
                await this.$store.dispatch(`${MODULE_NAME}/loadBatteryModel`, { batteryModelId: this.batteryModel.name });

                // The material composition must be loaded in the context of the battery model
                
                this.progressIndicatorService.updateText(this.$t('battery-passport-dashboard.view.progress.materialComposition'));
                await this.$store.dispatch(`${MODULE_NAME}/loadMaterialCompositionData`, { batteryModelId: this.batteryModel.name });

                // The same goes for the battery material masterdata
                this.progressIndicatorService.updateText(this.$t('battery-passport-dashboard.view.progress.material'));
                await this.$store.dispatch(`${MODULE_NAME}/loadMaterialData`, { batteryModelId: this.batteryModel.name });

            } catch (error) {
                this.errorHandlerService.handleError(error);

            } finally {
                this.progressIndicatorService.hide();
            }
        },

        // Navigates to the battery pack detail dialog.
        openBatteryPack() {
            this.$router.push({
                name: 'BatteryPackEditorView',
                params: { name: this.batteryName }
            });
        },

        // Navigates to the battery model detail dialog.
        openBatteryModel() {
            this.$router.push({
                name: 'BatteryModelEditorView',
                params: { name: this.batteryModel.name }
            });
        },

        showJsonModel() {
            this.jsonModelDialog.display = true;
        },

        hideJsonModel() {
            this.jsonModelDialog.display = false;
        },


        goBack() {
            this.$router.go(-1);
        }
    },


    computed: {

        batteryDashboardTitle() {
            let title = this.batteryName;
            if (this.batteryPassportId) {
                title = `${title} : ${this.batteryPassportId}`;
            }
            return title; 
        },

        batteryName() {
            return get(this.value, 'name');
        },

        // Central data model
        dataModel() {
            return this.$store.getters[`${MODULE_NAME}/dataModel`];
        },


        // Data Objects
        batteryModel() {
            return get(this.dataModel, 'batteryModel', { data: {} });
        },

        batteryPack() {
            return get(this.dataModel, 'batteryPack', { data: {} });
        },

        batteryStatus() {
            return get(this.dataModel, 'batteryStatus', { data: {}  });
        },

        manufacturer() {
            return get(this.dataModel, 'manufacturer', { data: {}  })
        },

        manufacturerPlant() {
            return get(this.dataModel, 'manufacturerPlant', { data: {}  })
        },

        batteryCategory() {
            return get(this.dataModel, 'batteryCategory', { data: {} })
        },

        batteryChemistry()  {
            return get(this.dataModel, 'batteryChemistry', { data: {} })
        },

        batteryWeight() {
            return get(this.batteryPack, 'data.weightKg', 0);
        },

        batteryMaterialComposition() {
            return this.$store.getters[`${MODULE_NAME}/batteryMaterialComposition`];
        },

        materials() {
            return this.$store.getters[`${MODULE_NAME}/materials`];
        },

        materialsMap() {
            const map = {};
            for (const material of this.materials) {
                map[material.name] = material;
            }

            
            return map;
        },

        model() {

            return { 
                battery: {

                    data: {
                        batteryPassportId: 'fbd5ddcb-452f-4e5a-9dd2-23f0bad898d4'
                    }
                }, 
                
                materialComposition:[
                    { material: { name: 'Ni', data: { color : '#005155' } }, share: 4.90 },
                    { material: { name: 'Pb', data: { color : '#599099' } }, share: 6.74 },
                    { material: { name: 'Co', data: { color : '#7fb1bd' } }, share: 1.3 },
                    { material: { name: 'Li', data: { color : '#a5d3e3' } }, share: 4.9 }
                ],

                batteryModel: {

                    conformity: {
                        links: {
                            euDeclarationOfConformance: {
                                name: 'euDeclarationOfConformance',
                                i18n: { en: { name: 'EU Declaration of Conformance'}},
                                url: 'https://eu.gov/batteries/declarationConformance'
                            }

                        }
                    }
                    
                },
                
                safetyRequirements: {
                    
                    name: 'general',
                    
                    // See https://vanguard-fire.com/what-are-the-5-different-classes-of-fires/
                    fireExtinguisherClass: {
                        name: 'FlammableGases', 
                        i18n: {
                            en: { name: 'Flammable Gases', description: 'This class involves flammable gases, e.g. The best way to deal with these types of fires is by smothering them or removing oxygen using foam or CO2 fire suppression equipment.' },
                        },

                        data:{
                            
                            class: {
                                EU: 'C',

                                US: 'B',

                                AU: 'B'
                            },

                            extinguishingAgents: [
                                {
                                    name: 'DryChemicalPowder' 
                                }, 
                                {
                                    name: 'CarbonDioxide'
                                }
                            ],
                            
                            link: 'https://en.wikipedia.org/wiki/Class_B_fire'
                        }


                    }
                    
                },

                endOfLife: {

                    endUserWastePreventionInfo: {
                        
                        name: 'endUserWastePreventionInfo',
                        i18n: {
                            en: {
                                name: 'Role of end-users in contributing to waste prevention'
                            }
                        },
                        url: 'https://battery-waste.info'
                    },

                    endUserWasteCollectionInfo: {
                        
                        name: 'endUserWasteCollectionInfo',
                        i18n: {
                            en: {
                                name: 'Role of end-users in contributing to the separate collection of waste batteries'
                            }
                        },
                        url: 'https://battery-waste.info'
                    },

                    generalInfo: {
                        
                        name: 'generalInfo',
                        i18n: {
                            en: {
                                name: 'Information on separate collection, take back, collection points and preparing for re-use, preparing for repurposing and recycling operations'
                            }
                        },
                        url: 'https://battery-waste.info'
                    }


                    
                },

                substances: [

                    { 
                        substance: { 
                            name: 'Ni', 
                            i18n: { de: { name: 'Nickel'} } 
                        },
                        
                        batterySubstanceQuantity: {
                            totalQuantityMilligram: 8000,
                            preConsumerRecyclingShare: 17.0,
                            postConsumerRecyclingShare: 7.0
                        }
                    },
                    { 
                        substance: { 
                            name: 'Co', 
                            i18n: { de: { name: 'Kobalt'} } 
                        },
                        
                        batterySubstanceQuantity: {
                            totalQuantityMilligram: 8000,
                            preConsumerRecyclingShare: 10.0,
                            postConsumerRecyclingShare: 9.0
                        }
                    },
                    { 
                        substance: { 
                            name: 'Li', 
                            i18n: { de: { name: 'Lithium'} } 
                        },
                        
                        batterySubstanceQuantity: {
                            totalQuantityMilligram: 6000,
                            preConsumerRecyclingShare: 14.0,
                            postConsumerRecyclingShare: 10.0
                        }
                    },
                    { 
                        substance: { 
                            name: 'Pb', 
                            i18n: { de: { name: 'Blei'} } 
                        },
                        
                        batterySubstanceQuantity: {
                            totalQuantityMilligram: 12000,
                            preConsumerRecyclingShare: 11.0,
                            postConsumerRecyclingShare: 6.0
                        }
                    }

                ]
            }   
        },

        batteryPassportId() {
            return get(this.battery, 'batteryPack.data.batteryPassportId', null);
        },  

        batteryGeneralInfoPageModel() {
            return {
                batteryPack: this.batteryPack,
                batteryModel: this.batteryModel,
                manufacturer: this.manufacturer,
                manufacturerPlant: this.manufacturerPlant,
                batteryStatus: this.batteryStatus,
                batteryCategory: this.batteryCategory
            }
        },



        batteryMaterialCompositionPageModel() {

            const materialCompositionItems = [];
            for (const mc of this.batteryMaterialComposition) {

                const materialId = mc.references.batterymaterialId;
                materialCompositionItems.push({
                    name: materialId,
                    material: this.materialsMap[materialId] ? this.materialsMap[materialId] : { data: {}},
                    materialComposition: mc
                });
            }


            return {
                battery: this.model.battery,

                materialComposition: materialCompositionItems
            }
        },

        batteryCircularityPageModel() {

            return {
                battery: this.model.battery,

                substances: this.model.substances,
                
                safetyRequirements: this.model.safetyRequirements,

                endOfLife: this.model.endOfLife
            }
        },

        supplyChainPageModel() {

            return {

                dueDiligenceReport: {

                    name: 'DueDiligenceReport',
                    i18n: {
                        en: { name: 'Due Diligence Report'}
                    },
                    url: 'Due Diligence Report',
                },

                additionalVoluntary: [
                    
                    { 
                        name: 'ThirdPartySupplyChainAssurances',
                        i18n: { en: { name: 'Third party supply chain assurances'} },
                        url: 'http://api.cloud.amentis-solutions.com/files/3rdparty.pdf'
                    },
                    { 
                        name: 'EUTaxonomyDisclosureStmt',
                        i18n: { en: { name: 'EU Taxonomy disclosure statement'} },
                        url: 'http://api.cloud.amentis-solutions.com/files/eutaxonomydisclosurestmt.pdf'
                    },
                    { 
                        name: 'SustainabilityReport',
                        i18n: { en: { name: 'Sustainability Report'} },
                        url: 'http://api.cloud.amentis-solutions.com/files/sustainability.pdf'
                    }
                
                ]
            }
        },

        batteryCompliancePageModel() {
            
            return {
                batteryModel: this.batteryModel,
            }
        }

    },

    // ==========================================================================
    // CREATED HOOK
    // ==========================================================================
    async created() {
        this.$log.debug(`Creating Battery Passport Dashboard.`)
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, BatteryPassportDashboardModule);
            this.$log.debug(`Registered new Vuex Store ${MODULE_NAME}`);

        } else {
            this.$log.debug(`Vuex Store ${MODULE_NAME} is already registered.`)
        }        

        // Initialize the view.
        await this.init(this.batteryName);
    },

    // ==========================================================================
    // DESTROYED HOOK
    // ==========================================================================
    destroyed() {
        
        if (this.$store.hasModule(MODULE_NAME)) {
            this.$store.unregisterModule(MODULE_NAME);
            this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`)
        }        
    }

}
</script>

<i18n>
{
    "en" : {
        "battery-passport-dashboard.view.navigation.general.title" : "General",
        "battery-passport-dashboard.view.navigation.composition.title" : "Material Composition",
        "battery-passport-dashboard.view.navigation.carbonFootprint.title" : "Carbon Footprint",
        "battery-passport-dashboard.view.navigation.circularity.title" : "Circularity",
        "battery-passport-dashboard.view.navigation.supplyChain.title" : "Supply Chain",
        "battery-passport-dashboard.view.navigation.compliance.title" : "Compliance",
        "battery-passport-dashboard.view.navigation.performance.title" : "Performance",

        
        "battery-passport-dashboard.view.facts.weight.title" : "Weight",
        "battery-passport-dashboard.view.facts.carbonFootprint.title" : "Carbon Footprint",
        "battery-passport-dashboard.view.facts.manufacturer.title" : "Manufacturer",
        "battery-passport-dashboard.view.facts.batteryChemistry.title" : "Batterie Chemistry",

        "battery-passport-dashboard.view.progress.battery" : "Loading Battery Masterdata ...",
        "battery-passport-dashboard.view.progress.batteryModel" : "Loading Battery Model ...",
        "battery-passport-dashboard.view.progress.materialComposition" : "Loading Battery Materials ...",
        "battery-passport-dashboard.view.progress.material" : "Loading Material Masterdata ...",

        "battery-passport-dashboard.view.actions.goToBatteryPack" : "Open Battery...",
        "battery-passport-dashboard.view.actions.goToBatteryModel" : "Open Battery Model...",
        "battery-passport-dashboard.view.actions.goToJsonModel" : "Show JSON Model...",

        "battery-passport-dashboard.view.jsonModelDialog.tabs.battery" : "Battery",
        "battery-passport-dashboard.view.jsonModelDialog.tabs.batteryModel" : "Battery Model"
    },

    "de" : {
        "battery-passport-dashboard.view.navigation.general.title" : "Allgemein",
        "battery-passport-dashboard.view.navigation.composition.title" : "Materialzusammensetzung",
        "battery-passport-dashboard.view.navigation.carbonFootprint.title" : "CO2 Fußabdruck",
        "battery-passport-dashboard.view.navigation.circularity.title" : "Kreislauf",
        "battery-passport-dashboard.view.navigation.supplyChain.title" : "Lieferkette",
        "battery-passport-dashboard.view.navigation.compliance.title" : "Compliance",
        "battery-passport-dashboard.view.navigation.performance.title" : "Leistung",

        "battery-passport-dashboard.view.facts.weight.title" : "Gewicht",
        "battery-passport-dashboard.view.facts.carbonFootprint.title" : "CO2 Fußabdruck",
        "battery-passport-dashboard.view.facts.manufacturer.title" : "Hersteller",
        "battery-passport-dashboard.view.facts.batteryChemistry.title" : "Batterie Chemie",

        "battery-passport-dashboard.view.progress.battery" : "Batterie wird geladen...",
        "battery-passport-dashboard.view.progress.batteryModel" : "Batterie-Modell wird gelanden ...",
        "battery-passport-dashboard.view.progress.materialComposition" : "Materialzusammensetzung wird geladen ...",
        "battery-passport-dashboard.view.progress.material" : "Materialstammdaten werden geladen ...",

        
        "battery-passport-dashboard.view.actions.goToBatteryPack" : "Batterie öffnen...",
        "battery-passport-dashboard.view.actions.goToBatteryModel" : "Batterie-Modell öffnen...",
        "battery-passport-dashboard.view.actions.goToJsonModel" : "JSON-Modell anzeigen...",

        "battery-passport-dashboard.view.jsonModelDialog.tabs.battery" : "Batterie",
        "battery-passport-dashboard.view.jsonModelDialog.tabs.batteryModel" : "Batterie-Modell"
    }
}
</i18n>