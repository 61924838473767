<template>
<div>
    <v-card tile flat  class="text-center">
        <v-card-text class="grey lighten-5">
            <v-progress-linear :color="color" height="30" :value="loadingPercentage" striped :indeterminate="loading">
                <div class="text-caption">
                    {{$t('item-list-pagination.component.progress.label', {currentItemCount: itemsLengthFormatted, totalItemCount: totalItemCountFormatted, loadingPercentage: loadingPercentageRoundedFormatted})}}
                </div>
            </v-progress-linear>
          </v-card-text>

          <v-divider />

              
            <v-card-text v-if="!noMoreItemsAvailable">

                <v-btn :class="color" text large @click="fireLoadMore" :disabled="disabled"><v-icon>arrow_downward</v-icon>&nbsp;{{$t('item-list-pagination.component.actions.loadMore', {pageSize: pageSize})}}</v-btn>
                <p />
                <div>
                    {{$t('item-list-pagination.component.results.loadFurtherItems', {pageSize: pageSize, totalItemCount: totalItemCount })}}
                </div>
            </v-card-text>
              
            <v-card-text v-else>
              <v-icon :color="color" large>assignment_turned_in</v-icon>
              <br />
              {{$t('item-list-pagination.component.results.noMoreResults', {totalItemCount: this.pagination.totalItemCount})}}
            </v-card-text>

  </v-card>
</div>
</template>

<script>
import { isEmpty  } from 'lodash';
import { get  } from 'lodash';

export default {
    name: 'c-item-list-pagination',

    props: {

        items: {
  
            type: Array,
            required: true,
            default: () => []
        },

        /**
         * Requires an object with pagination information
         * <code>
         * 
         * </code>
         */
        pagination: {
            type: Object,
             required: true,
            //default: () => ({ from:0,pageSize:50,totalItemCount:3,currentPage:1,totalAvailableItems:6})
        },

        color: {
            type: String,
            required: false,
            default: 'accent'
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        loading: {
            type: Boolean,
            required: false,
            default: false
        }
        
    },

    methods: {
        fireLoadMore() {

            this.$emit('load-more', {
                from: this.itemsLength,
                pageSize: this.pageSize
            });
        }
    },

    computed: {
        itemsLength() {
            return isEmpty(this.items) ? 0 : this.items.length; 
        },

        itemsLengthFormatted() {
            return this.$core.filters.numericFormat(this.itemsLength, '#,###', this.$i18n.locale);
        },

        totalItemCount() {
            return get(this.pagination, 'totalItemCount', 0);
        },

        totalItemCountFormatted() {
            return this.$core.filters.numericFormat(this.totalItemCount, '#,###', this.$i18n.locale);
        },

        moreElements() {

            return this.itemsLength < this.totalItemCount;
        },

        loadingPercentage() {
            if (this.itemsLength === 0) return 0;
            else return this.itemsLength / ( this.totalItemCount / 100 ) ;
        },

        loadingPercentageRounded() {
            return this.$dxs.rounding.round(this.loadingPercentage, 2);
        },

        loadingPercentageRoundedFormatted() {
            return this.$core.filters.numericFormat(this.loadingPercentageRounded, '#,###', this.$i18n.locale);
        },

        noMoreItemsAvailable(){
            return this.pagination.totalItemCount <= this.itemsLength;
        },

        pageSize(){
            return get(this.pagination, 'pageSize', 0);
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "item-list-pagination.component.progress.label" : "{currentItemCount} / {totalItemCount} Rows ({loadingPercentage} %)",
        "item-list-pagination.component.actions.loadMore" : "Load Next {pageSize} Rows",
        "item-list-pagination.component.results.loadFurtherItems" : "Please press this button in order to load another {pageSize}  rows of ca. {totalItemCount} total rows matching your filter criteria.",
        "item-list-pagination.component.results.adjustFilterLessResults" : "In order to reduce the total number of rows, refine your filter settings, please.",
        "item-list-pagination.component.results.noMoreResults" : "You are currently viewing all {totalItemCount} rows matching your filter criteria.",
        "item-list-pagination.component.results.adjustFilterMoreResults" : "In order to display additional rows, refine your filter settings, please. "
    },

    "de" : {
        "item-list-pagination.component.progress.label" : "{currentItemCount} / {totalItemCount} Zeilen ({loadingPercentage} %)",

        "item-list-pagination.component.actions.loadMore" : "Nächste {pageSize} Zeilen laden...",
        "item-list-pagination.component.results.loadFurtherItems" : "Bitte klicken Sie auf den Button um weitere {pageSize} Ergebnisse von insg. ca. {totalItemCount} Zeilen zu laden, die zu Ihren Filterkriterien passen.",
        "item-list-pagination.component.results.adjustFilterLessResults" : "Bitte passen Sie die Filterkriterien an, um die Anzahl der Zeilen weiter einzuschränken,",
        "item-list-pagination.component.results.noMoreResults" : "Sie sehen alle {totalItemCount} Zeilen, die zu Ihrer Filterung passen.",
        "item-list-pagination.component.results.adjustFilterMoreResults" : "Bitte passen Sie Ihre Filterung an, um zusätzliche Zeilen anzuzeigen."
    }
}    
</i18n>

