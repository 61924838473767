import { get, isEmpty } from 'lodash';
import api from '@/solutions/battery-data-hub/api/battery-data-hub.api';


export default {

    namespaced: true,

    state: {
        battery: {
            
            batteryPack: {},
            batteryModel: {},
            batteryCategory: {},
            batteryStatus: {},
            batteryChemistry: {},

            manufacturer: {},
            manufacturerPlant: {},


        },

        batteryModel: {

        },

        materialComposition: [],

        materials: []
    },

    mutations: {

        setBattery(state, battery) {
            state.battery = !isEmpty(battery) 
                          ? battery 
                          : { 
                                batteryPack: {} 
                            };
        },

        setBatteryModel(state, batteryModel) {
            state.batteryModel = !isEmpty(batteryModel) 
                               ? batteryModel 
                               : {
                                    batteryModel: {},
                                    batteryChemistry: {}
                                };
        },

        setMaterialComposition(state, materialComposition) {
            state.materialComposition = !isEmpty(materialComposition) ? materialComposition : [];
        },

        setMaterials(state, materials) {
            state.materials = !isEmpty(materials) ? materials : [];
        }
    },

    actions: {
        
        async loadBatteryData(context, {name}){

            const response = await api.masterdata.batteryPacks.findById(name);
            const battery = get(response, 'item');
            context.commit('setBattery', battery);
        },

        async loadBatteryModel(context, {batteryModelId}) {

            const response = await api.masterdata.batteryModels.findById(batteryModelId);
            const batteryModel = get(response, 'item');
            context.commit('setBatteryModel', batteryModel);
        },

        async loadMaterialCompositionData(context, {batteryModelId}) {
            const response = await api.masterdata.batteryMaterialCompositions.findByBatteryModel(batteryModelId, 0, 100);
            const items = get(response, 'items', []);
            context.commit('setMaterialComposition', items);
        },

        
        async loadMaterialData(context, {batteryModelId}) {
            const response = await api.masterdata.batteryMaterials.findByBatteryModel(batteryModelId, 0, 100);
            const items = get(response, 'items', []);
            context.commit('setMaterials', items);
        }
        
    },

    getters: {

        dataModel(state) {

            return {
                batteryPack: get(state, 'battery.batteryPack',  createEmptyItem()),
                batteryModel: get(state, 'battery.batteryModel',  createEmptyItem()),
                batteryStatus: get(state, 'battery.batteryStatus',  createEmptyItem()),
                batteryCategory:  get(state, 'battery.batteryCategory',  createEmptyItem()),
                batteryChemistry:  get(state, 'batteryModel.batteryChemistry',  createEmptyItem()),
                manufacturer: get(state, 'battery.manufacturer',  createEmptyItem()),
                manufacturerPlant: get(state, 'battery.manufacturerPlant',  createEmptyItem())
            }
        },
        
        battery(state) {
            return get(state, 'battery', { batteryPack: {}});
        },

        batteryModel(state) {
            return get(state, 'batteryModel', { batteryModel: {}})
        },

        batteryMaterialComposition(state) {
            return get(state, 'materialComposition', []);
        },

        materials(state) {
            return get(state, 'materials', []);
        }
    }


}

function createEmptyItem() {
    return {
        data: {}, 
        i18n: {}, 
        references: {}
    };
}