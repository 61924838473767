<template>
    <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form :title="$t('battery-substance-editor.view.title', { name: name })" :value="batterySubstance"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole&hasReadRole">

            <template v-slot:default>
                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('battery-substance-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('battery-substance-editor.view.tabs.settings') }}</v-tab>
                        <v-tab :key="2">{{ $t('battery-substance-editor.view.tabs.json') }}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="batterySubstance" :disabled="!hasEditRole"/>
                            </v-tab-item>

                            <!-- hazardousSubstanceClassification Settings (hazardousSubstanceClassification, etc.) -->
                            <v-tab-item :key="1">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('battery-substance-editor.view.component.title') }}</div>
                                        <div>{{ $t('battery-substance-editor.view.component.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                    
                                        <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("battery-substance-editor.view.sections.hazardousSubstanceClassification.title") }}</div>
                                        <div>{{ $t('battery-substance-editor.view.sections.hazardousSubstanceClassification.subtitle') }}</div>
                                        <c-item-selector :disabled="!hasEditRole"
                                          :readonly="!hasEditRole" :items="hazardousSubstanceClassifications.items" v-model="batterySubstance.references.hazardousSubstanceClassification" :return-object="false" filled
                                          :rules="[v => v && !!v.trim() || $t('battery-substance-editor.view.fields.schema.validation.notEmpty')]"
                                          :chips="false"
                                          :label="$t('battery-substance-wizard.view.subheader.hazardousSubstanceClassification')">HazardousSubstanceClassifications</c-item-selector>
                                        </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
        </c-item-editor-form>
    </v-sheet>
</template>

<script>
import { cloneDeep, get } from 'lodash';

import BatterySubstanceEditorModule from "../../../store/masterdata/battery-substances/battery-substances-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'BatterySubstanceEditor';

export default {

    name: 'battery-substance-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],



    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/saveData");
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
      this.$router.push({
        name: "BatterySubstanceListView",
      });
      }
    },

    computed: {

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.BATTERYSUBSTANCE_EDIT);
        },

        hasReadRole() {
            return this.userContextService.hasRole(Roles.HAZARDOUSSUBSTANCECLASSIFICATION_READ);
        },

        batterySubstance: {

            get() {
                return this.$store.getters[MODULE_ID + '/batterySubstance'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatterySubstance", value);
            }
        },

        hazardousSubstanceClassification: {
            get() {
                return this.$store.getters[MODULE_ID + '/hazardousSubstanceClassification'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateHazardousSubstanceClassification", value);
            }

        },
        

        batterySubstancesId: {

set(item) {
    this.$store
        .dispatch(MODULE_ID + "/updateBatterySubstances", item)
        .catch(this.errorHandlerService.handleError);
},
},
        
hazardousSubstanceClassifications() {
    return this.$store.getters[MODULE_ID + "/hazardousSubstanceClassifications"];
},
hazardousSubstanceClassificationId: {
    get() {
        return this.$store.getters[MODULE_ID + "/hazardousSubstanceClassificationId"];
    },
    

    set(item) {
        this.$store
            .dispatch(MODULE_ID + "/updateHazardousSubstanceClassificationId", item)
            .catch(this.errorHandlerService.handleError);
    },
},
        model() {
            return cloneDeep(this.batterySubstance);
        }

    },

    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatterySubstanceEditorModule);
        }

        this.refresh();

    },
  destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_ID)) {
        this.$store.unregisterModule(MODULE_ID);
        this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
    }
  }
}
</script>g

<i18n>
   
{
    "en" : {
        "battery-substance-editor.view.title" : "Battery Substance {name}",

        "battery-substance-editor.view.tabs.general" : "General",
        "battery-substance-editor.view.tabs.settings" : "Settings",
        "battery-substance-editor.view.tabs.json" : "JSON",
        "battery-substance-editor.view.component.title":"Settings",
        "battery-substance-editor.view.component.subtitle": "This page contains settings for the selected Battery Substance.",
        "battery-substance-editor.view.sections.hazardousSubstanceClassification.title":"Hazardous Substance Classification",
        "battery-substance-editor.view.sections.hazardousSubstanceClassification.subtitle" : "Here you assign the Battery Substance the respective Hazardous Substance Classification.",
        "battery-substance-editor.view.fields.schema.validation.notEmpty":"This field cannot be empty.",
        "battery-substance-wizard.view.subheader.hazardousSubstanceClassification" : "Hazardous Substance Classification"
    },

    "de" : {
        "battery-substance-editor.view.title" : "Batterie-Element {name}",
        "battery-substance-editor.view.tabs.general" : "Allgemein",
        "battery-substance-editor.view.tabs.settings" : "Einstellungen",
        "battery-substance-editor.view.tabs.json" : "JSON",
        "battery-substance-editor.view.component.title":"Einstellungen",
        "battery-substance-editor.view.component.subtitle": "Diese Seite beinhaltet Einstellungen für das ausgewählte Batterie-Element.",
        "battery-substance-editor.view.fields.schema.validation.notEmpty":"Dieses Feld kann nicht leer sein.",
        "battery-substance-editor.view.sections.hazardousSubstanceClassification.title":"Gefahrenstoffklasse",
        "battery-substance-editor.view.sections.hazardousSubstanceClassification.subtitle" : "Hier weisen Sie dem Batterie-Element die zugehörige Gefahrenstoffklasse zu.",
        "battery-substance-wizard.view.subheader.hazardousSubstanceClassification" : "Gefahrenstoffklasse"
    }
}
</i18n>