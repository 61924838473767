import api from '../../../api/battery-data-hub.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        safetyInstruction: createEmptySafetyInstruction(),
        severities: { items: [] },
        duobleCheck:[],
        tenantId: null
    },

    mutations: {

        setSafetyInstruction(state, safetyInstruction) {

            if (safetyInstruction) {

                state.safetyInstruction.tenantId = safetyInstruction.tenantId ? safetyInstruction.tenantId : null;
                state.safetyInstruction.name = safetyInstruction.name ? safetyInstruction.name : null;
                state.safetyInstruction.i18n = safetyInstruction.i18n ? safetyInstruction.i18n : {};
                state.safetyInstruction.data = safetyInstruction.data ? safetyInstruction.data : { details: {} };
                state.safetyInstruction.active = safetyInstruction.active ? safetyInstruction.active : true;
                state.safetyInstruction.references = safetyInstruction.references ? safetyInstruction.references : {severity: "Mittel"};

            } else {
                state.safetyInstruction.tenantId = null;
                state.safetyInstruction.name = null;
                state.safetyInstruction.i18n = null;
                state.safetyInstruction.data = null;
                state.safetyInstruction.active = null;
                state.safetyInstruction.references = null;
            }
        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        },
        setSeverities(state, severities) {
            if (severities) {
                let items = [];
                if (severities.items) {
                    state.severities.items = severities.items;
                }
                else {
                    state.severities.items = items;
                }
            } else {
                state.severities.items = [];
            }
        },
        setDoubleCheck(state, severities) {
            if (severities) {
                let items = [];
                if (severities.items) {
                    state.duobleCheck = severities.items;
                }
                else {
                    state.duobleCheck = items;
                }
            } else {
                state.duobleCheck = [];
            }
        },
        setSeverity(state, severity) {
            state.safetyInstruction.references.severity = severity;
        },


    },

    actions: {
        async loadData(context) {
            try {
                let items = await api.masterdata.severity.findByFilter('*', 0, 100);
                await context.commit('setSeverities', items);
                let itemsD = await api.masterdata.safetyInstructions.findByFilter('*', 0, 100);
                await context.commit('setDoubleCheck', itemsD);
                 } catch (error) {
                await context.commit('setSeverities', {items:[]});
                context.commit('setSafetyInstruction', createEmptySafetyInstruction());
                throw error;
            }
        },
        async updateSeverity(context, severity) {
            await context.commit('setSeverity', severity);
        },
        async createSafetyInstruction(context, locale) {

            try {
                const t = await context.getters.duobleCheck;
                console.log(t);
                for(let i in t){
                    console.log(context.getters.request.name);
                    console.log(i);
                    if(context.getters.request.name === t[i].name){
                        
                        throw new Error(locale === "en"?"this name already exists":"Dieser Name existiert bereits");
                    }}
                let request = context.getters.request;
                await api.masterdata.safetyInstructions.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetSafetyInstruction(context) {
            context.commit('setSafetyInstruction', createEmptySafetyInstruction());
        },

        async updateSafetyInstruction(context, safetyInstruction) {

            try {
                context.commit('setSafetyInstruction', safetyInstruction);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {
        duobleCheck(state){
            return state.duobleCheck;
        },
        request(state) {
            let payload = state.safetyInstruction;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        safetyInstruction(state) {
            return state.safetyInstruction ? state.safetyInstruction : createEmptySafetyInstruction();
        },
        severity(state) {
            return state.safetyInstruction.references.severity;
        },
        severities(state) {
            return state.severities.items;
        }

}
}

function createEmptySafetyInstruction() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "details":{}
        },
        "active": true,
        "references": {
            severity: null
        }

    }
}