<template>
    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog 
                    :value="workplace || {}" 
                    :title="$t('workplace-wizard.view.title')"
                    :subtitle="$t('workplace-wizard.view.subtitle')" color="secondary" @dialog-saved="save"
                    @dialog-canceled="cancel">
                    <template v-slot:default="props">
                        <v-card flat>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <c-name-input v-model="props.item.name" filled />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="6">

                                        <c-item-selector :items="sites.items" v-model="siteId" :return-object="false" filled
                                            :chips="false" :label="$t('workplace-wizard.view.subheadder.sites')"
                                            :rules="[v => v && !!v.trim() || $t('workplace-wizard.view.siteId.validation.notEmpty')]" />
                                    </v-col>
                                    <v-col cols="12" lg="6">

                                        <c-item-selector :items="workplaceTypes.items" v-model="workplaceTypeId"
                                            :label="$t('workplace-wizard.view.subheadder.workplacetypes')"
                                            :rules="[v => v && !!v.trim() || $t('workplace-wizard.view.workplacetypeId.validation.notEmpty')]"
                                            :return-object="false" filled :chips="false" />

                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                </c-base-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import WorkplaceWizardModule from "../../../store/masterdata/workplaces/workplaces-wizard.module";

const MODULE_NAME = "workplacesWizard";

export default {
    name: "workplaces-wizard",

    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "envContextService",
    ],

    methods: {
        async save() {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + "/createWorkplace");

                const workplaceName = this.workplace.name;
                this.reset();

                this.$router.push({
                    name: "WorkplaceEditorView",
                    params: { name: workplaceName },
                });

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.reset();
            this.$router.push({
                name: "WorkplaceListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        async reset() {
            this.progressIndicatorService.show();

            try {
                this.$store.dispatch(MODULE_NAME + "/resetWorkplace");
                this.setTenantId(this.tenantId);
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    computed: {

        siteId: {
            get() {
                return this.$store.getters[MODULE_NAME + "/siteId"];
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_NAME + "/updateSiteId", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },

        workplaceTypeId: {
            get() {
                return this.$store.getters[MODULE_NAME + "/workplaceTypeId"];
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_NAME + "/updateWorplaceTypeId", item)
                    .catch(this.errorHandlerService.handleError);

            }
        },
        workplace: {
            get() {
                return this.$store.getters[MODULE_NAME + "/workplace"] || {};
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_NAME + "/updateWorkplace", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },

        sites() {
            return this.$store.getters[MODULE_NAME + "/sites"] || { items: [] };
        },

        workplaceTypes() {
            return this.$store.getters[MODULE_NAME + "/workplaceTypes"] || { items: [] };
        },



        tenantId() {
            return this.envContextService.tenantId;
        },




    },
    async beforemount() {
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, WorkplaceWizardModule);
        }
        console.log("unmounting")
        this.reset();
    },

    async created() {
        if (!this.$store.hasModule(MODULE_NAME)) {
        this.$store.registerModule(MODULE_NAME, WorkplaceWizardModule);//todo
    }
        this.reset();
        await this.$store.dispatch(MODULE_NAME + "/loadSitesAndWorkplaceTypes");
    },
    destroyed() {
        // Unregister the vuex store if it has been registered before.
        if (this.$store.hasModule(MODULE_NAME)) {
            this.$store.unregisterModule(MODULE_NAME);
            this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
        }
    }
};
</script>
  
<i18n>
  {
      "en" : {
          "workplace-wizard.view.title" : "Create New Workplace",
          "workplace-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Workplace.",
          "workplace-wizard.view.subheadder.sites" : "Site",
          "workplace-wizard.view.subheadder.workplacetypes" : "Workplace Type",
          "workplace-wizard.view.workplacetypeId.validation.notEmpty": "Please assign a Workplace Type.",
          "workplace-wizard.view.siteId.validation.notEmpty": "Please assign a Site."
      },
  
      "de" : {
          "workplace-wizard.view.title" : "Neuen Arbeitsplatz anlegen",
          "workplace-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Arbeitsplatzes.",
          "workplace-wizard.view.subheadder.sites" : "Produktionsstandort",
          "workplace-wizard.view.subheadder.workplacetypes" : "Arbeitsplatztyp",
          "workplace-wizard.view.workplacetypeId.validation.notEmpty" : "Bitte wählen sie einen Arbeitsplatztyp aus.",
          "workplace-wizard.view.siteId.validation.notEmpty": "Bitte wählen sie einen Produktionsstandort aus."
      }
  }
  </i18n>
  
  
  