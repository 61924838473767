<template>
    <div>
        <c-query-configuration-panel 
            v-model="selectedListTemplate" 
            @list-settings-changed="refresh"
            @list-settings-reset="onResetTemplate"
            @save-list-settings="onSaveListTemplate"
            @save-tenant-list-settings="onSaveTenantListTemplate"
            :collection-settings="collectionSettings"
            :schema="schema"
            :templates="listTemplates"
            :enableSaveTenantListTemplate="hasTenantListTemplatesPermissions"
            :disabled="false"
            :title="$t('collection-documents.view.results', {totalItemCount: totalItemCountFormatted})"
            :hide-view-settings="hideSelectViewType"
            >

            <!-- Extend Toolbar -->
            <template v-slot:toolbar-items-prepend="{disabled}">
                
                <v-btn icon @click="refresh" :disabled="disabled">
                    <v-icon>refresh</v-icon>
                </v-btn>


                <!-- View Type -->
                <v-menu top v-if="!hideSelectViewType">
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" :disabled="disabled">
                            <v-icon v-if="isViewTypeTable">table_view</v-icon>
                            <v-icon v-if="isViewTypeJsonList">data_object</v-icon>
                            <v-icon v-if="isViewTypeTile">grid_view</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="setViewTypeTable">
                            <v-list-item-icon><v-icon>table_view</v-icon></v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.table')}}</v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item @click="setViewTypeTile">
                            <v-list-item-icon><v-icon>grid_view</v-icon></v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.tiles')}}</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="setViewTypeJsonList">
                            <v-list-item-icon><v-icon>data_object</v-icon></v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.list')}}</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-subheader>{{$t('collection-documents.view.actions.view.fields.title')}}</v-subheader>
                        <v-list-item @click="showFieldName = !showFieldName">
                            <v-list-item-icon>
                                <v-icon v-if="showFieldName">check_box</v-icon>
                                <v-icon v-else>check_box_outline_blank</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.fields.toggleName')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showFieldDocumentId = !showFieldDocumentId">
                            <v-list-item-icon>
                                <v-icon v-if="showFieldDocumentId">check_box</v-icon>
                                <v-icon v-else>check_box_outline_blank</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.fields.toggleDocumentId')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showFieldRowNumber = !showFieldRowNumber">
                            <v-list-item-icon>
                                <v-icon v-if="showFieldRowNumber">check_box</v-icon>
                                <v-icon v-else>check_box_outline_blank</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.fields.toggleRowNumber')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showFieldModificationDateTime = !showFieldModificationDateTime">
                            <v-list-item-icon>
                                <v-icon v-if="showFieldModificationDateTime">check_box</v-icon>
                                <v-icon v-else>check_box_outline_blank</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.fields.toggleModificationDateTime')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showFieldModificationUserId = !showFieldModificationUserId">
                            <v-list-item-icon>
                                <v-icon v-if="showFieldModificationUserId">check_box</v-icon>
                                <v-icon v-else>check_box_outline_blank</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.fields.toggleModificationUserId')}}</v-list-item-title>
                        </v-list-item>
                        <!-- Developer Features -->
                        
                        <v-template v-if="devModeOn">
                            <v-divider />
                            <v-list-item @click="showJsonModelDialog = !showJsonModelDialog">
                            <v-list-item-icon>
                                <v-icon>code</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{$t('collection-documents.view.actions.view.fields.jsonModel')}}</v-list-item-title>
                        </v-list-item>
                        </v-template>
                        

                    </v-list>
                </v-menu>
            </template>
            
            <!-- Extend Toolbar -->
            <template v-slot:toolbar-items-append="{disabled}">
                
                <v-menu top>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" :disabled="!hasExportPermission || disabled">
                            <v-icon>cloud_download</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="startDocumentExport('XLSX')">
                            <v-list-item-avatar><v-icon>cloud_download</v-icon></v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{$t('collection-documents.view.actions.export.xls', $i18n.locale)}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="startDocumentExport('CSV')">
                            <v-list-item-avatar><v-icon>cloud_download</v-icon></v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{$t('collection-documents.view.actions.export.csv', $i18n.locale)}}</v-list-item-title>
                                <v-list-item-subtitle>{{$t('collection-documents.view.actions.export.csv.charset.utf-8', $i18n.locale)}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="startDocumentExport('CSV', 'windows-1252')">
                            <v-list-item-avatar><v-icon>cloud_download</v-icon></v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{$t('collection-documents.view.actions.export.csv', $i18n.locale)}}</v-list-item-title>
                                <v-list-item-subtitle>{{$t('collection-documents.view.actions.export.csv.charset.windows-1252', $i18n.locale)}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="startDocumentExport('JSON')">
                            <v-list-item-avatar><v-icon>cloud_download</v-icon></v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{$t('collection-documents.view.actions.export.json', $i18n.locale)}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                
                
            </template>
            

            <!-- Configure Content -->
            <template v-slot:content>
          
                <template v-if="formSubmitted">
                    <slot name="content-items" v-bind:items="items" v-bind:pagination="pagination" v-bind:collectionSettings="collectionSettings" v-bind:schema="schema">                   
                        <c-query-result-data-panel
                            :value="{items, pagination, schema: schema}" 
                            :view-type="viewType"
                            :view-settings="viewSettings"
                            :loading="loading"
                            :hideRowNumber="!showFieldRowNumber"
                            :hideDocumentId="!showFieldDocumentId"
                            :hideDocumentName="!showFieldName"
                            :hideModificationDateTime="!showFieldModificationDateTime"
                            :hideModificationUserId="!showFieldModificationUserId"
                            @open-item="onOpenClicked"
                           
                        >
                            <!-- Custom Columns for Table Mode -->
                            <template v-slot:content-items-table-header-actions>
                                <th class="text-center" style="width: 100px;">{{$t('collection-documents.view.columns.delete')}}</th>
                                <th class="text-center" style="width: 100px;">{{$t('collection-documents.view.columns.changeLog')}}</th>
                            </template>
                            <template v-slot:content-items-table-body-actions="{item}">
                                <td class="text-center" style="width: 100px;">
                                    <v-btn icon @click="onDeleteClicked(item)" :disabled="!hasDeletePermission"><v-icon small>delete</v-icon></v-btn>
                                </td>
                                <td class="text-center" style="width: 100px;">
                                    <v-btn icon @click="openChangeLog(item)"><v-icon small>history</v-icon></v-btn>
                                </td>
                            </template>

                        </c-query-result-data-panel>
                    </slot>
                    <v-divider />
                    <slot name="content-pagination" v-bind:items="items" v-bind:pagination="pagination" v-bind:collectionSettings="collectionSettings" v-bind:schema="schema">
                        <c-query-result-pagination-panel
                            :items="items"
                            :pagination="pagination"
                             @load-next-page="onLoadMore"
                        />
                    </slot>

                </template>
                
                <template v-else>
                    <dxs-send-query-hint-banner />
                </template>
            </template>
        </c-query-configuration-panel>


        <v-dialog v-model="showJsonModelDialog" fullscreen>
            <v-card flat>

                <v-toolbar flat>
                    <v-toolbar-items>
                        <v-btn icon @click="showJsonModelDialog = false"><v-icon>close</v-icon></v-btn>
                    </v-toolbar-items>
                    <v-toolbar-title>JSON Model</v-toolbar-title>
                </v-toolbar>
                <v-divider />

                <v-card-text class="grey lighten-3">
                    <pre>{{JSON.stringify(documentListModel, true, 2)}}</pre>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- ====================================================== -->
        <!-- DELETE DIALOG                                          -->
        <!-- ====================================================== -->
        <v-dialog v-model="showDocumentDeleteDialog" width="480">
            <v-card tile>
                <v-toolbar flat>
                    <v-toolbar-title>{{$t('collection-documents.dialogs.delete.title')}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-5">
                  {{$t('collection-documents.dialogs.delete.description')}}
                </v-card-text>
                <v-card-actions class="text-right">
                    <v-btn @click="onConfirmDeletion" text color="error">{{$t('collection-documents.dialogs.actions.confirm')}}</v-btn>
                    <v-btn @click="onCancelDeletion" text color="grey" >{{$t('collection-documents.dialogs.actions.cancel')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { isEmpty, get } from 'lodash';
import { newInstance as listTemplateDataMixin }  from '@/core/mixins/list-template-data/list-template-data.mixin';
import { newInstance as collectionSettingsDataMixin }   from '@/core/mixins/collection-settings-data/collection-settings-data.mixin';
import { newInstance as collectionDocumentsDataMixin }   from '@/core/mixins/collection-documents-data/collection-documents-data.mixin';
import { newInstance as devModeMixin }  from '@/core/mixins/dev-mode/dev-mode.mixin';

import Roles from '@/roles';

import SendQueryHintBannerComponent from '@/core/components/collections/send-query-hint-banner.component';

const VIEW_TYPE_TILE = "tile";
const VIEW_TYPE_LIST = "VIEW_TYPE_LIST";
const VIEW_TYPE_JSON_LIST = "jsonlist";
const VIEW_TYPE_TABLE = "table";

export default {

    name: 'collection-documents',

    components: {
        'dxs-send-query-hint-banner' : SendQueryHintBannerComponent
    },

    props: {
    
        value: {
            type: Object,
            required: false,
        },

        options: {
            type: Object,
            required: false
        },

        hideSelectViewType: {
            type: Boolean,
            required: false
        }
    },



    data: () => ({
        submitted: false,
        height: 600,
        viewType: VIEW_TYPE_TABLE,
        toggleDisplayModeGroup: 0,
        showDeleteConfirmation: false,
        querySummary: false,

        // Show JSON Model for debugging
        showJsonModelDialog: false,

        // Show delete dialog
        showDocumentDeleteDialog: false,
        selectedIdForDeletion: null,

        // Optional Fields
        showFieldName: true,
        showFieldDocumentId: false,
        showFieldRowNumber: false,
        showFieldModificationDateTime : true,
        showFieldModificationUserId : true,
    }),

    mixins: [
        collectionSettingsDataMixin({moduleId: 'collections-documents-collectionSettings'}),
        listTemplateDataMixin({moduleId: 'collections-documents-listTemplates'}),
        collectionDocumentsDataMixin({moduleId: 'collections-documents-documentList'}),
        devModeMixin()
        
    ],

    inject: ['shellState', 'errorHandlerService', 'messageService', 'progressIndicatorService', 'userContextService', 'collectionPermissionService'],

    watch: {
        
        value: {
            deep: true,
            async handler(newValue, oldValue) {
                
                // Only re-initialize the dialog, when a new collection has been selected.
                if (newValue.collectionId !== oldValue.collectionId) {
                    this.$log.debug(`Refreshing view: ${newValue.collectionId} -> ${newValue.collectionId}`);
                    await this.initView(newValue);
                } else {
                    this.$log.debug(`No refresh was triggered as ${newValue.collectionId} is still the same as before.`);
                }
                
            }
        }

        // TODO remove old value.
    },

    methods: {
        
        /**
         * Initializes the view, e.g. by loading masterdata.
         */
        async initView( {collectionId, listTemplate} ) {

            this.$log.debug(`Initializing view for collection ${collectionId} and list settings ${JSON.stringify(listTemplate)}`);

            this.progressIndicatorService.show();

            try {

                //await this.$store.dispatch(MODULE_NAME + '/resetView');

                //===================================================================================
                // Reset previously loaded Documents
                //===================================================================================
                this.resetDocuments();
                
                //===================================================================================
                // Load Metadata
                //===================================================================================
                await this.loadCollectionSettings(collectionId);
                await this.loadListTemplates(this.collectionId);
                
                
                //===================================================================================
                // Init List Template Configuration based on Metadata
                //===================================================================================

                if (isEmpty(listTemplate)) {
                    // Use a default template in case the parameter has not been set.
                    const emptyTemplate = this.createEmptyListTemplate(this.schema);
                    this.selectedListTemplate  = emptyTemplate;
                } else {
                    this.selectedListTemplate = listTemplate;
                    await this.refresh();
                }

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
                this.submitted = false;
            }

        },

        async refresh() {
            this.loadDocuments(this.collectionId, this.selectedListTemplate);
        },

        async onLoadMore() {
            this.loadMoreDocuments(this.collectionId, this.selectedListTemplate);
        },

        setViewType(viewType) {
            this.viewType = viewType;
        },

        setViewTypeTile() {
            this.setViewType(VIEW_TYPE_TILE);
        },

        setViewTypeList() {
            this.setViewType(VIEW_TYPE_LIST);
        },

        setViewTypeJsonList() {
            this.setViewType(VIEW_TYPE_JSON_LIST);
        },

        setViewTypeTable() {
            this.setViewType(VIEW_TYPE_TABLE);
        },

        onOpenClicked(event) {
            this.goToDetailsView({
                collectionSettings: this.collectionSettings,
                schema: this.schema,
                item: event.item
            });
        },

        onEditClicked(event) {
            this.goToDetailsView({
                collectionSettings: this.collectionSettings,
                schema: this.schema,
                item: event.item
            });
        },

        onDeleteClicked(document) {

            // Delete this item.
            //this.$log.debug(JSON.stringify(event));
            this.selectedIdForDeletion = document.id;

            this.showDocumentDeleteDialog = true;
        },

        onConfirmDeletion() {

            this.showDocumentDeleteDialog = false;
            this.deleteItem(this.selectedIdForDeletion).finally(() => this.selectedIdForDeletion = null);

        },

        /**
         * Deletes the given document id.
         */
        async deleteItem(documentId) {
            
            this.$log.debug(`Deleting the document with id ${documentId}`);

            // If the document could be deleted, also refresh the view in order to show a consistent behaviour.
            if (await this.deleteDocumentById(this.collectionId, documentId)) {
                await this.refresh();
                this.messageService.show({
                    title: this.$t('collection-documents.view.messages.deleteDocument.success.title', this.$i18n.locale),
                    text: this.$t('collection-documents.view.messages.deleteDocument.success.message', this.$i18n.locale)
                });

                 this.$log.debug(`Document with id ${documentId} was deleted successful.`);
            } else {

                this.messageService.show({
                    title: this.$t('collection-documents.view.messages.deleteDocument.error.title', this.$i18n.locale),
                    text: this.$t('collection-documents.view.messages.deleteDocument.error.message', this.$i18n.locale)
                });

                this.$log.debug(`Document with id ${documentId} could not be deleted.`);
            }
        } ,

        onCancelDeletion() {
            this.selectedIdForDeletion = null;
            this.showDocumentDeleteDialog = false;

        },

        openChangeLog(item) {

            // Currently there is no elegant way of opening views in new tabs :-(
            const route = this.$router.resolve('/collections/' + this.collectionId + '/documents/' + item.id + '/changelog'  );
            window.open(route.href);

        },

        onDeleteCollection() {
            this.deleteAll();
        },

        goToDetailsView({collectionSettings, item}) {

            // This method relies on the client settings attached to the given collection.
            const pathDetailView = this.$dxs.collectionSettings.getDetailViewUrl(collectionSettings, item);

            // Unfortunatetly there is currently not better way to open new tabs in vue router.
            const route = this.$router.resolve(pathDetailView);
            window.open(route.href);

        },


        async onResetTemplate() {
            
            this.selectedListTemplate = this.createEmptyListTemplate(this.schema);
            this.refresh();

        }, 

        async onSaveListTemplate(template){

            // Delegate actual saving to the mixin.
            const result = await this.saveListTemplate(this.userId, this.collectionId, template);

            // Open the dialog with the saved template in a new tab.
            const name = get(result, 'data.template.name');
            if (name) {

                //this.$router.push({ name: 'myUserListTemplatesEditor', params: {name: name}});
                const route = this.$router.resolve({ name: 'myUserListTemplatesEditor', params: {name: name}});
                window.open(route.href);
            }
        },

        async onSaveTenantListTemplate(template){

            // Delegate actual saving to the mixin.
            const result = await this.saveTenantListTemplate(this.collectionId, template);

            // Open the dialog with the saved template in a new tab.
            const name = get(result, 'data.template.name');
            if (name) {
                const route = this.$router.resolve({ name: 'tenantListTemplatesEditor', params: {name: name}});
                window.open(route.href);
            }
        },

        async startDocumentExport(format, charset='utf-8') {
            try {
                await this.exportDocuments(this.collectionId, this.selectedListTemplate, format, charset);
                this.messageService.show({
                        title: this.$t('collection-documents.view.messages.export.success.title', this.$i18n.locale),
                        text: this.$t('collection-documents.view.messages.export.success.message', this.$i18n.locale)
                    });
                    
            } catch (error) {

                this.$log.error(error);
                this.messageService.show({
                        title: this.$t('collection-documents.view.messages.export.error.title', this.$i18n.locale),
                        text: this.$t('collection-documents.view.messages.export.error.message', this.$i18n.locale)
                    });
            }




            
        }

        

    },

    computed: {

        // =============== Context

        collectionId() {
            return this.value ? this.value.collectionId : null;
        },

        userId() {
            return this.userContextService.userName;
        }, 

        // =============== Permissions

        hasExportPermission() {
            return this.collectionPermissionService.hasExportPermission({collectionId: this.collectionId});
        },

        hasDeletePermission() {
            return this.collectionPermissionService.hasDeletePermission({collectionId: this.collectionId});
        },

        hasTenantListTemplatesPermissions() {
            let roles = [Roles.TENANT_LIST_TEMPLATES_READ, Roles.TENANT_LIST_TEMPLATES_EDIT, Roles.TENANT_LIST_TEMPLATES_CREATE];
            return this.userContextService.hasAllRoles(roles);
        },
        
        // =============== View Types
        isViewTypeTile() {
            return this.viewType === VIEW_TYPE_TILE;
        },

        isViewTypeList() {
            return this.viewType === VIEW_TYPE_LIST;
        },

        isViewTypeJsonList() {
            return this.viewType === VIEW_TYPE_JSON_LIST;
        },

        isViewTypeTable() {
            return this.viewType === VIEW_TYPE_TABLE;
        },

        // =============== Dialog State

        loading() {
            return this.shellState.loading;
        },

        formSubmitted() {
            return this.submitted || this.items.length > 0;
        }
    },

    async created() {
        this.$log.debug(`Creating View for ${JSON.stringify(this.value)}`);
        await this.initView(this.value);
    }
}
</script>

<i18n>
{
    "en" : {
        "collection-documents.view.results" : "Results ({totalItemCount} Items)",
        "collection-documents.view.results.noMoreResults" : "You are currently viewing all {totalItemCount} items matching your search query.",
        "collection-documents.view.results.adjustFilterMoreResults" : "In order to display additional items, refine your filter settings, please. ",

        "collection-documents.view.actions.view.table" : "Display as Table",
        "collection-documents.view.actions.view.tiles" : "Display as Tiles",
        "collection-documents.view.actions.view.list" : "Display as JSON",


        "collection-documents.view.actions.view.fields.title" : "Additional Fields",
        "collection-documents.view.actions.view.fields.toggleName" : "Document Name",
        "collection-documents.view.actions.view.fields.toggleDocumentId" : "Document ID",
        "collection-documents.view.actions.view.fields.toggleRowNumber" : "Row Number",
        "collection-documents.view.actions.view.fields.toggleModificationDateTime" : "Change Date",
        "collection-documents.view.actions.view.fields.toggleModificationUserId" : "Changed By",
        "collection-documents.view.actions.view.fields.jsonModel" : "JSON Model (Dev Mode)",

        "collection-documents.view.actions.export.csv" : "Download CSV",
        "collection-documents.view.actions.export.csv.charset.utf-8" : "Charset: UTF-8",
        "collection-documents.view.actions.export.csv.charset.windows-1252" : "Charset: Windows 1252 (for Excel)",
        "collection-documents.view.actions.export.xls" : "Download Excel",
        "collection-documents.view.actions.export.json" : "Download JSON",

        
        "collection-documents.view.actions.bookmark.save" : "Save List Template",
        "collection-documents.view.actions.bookmark.saveAs" : "Save List Templates as ...",

        "collection-documents.view.columns.changeLog" : "Change Log",
        "collection-documents.view.columns.delete" : "Delete",

        "collections-documents.view.noData.title" : "Where is my Data?",
        "collections-documents.view.noData.subtitle" : "Your data is only a few clicks away. Please enter a filter expression and then press Search in order to display available documents.",
        "collections-documents.view.noData.hint.title" : "Hint",
        "collections-documents.view.noData.hint.text" : "Use the expression * in order to search for all documents without applying filter conditions.",

        "collection-documents.dialogs.delete.title" : "Confirm Deletion",
        "collection-documents.dialogs.actions.confirm" : "Confirm",
        "collection-documents.dialogs.actions.cancel" : "Cancel",
        "collection-documents.dialogs.delete.description" : "Please confirm this operation in order to delete the document from the system permanently. Please note that a deletion is final and cannot be undone.",

        "collection-documents.dialogs.template.title" : "Liste Templates",
        "collection-documents.dialogs.template.save.title": "Save Template",
        "collection-documents.dialogs.template.save.description": "Please provide a name for the template and confirm to save the current filter-, view- and sort-settings settings. You can access saved templates in the \"Templates\" menu.",
        "collection-documents.dialogs.template.save.name": "Template Name",
        "collection-documents.dialogs.template.summary.title" : "Summary of Your Current Settings",

        "collection-documents.view.messages.export.success.title" : "Export Started",
        "collection-documents.view.messages.export.success.message" : "The export job was started successfully and can be downloaded later.",

        "collection-documents.view.messages.export.error.title" : "Export Failed",
        "collection-documents.view.messages.export.error.message" : "The export job could not be started due to an unexpected error.",

        "collection-documents.view.messages.deleteDocument.success.title" : "Document was Deleted",
        "collection-documents.view.messages.deleteDocument.success.message" : "The document was deleted successfully. You view has been automatically refreshed.",

        "collection-documents.view.messages.deleteDocument.error.title" : "Document could not be deleted",
        "collection-documents.view.messages.deleteDocument.error.message" : "The document could not be deleted. Please ask your administrator to check the error log for further analysis."
    },

    "de" : {
        "collection-documents.view.results" : "Ergebnis ({totalItemCount} Ergebnisse)",
        "collection-documents.view.results.noMoreResults" : "Sie sehen alle {totalItemCount} Ergebnisse, die zu Ihrer Filterung passen.",
        "collection-documents.view.results.adjustFilterMoreResults" : "Bitte passen Sie Ihre Filterung an, um zusätzliche Ergebnisse anzuzeigen.",

        "collection-documents.view.actions.view.table" : "Als Tabelle anzeigen",
        "collection-documents.view.actions.view.tiles" : "Als Kacheln anzeigen",
        "collection-documents.view.actions.view.list" : "Als JSON anzeigen",

        "collection-documents.view.actions.view.fields.title" : "Zusatzfelder",
        "collection-documents.view.actions.view.fields.toggleName" : "Bezeichnung Dokument",
        "collection-documents.view.actions.view.fields.toggleDocumentId" : "Dokumenten-ID",
        "collection-documents.view.actions.view.fields.toggleRowNumber" : "Zeilennummer",
        "collection-documents.view.actions.view.fields.toggleModificationDateTime" : "Geändert am",
        "collection-documents.view.actions.view.fields.toggleModificationUserId" : "Geändert von",

        "collection-documents.view.actions.export.csv" : "Als CSV herunterladen",
        "collection-documents.view.actions.export.csv.charset.utf-8" : "Zeichensatz: UTF-8",
        "collection-documents.view.actions.export.csv.charset.windows-1252" : "Zeichensatz: Windows 1252 (für Excel)",
        "collection-documents.view.actions.export.xls" : "Als Excel herunterladen",
        "collection-documents.view.actions.export.json" : "Als JSON herunterladen",

        "collection-documents.view.actions.bookmark.save" : "Listenvorlage speichern",
        "collection-documents.view.actions.bookmark.saveAs" : "Listenvorlage speichern als ...",

        "collection-documents.view.columns.changeLog" : "Historie",
        "collection-documents.view.columns.delete" : "Löschen",

        "collections-documents.view.noData.title" : "Wo sind meine Daten?",
        "collections-documents.view.noData.subtitle" : "Ihre Dokumente sind nur einen Knopfdruck entfernt: Bitte geben Sie einen Suchausdruck ein und drücken Sie anschließend die Schaltfläche Suchen um die Dokumente anzuzeigen.",
        "collections-documents.view.noData.hint.title" : "Hinweise",
        "collections-documents.view.noData.hint.text" : "Verwenden Sie den Suchausdruck * um alle Dokumente ohne Einschränkung zu suchen.",

        "collection-documents.dialogs.delete.title" : "Löschen bestätigen",
        "collection-documents.dialogs.actions.confirm" : "Bestätigen",
        "collection-documents.dialogs.actions.cancel" : "Abbrechen",
        "collection-documents.dialogs.delete.description" : "Bitte bestätigen Sie den Vorgang, um das Dokument dauerhaft aus dem  System zu löschen. Bitte beachten Sie, dass eine Löschung endgültig ist und nicht rückgängig gemacht werden kann.",

        "collection-documents.dialogs.template.title" : "Listenvorlagen",
        "collection-documents.dialogs.template.save.title": "Wollen Sie Ihre Einstellungen als Listenvorlage speichern?",
        "collection-documents.dialogs.template.save.description": "Sie können Ihre derzeitigen Filter-, Ansicht- und Sortiereinstellungen unter einem frei wählbaren Namen als Listenvorlage speichern und zu einem späteren Zeitpunkt als Voreinstellung wiederverwenden.",
        "collection-documents.dialogs.template.save.name": "Name der Listenvorlage",
        "collection-documents.dialogs.template.summary.title" : "Zusammenfassung Ihrer aktuellen Einstellungen",

        "collection-documents.view.messages.export.success.title" : "Export gestartet",
        "collection-documents.view.messages.export.success.message" : "Der Export-Auftrag wurde erfolgreich gestartet und kann später heruntergeladen werden.",

        "collection-documents.view.messages.export.error.title" : "Export fehlgeschlagen",
        "collection-documents.view.messages.export.error.message" : "Der Export-Auftrag konnte auf Grund eines unerwarteten Fehlers nicht gestartet werden.",

        "collection-documents.view.messages.deleteDocument.success.title" : "Dokument erfolgreich gelöscht",
        "collection-documents.view.messages.deleteDocument.success.message" : "Das Dokument wurde gelöscht. Ihre Ansicht wurde automatisch aktualisiert.",

        "collection-documents.view.messages.deleteDocument.error.title" : "Dokument konnte nicht gelöscht werden",
        "collection-documents.view.messages.deleteDocument.error.message" : "Das Dokument konnte nicht gelöscht werden. Bitte wenden Sie sich an Ihren Systembetreuer, um das Fehlerprotokoll zu überprüfen."
    }
}
</i18n>