<template>
  <v-container fluid class="secondary pt-10 pb-10">
      <v-row justify="center">
          <v-col cols="12" lg="9">
              <c-base-dialog :value="manufacturerplants" :title="$t('manufacturerplants-wizard.view.title')"
                  :subtitle="$t('manufacturerplants-wizard.view.subtitle')" color="secondary"
                  @dialog-saved="save"
                  @dialog-canceled="cancel">
                  <template v-slot:default="props">
                      <v-card flat>
                          <v-card-text>
                              <v-row>
                                  <v-col cols="12">
                                      <c-name-input v-model="props.item.name" filled />
                                  </v-col>
                              </v-row>
                              <v-row> 
                                  <v-col cols="12">

                                      <c-item-selector :items="manufacturer.items" v-model="manufacturerId" :return-object="false" filled
                                          :chips="false"
                                          :rules="[  v => v && !!v.trim()  || $t('manufacturer-wizard.view.fields.schema.validation.notEmpty') ]"
                                          :label="$t('manufacturerplant-wizard.view.subheader.manufacturer')">Manufacturers</c-item-selector>
                                  </v-col>
                              </v-row>
                                <divider></divider>
                              <v-row >
                                  <v-col cols="12" md ="6">
                                    <v-text-field v-model="props.item.data.street" filled
                                    :label="streetLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                        
                                    ]"/>
                                  </v-col>

                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="props.item.data.building" filled 
                                    :label="buildingLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                        
                                    ]"/>
                                  </v-col>

                                    <v-col cols="12" md="6">
                                    <v-text-field v-model="props.item.data.zipCode" filled
                                    :label="zipLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                        
                                    ]"/>
                                  </v-col>
                              </v-row>
                              <v-row >
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="props.item.data.country" filled
                                    :label="countryLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>

                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="props.item.data.province" filled
                                    :label="provinceLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                        
                                    ]"/>
                                  </v-col>

                                    <v-col cols="12" md="6">
                                    <v-text-field v-model="props.item.data.city" filled
                                    :label="cityLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>
                              </v-row>
                          </v-card-text>
                      </v-card>
                  </template>
              </c-base-dialog>
          </v-col>
      </v-row>
  </v-container>
</template>


<script>
import ManufacturerplantWizardModule from "../../../store/masterdata/manufacturerplant/manufacturerplant-wizard.module";
import {get} from "lodash";
import Roles from "@/roles";
const MODULE_NAME = "ManufacturerplantWizard";

export default {
  name: "manufacturerplant-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
    "userContextService",
  ],

  methods: {
    async save() {
            this.progressIndicatorService.show();

            try {
              
                await this.$store.dispatch(MODULE_NAME + "/createManufacturerplant");
                this.$router.push({
                    name: "ManufacturerPlantEditorView",
                    params: { name: this.manufacturerplants.name },
                });
                this.reset();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.reset();
            this.$router.push({
                name: "ManufacturerplantListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetManufacturerplant");
            this.setTenantId(this.tenantId);
        }
  },

  computed: {

    manufacturerId: {
    get() {
        return this.$store.getters[MODULE_NAME + "/manufacturerId"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateManufacturerId", item)
            .catch(this.errorHandlerService.handleError);
    },
    },
    hasReadRole() {
      return this.userContextService.hasAllRoles(Roles.MANUFACTURERPLANT_READ);
    },

manufacturerplants: {
    get() {
        return this.$store.getters[MODULE_NAME + "/manufacturerplant"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateManufacturerplants", item)
            .catch(this.errorHandlerService.handleError);
    },
},

manufacturerplantsId: {    
    get() {
        return this.$store.getters[MODULE_NAME + "/manufacturerplant"];
    },


    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateManufacturerplants", item)
            .catch(this.errorHandlerService.handleError);
    },
},
manufacturer() {
    return this.$store.getters[MODULE_NAME + "/manufacturer"];
},

street:{
            get() {
                return this.$store.getters[MODULE_NAME + '/manufacturerplant'];
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateBatterySubstance", value);
            }
        },

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        streetLabel(){
            return this.$t("name-input.component.streetLabel")
        },
        buildingLabel(){
            return this.$t("name-input.component.buildingLabel")
        },
        zipLabel(){
            return this.$t("name-input.component.zipLabel")
        },
        countryLabel(){
            return this.$t("name-input.component.countryLabel")
        },
        cityLabel(){
            return this.$t("name-input.component.cityLabel")
        },
        provinceLabel(){
            return this.$t("name-input.component.provinceLabel")
        },



tenantId() {
    return this.envContextService.tenantId;
}
},

beforemount(){
    console.log("unmounting")
    this.$store.commit(MODULE_NAME + "/resetManufacturerplant");
    this.reset();
  },

async created() {
  if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, ManufacturerplantWizardModule);
  }
    this.$store.commit(MODULE_NAME + "/resetManufacturerplant");
    this.reset();

await this.$store.dispatch(MODULE_NAME + "/loadManufacturers");
},

destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>

<i18n>
{
    "en" : {
        "manufacturerplants-wizard.view.title" : "Create New Manufacturer Plant",
        "manufacturerplants-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Manufacturer Plant.",
        "manufacturerplant-wizard.view.subheader.manufacturer" : "Manufacturer Plant",
        "manufacturer-wizard.view.fields.schema.validation.notEmpty" : "Here you assign a Manufacturer.",
        "manufacturerplant-editor.view.sections.location.title":"Location Data",
        "manufacturerplant-editor.view.sections.location.subtitle" : "Here you assign the location of the Manufacturer Plant.",
        "manufacturerplant-wizard.view.subheader.manufacturer" : "Manufacturer",
        "name-input.component.streetLabel" : "Street Name",
        "name-input.component.buildingLabel" : "Building Number",
        "name-input.component.zipLabel" : "Zip Code",
        "name-input.component.countryLabel" : "Country",
        "name-input.component.cityLabel" : "City",
        "name-input.component.provinceLabel" : "Province",
        "name-input.component.validation.notEmpty" : "This field must be filled",
        "name-input.component.validation.onlyValidCharacters" : "Please only use the characters from a-z and figures, please no special characters",
        "manufacturer-wizard.view.fields.schema.validation.noRights" : "You do not have sufficient Priviledges to view Manufacturers and you therefor cannot create a Manufacturer Plant. Please contact your Administrator!"
    },

    "de" : {
        "manufacturerplants-wizard.view.title" : "Neue Hersteller-Werk anlegen",
        "manufacturerplants-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Hersteller-Werkes.",
        "manufacturerplant-wizard.view.subheader.manufacturer" : "Hersteller",
        "manufacturer-wizard.view.fields.schema.validation.notEmpty" : "Bitte ordnen Sie dem Hersteller-Werk einen Hersteller zu.",
        "manufacturerplant-editor.view.sections.location.title":"Standort-Daten",
        "manufacturerplant-editor.view.sections.location.subtitle" : "Bitte weisen Sie dem Hersteller-Werk einen Standort zu.",
        "manufacturerplant-wizard.view.subheader.manufacturer" : "Hersteller",
        "name-input.component.streetLabel" : "Straßenname",
        "name-input.component.buildingLabel" : "Hausnummer",
        "name-input.component.zipLabel" : "Postleitzahl",
        "name-input.component.countryLabel" : "Land",
        "name-input.component.cityLabel" : "Stadt",
        "name-input.component.provinceLabel" : "Bundesland/Provinz",
        "name-input.component.validation.notEmpty" : "Dieses Feld kann nicht leer sein.",
        "name-input.component.validation.onlyValidCharacters" : "Bitte nutze ausschließlich reguläre Buchstaben (a-z) und Zahlen; bitte nutze keine Sonderzeichen",
        "manufacturer-wizard.view.fields.schema.validation.noRights" : "Deine Berechtigungen reichen nicht aus um Hersteller einzusehen, dementsprechend kannst du keine Hersteller Werke erstellen. Bitte kontaktiere den zuständigen Administrator!"
    
    }
}
</i18n>