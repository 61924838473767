<template>
  <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form 
            :title="$t('sites-editor.view.title', { name: name })" 
            :value="model"
            @update-confirmed="save"
            @update-canceled="cancel"
            :editable="hasEditRole"
            >
            
            <template v-slot:default>
                <v-sheet color="grey lighten-3">
                    
                    <v-tabs 
                        v-model="selectedTab" 
                        :vertical="$vuetify.breakpoint.mdAndUp" 
                        color="grey" 
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                        slider-color="grey" 
                        show-arrows 
                        :fixed-tabs="!$vuetify.breakpoint.mdAndUp"
                        ><!-- >@change="OnTabChange"-->
                        
                        

                        <!-- Tabs -->
                        <v-tab :key="0">{{$t('sites-editor.view.tabs.general')}}</v-tab>
                        <!--//v-if ausblenden, wenn berechtinung -->
                        <v-tab :key="2">{{$t('sites-editor.view.tabs.settings')}}</v-tab>
                        <v-tab :key="1">{{$t('sites-editor.view.tabs.json')}}</v-tab>



                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="site" :disabled="!hasEditRole"/>
                            </v-tab-item>

                            <!-- Workplaces -->
                            <v-tab-item :key="2">
                                <c-paginated-item-list-page
                                @item-deleted="onItemDeleted"
                                @item-edited="onItemEdited"
                                @item-added="onItemAdded"
                                @load-more = "onLoadMoreWorkplaces"
                                :items = "workplace.items"
                                :pagination = "workplace.pagination"
                                :title="title"
                                :subtitle="subtitle"
                                :pageSize=25>
                                </c-paginated-item-list-page>
                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="1">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
             
                
                 
            </template>
        </c-item-editor-form>
  </v-sheet>
</template>

<script>
import { get } from 'lodash';
import SitesEditorModule from "../../../store/masterdata/sites/sites-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'siteEditor';

export default {
    
    name: 'site-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

props: {

    value: {
        type: Object,
        required: true,
        default: () => ({})
    }
},
watch: {

    value: {
        handler(newValue) {

            //workplaces in der init ausführen und laden (Site-laden ubd dazu workplaces nach sit )
            const name = get(newValue, 'name');
            this.init({
                name: name
            });
        },

        immediate: true

    }
},

    data: () => ({
        selectedTab : 0
    }),

    methods: {
        async OnTabChange(event){
            console.log(event.key)
            if(event.key == 2){
            await this.$store.dispatch('loadData');
            }
        },

        async onLoadMoreWorkplaces(payload) {
            
            this.progressIndicatorService.show();
            try{
            await this.$store.dispatch(MODULE_ID +'/loadMoreWorkplaces', {pagination: payload.pagination});
            }catch(error){
                this.errorHandlerService(error);
            }finally{
            this.progressIndicatorService.hide();
            }
        },
        async refresh() {
            this.progressIndicatorService.show();
            try {
               this.$log.debug(`Loading information for site ${this.name}`);
                await this.$store.dispatch(MODULE_ID + "/loadData", {name: this.name})
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        async save() {
            this.progressIndicatorService.show();
            try {
               
                await this.$store.dispatch(MODULE_ID + "/saveData");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        
        async onNextPage({ from, pageSize }) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({ from, pageSize }) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadResponse", {
                    from,
                    pageSize,
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemEdited(event) {
            if (event) {
                const route = this.$router.resolve({
                    name: "WorkplaceEditorView",
                    params: {
                        name: event.item.name,
                    }
                });
                
        window.open(route.href, '_blank');
            } else {
                this.$log.debug(
                    "Event object is undefined. Can't navigate to prediction scenario."
                );
            }
        },

        onItemAdded() {
                const route = this.$router.resolve({
                    name: "WorkplaceWizardView",
                });
                
        window.open(route.href, '_blank');
        },

        async onItemDeleted({ item }) {
            this.progressIndicatorService.show();
            try {
                // Delete the item, then reload the list in order to reflect change.
                await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
                this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter);
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.refresh();
            this.$router.push({
                name: "SitesListView",
            });
        }
    },

    computed: {
        title(){
            return this.$t('workplace-list.view.toolbar.title');
        },
        subtitle(){
            return this.$t('workplace-list.view.toolbar.subtitle');
        },
        workplace: {
            get(){
                return this.$store.getters[MODULE_ID + '/workplace'];
            }},
        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        hasEditRole() { 
           return this.userContextService.hasRole(Roles.SITES_EDIT);
        },

        model: {

            get() {
                return this.$store.getters[MODULE_ID + '/model'];
            },  
            
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateModel", value);
            }
        },

        site: {
            get() {
                return get(this.model, 'site');
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateSite", value);
            }
        },

        response() {
            return this.$store.getters[MODULE_ID + "/response"];
        },

        items() {
            return this.response.items;
        },

        pagination() {
            return get(this.response, "pagination", {});
        },


        hasReadRole() {
            return this.userContextService.hasRole(Roles.WORKPLACE_READ);
        },

        hasCreateRole() {
            return this.userContextService.hasRole(Roles.WORKPLACE_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasRole(Roles.WORKPLACE_DELETE);
        }
        
    },

    created() {
        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, SitesEditorModule);
        }

        this.refresh();
        
    }
}
</script>

<i18n>
{
    "en" : {
        "sites-editor.view.title" : "Site {name}",

        "sites-editor.view.tabs.general" : "General",
        "sites-editor.view.tabs.settings" : "Workplaces",
        "sites-editor.view.tabs.json" : "JSON",
        "workplace-list.view.toolbar.title" : "Site's workplaces",
        "workplace-list.view.toolbar.subtitle" : "Here you can manage the Workplaces belonging to this Site. You can create, delete and edit workplaces."
    },

    "de" : {
        "sites-editor.view.title" : "Produktionsstandort {name}",

        "sites-editor.view.tabs.general" : "Allgemein",
        "sites-editor.view.tabs.settings" : "Arbeitsplatz",
        "sites-editor.view.tabs.json" : "JSON",
        "workplace-list.view.toolbar.title" : "Arbeitsplätze des Standortes",
        "workplace-list.view.toolbar.subtitle" : "Hier können Sie die Arbeitsplätze dieses Standortes verwalten. Sie können Arbeitsplätze editieren, erstellen, oder löschen."
    }
}
</i18n>