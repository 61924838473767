import { get } from "lodash"
import api from '@/solutions/battery-data-hub/api/battery-data-hub.api';


export default {

    namespaced: true,

    state: {

        filter: {
            batteryModels: [],
            batteryStatus: []
        },

        pagination: {
            from: 0,
            pageSize: 0,
            totalItemCount: 0,
        },

        model: {

            columns: [],
            rows: [],
        },

        context: {
            batteryModels: [],
            batteryStatus: [],
        }
    },

    mutations: {
        
        setMatrixRows(state, rows) {
            state.model.rows = (rows) ? rows : [];
        },

        appendMatrixRows(state, rows) {
            if (!state.model.rows) state.model.rows = [];

            if (rows) {
                for (const row of rows) {
                    state.model.rows.push(row);
                }
            }
        },

        setMatrixColumns(state, columns) {
            state.model.columns = (columns) ? columns : [];
        },

        setPagination(state, pagination) {
            state.pagination = (pagination) ? pagination : { from: 0, pageSize: 0, totalItemCount: 0};
        },

        setContextBatteryModelResponse(state, {items}){
            state.context.batteryModels = items ? items : [];
        },

        setContextBatteryStatusResponse(state, {items}) {
            state.context.batteryStatus = items ? items : [];
        },

        setBatteryModelFilter(state, items) {
            state.filter.batteryModels = items ? items : [];
        },

        setBatteryStatusFilter(state, items) {
            state.filter.batteryStatus = items ? items : [];
        }
    },

    actions: {

        async resetMatrix(context) {

            // The 
            context.commit('setMatrixRows',    []);
            context.commit('setMatrixColumns', []);
            context.commit('setPagination', {
                from: 0,
                pageSize: 0,
                totalItemCount: 0,
            });
        },

        async resetContext(context) {

           // Reset the model response 
           await context.commit('setContextBatteryModelResponse',  { items: [] });
           await context.commit('setContextBatteryStatusResponse', { items: [] });
        },


        async resetFilter(context) {

            // Reset the model response 
            await context.commit('setBatteryModelFilter',  [] );
            await context.commit('setBatteryStatusFilter', [] );
        },

        async resetPagination(context) {

            // Reset the model response 
            await context.commit('setPagination',  { from: 0, pageSize: 0, totalItemCount: 0} );

        },


        async loadMatrix(context, {pageSize}) {

            const batteryModels = context.getters.batteryModelFilter;
            const batteryStatus = context.getters.batteryStatusFilter;

            
            const response = await api.masterdata.batteryStatusMatrix.get(batteryModels, batteryStatus, 0, pageSize);
            context.commit('setMatrixRows',    get(response, 'rows', []));
            context.commit('setMatrixColumns', get(response, 'columns', []));
            context.commit('setPagination',    get(response, 'pagination', { from: 0, pageSize: pageSize, totalItemCount: 0}))

        },

        async loadMatrixNextPage(context, {from, pageSize}) {

            const batteryModels = context.getters.batteryModelFilter;
            const batteryStatus = context.getters.batteryStatusFilter;

            
            const response = await api.masterdata.batteryStatusMatrix.get(batteryModels, batteryStatus, from, pageSize);
            context.commit('appendMatrixRows',    get(response, 'rows', []));
            context.commit('setPagination',    get(response, 'pagination', { from: 0, pageSize: pageSize, totalItemCount: 0}))

        },

        async loadContextBatteryModels(context) {

            const result = await api.masterdata.batteryModels.findByFilter('*', 0, 100);
            context.commit('setContextBatteryModelResponse', result);
        },

        async loadContextBatteryStatus(context) {
            const result = await api.masterdata.batteryStatus.findByFilter('*', 0, 100);
            context.commit('setContextBatteryStatusResponse', result);
        },

        updateBatteryModelFilter(context, { items }) {
            context.commit('setBatteryModelFilter', items);
        },

        updateBatteryStatusFilter(context, { items }) {
            context.commit('setBatteryStatusFilter', items);
        }
    },

    getters: {
        
        matrixColumns(state) {
            return get(state, 'model.columns', []);   
        }, 

        matrixRows(state) {
            return get(state, 'model.rows', []);
        },

        batteryModels(state) {
            return get(state, 'context.batteryModels', []);
        },

        batteryStatus(state) {
            return get(state, 'context.batteryStatus', []);
        },

        batteryModelFilter(state) {
            return get(state, 'filter.batteryModels', []);
        },

        
        batteryStatusFilter(state) {
            return get(state, 'filter.batteryStatus', []);
        },

        pagination(state) {
            return get(state, 'pagination', {})
        },

        totalItemCount(state) {
            return get(state, 'pagination.totalItemCount', 0);
        },

        paginationFrom(state) {
            return get(state, 'pagination.from', 0);
        },

        paginationPageSize(state) {
            return get(state, 'pagination.pageSize', 100);
        }
    }
}