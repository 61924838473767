<template>
    <v-card tile :flat="flat">
        <v-card-text>
            <div class="text-h6 grey--text">{{title}}</div>
            <div class="grey--text">{{material.name }} {{casIdentifier ? '(CAS ' + casIdentifier +')' : '&nbsp;'}}</div>
        </v-card-text>
        <v-card-text>
            <c-metrics-distribution-chart :items="materialDistribution" type="treemap" show-value-label height="100px"/>
        </v-card-text>
        <v-card-text class="text-h4 text-right">
            {{  $dxs.formatting.formatDecimal(this.share, $i18n.locale) }}%
        </v-card-text>
                                  
    </v-card>
</template>

<script>
import { get } from 'lodash';

export default {

    props: {

        flat: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Object,
            required: true, 
        }

    },

    computed: {

        materialComposition() {
            return get(this.value, 'materialComposition', {});
        },  


        material() {
            return get(this.value, 'material', {});
        },

        title() {
            return get(this.material, 'name', null);
        },

        casIdentifier() {
            return get(this.material, 'data.casIdentifier', null);
        },

        color() {
            return get(this.material, 'data.color', 'grey');
        },

        share() {
            return get(this.materialComposition, 'data.share', 0.0);
        },

        materialDistribution() {
            return [
                {color: 'lightgray', name: 'Other', value: (100 - this.share)},    
                {color: this.color, name: `${this.title}`, value: this.share},       
            ];
        }
    }


}
</script>