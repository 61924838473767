<template>
    <v-text-field 
        type="number"
        v-model.number="model" 
        :label="label"
        :disabled="disabled" 
        :solo="solo" 
        :filled="filled" 
        :persistent-hint="persistentHint"
        suffix="%"
        :rules="[

            validateLowerBound,
            validateUpperBound
        ]"
        :hint="$t('percentage-input.component.field.validation.hint')"
             
        >
            <!--        
            :rules="[ 
            v => v && v >= 1.0 || $t('percentage-input.component.field.validation.valueTooBig'),
            v => v && v <= 0.0 || $t('percentage-input.component.field.validation.valueTooSmall')
            ]"
        -->
    </v-text-field>
</template>

<script>
export default {

    props: {

        value: {
            type: Number,
            required: true,
            validator: (v) => v >= 0.0 && v <= 1.0
        },

        label: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        solo: {
            type: Boolean,
            required: false,
            default: false
        },

        filled: {
            type: Boolean,
            required: false,
            default: false
        },

        persistentHint: {
            type: Boolean,
            required: false,
            default: false
        },

        /**
         * Optional function which can be used to transform the value before it is set on the components model.
         * If not set a simple pass through will be applied.
         */
        transformInput: {
            type: Function,
            required: false,
            default: (value) => value
        } 
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.data.value = (value) ? value : 0;
            }
        }
    },

    data() {

        return {
            data: { value: 0 }
        };
    },

    methods: {

        fireInput() {

            this.$emit('input', this.data.value);
        },

        validateNotEmpty(value) {
            this.$log.debug(`Validating value ${value} of type ${typeof(value)}`);

            if (value) return true;
            else return this.$t('percentage-input.component.field.validation.valueIsEmpty'); 
        },

        validateUpperBound(value) {

            this.$log.debug(`Validating value ${value} of type ${typeof(value)}`);

            if (value <= 100.0) return true;
            else return this.$t('percentage-input.component.field.validation.valueTooBig'); 
        },

        validateLowerBound(value) {
            this.$log.debug(`Validating value ${value} of type ${typeof(value)}`);

            if (value >= 0.0) return true;
            else return this.$t('percentage-input.component.field.validation.valueTooSmall'); 
        }

    
    },

    computed: {

        model: {

            get() {
                //return this.$dxs.formatting.formatDecimal(this.data.value, this.$i18n.locale);
                return this.data.value;
            },

            set(model) {
                
                this.data.value = this.transformInput(model);
                this.fireInput();
            }
        }
    }
}
</script>

<i18n>
{
    "en" : {

        "percentage-input.component.field.validation.hint" : "Enter a percentage value. Admissible values lie between [0.0 ... 100.0]. Example 87.0",

        "percentage-input.component.field.validation.valueTooBig" : "This input is not admissible: The value must not be empty. Allowed percentage values are in the decimal range of [0.0 ... 100.0].",
        "percentage-input.component.field.validation.valueTooBig" : "This input is not admissible: The value must not be greater than 100.0.",
        "percentage-input.component.field.validation.valueTooSmall" : "This input is not admissible:  The value must not be less than 0.0."
    },

    "de" : {

        "percentage-input.component.field.validation.hint" : "Geben Sie einen Prozentwert ein. Zulässige Werte liegen im Bereich zwischen [0,0 ... 100,0]. Beispiel: 87,00.",

        "percentage-input.component.field.validation.valueTooBig" : "Die Eingabe ist unzulässig: Ein Prozentwert muss im Intervall zwischen [0,0 ... 100,0] liegen.",
        "percentage-input.component.field.validation.valueTooBig" : "Die Eingabe ist unzulässig: Ein Prozentwert darf nicht größer als 100,0 sein.",
        "percentage-input.component.field.validation.valueTooSmall" : "Die Eingabe ist unzulässig: Ein Prozentwert darf nicht kleiner als 0,0 sein."
    }
}
</i18n>