<template>
  <v-container fluid class="secondary pt-10 pb-10">
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <c-base-dialog
          :value="safetyInstruction"
          :title="$t('safety-instruction-wizard.view.title')"
          :subtitle="$t('safety-instruction-wizard.view.subtitle')"
          color="secondary"
          @dialog-saved="save"
          @dialog-canceled="cancel"
        >
          <template v-slot:default="props">
            <v-card flat>
              <v-card-text>
                <c-name-input v-model="props.item.name" filled />
                <v-row>
                                    <v-col cols="12">
                                        <c-item-selector :items="severities" v-model="safetyInstruction.references.severity"
                                        :label="$t('safety-instruction-editor.view.tabs.severity')" :return-object="false" filled
                                        :chips="false"/>
                                    </v-col>
                                </v-row>
                </v-card-text>
            </v-card>
            
          </template>
        </c-base-dialog>
        
      </v-col>
      
    </v-row>
    
  </v-container>
</template>

<script>
import SafetyInstructionWizardModule from "../../../store/masterdata/safety-instructions/safety-instruction-wizard.module";

const MODULE_NAME = "SafetyInstructionWizard";

export default {
  name: "safety-instruction-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
  ],

  methods: {
    async save() {
            this.progressIndicatorService.show();
            try {


              await this.setTenantId(this.envContextService.tenantId);
              const locale = this.$i18n.locale;
              await this.$store.dispatch(MODULE_NAME + "/createSafetyInstruction",locale);

                this.$router.push({
                    name: "SafetyInstructionEditorView",
                    params: { name: this.safetyInstruction.name},
                });
                
    this.reset();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

    cancel() {
      
    this.reset();
      this.$router.push({
        name: "SafetyInstructionListView",
      });
    },

    setTenantId(tenantId) {
      this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
    },

    setSafetyInstruction(safetyInstruction){
      this.$store.dispatch(MODULE_NAME+ "/updateSafetyInstruction", safetyInstruction);
    },
    
    reset() {
      this.$store.dispatch(MODULE_NAME + "/resetSafetyInstruction");
      this.setTenantId(this.tenantId);
    },
  },

  computed: {
    
    severities() {
                return this.$store.getters[MODULE_NAME + '/severities'];
            },
    safetyInstructions() {
            return this.$store.getters[MODULE_NAME + "/safetyInstruction"];
        },
    safetyInstruction: {
      get() {
        return this.$store.getters[MODULE_NAME + "/safetyInstruction"];
      },

      set(item) {
        item.tenantId = this.envContextService.tenantId;
        this.$store
          .dispatch(MODULE_NAME + "/updateSafetyInstruction", item)
          .catch(this.errorHandlerService.handleError);
      },
    },
    
    tenantId() {
        return this.envContextService.tenantId;
    }

  },
  async beforemount(){
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, SafetyInstructionWizardModule);//todo
  }
    console.log("unmounting")
    this.$store.commit(MODULE_NAME + "/resetSafetyInstruction");//todo
    this.reset();
  },

async created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, SafetyInstructionWizardModule);//todo
  }
    this.$store.commit(MODULE_NAME + "/resetSafetyInstruction");//todo
    this.reset();
  await this.$store.dispatch(MODULE_NAME + "/loadData");//todo
},

destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>

<i18n>
{
    "en" : {
        "safety-instruction-wizard.view.title" : "Create Safety Instruction",
        "safety-instruction-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Safety Instruction.",
        "safety-instruction-wizard.view.subheader.safetyInstruction" : "Safety Instruction",
          "safety-instruction-editor.view.tabs.severity" : "Severity"
    },

    "de" : {
        "safety-instruction-wizard.view.title" : "Neuen Sicherheitsanweisung anlegen",
        "safety-instruction-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage einer neuen Sicherheitsanweisung.",
        "safety-instruction-wizard.view.subheader.safetyInstruction" : "Sicherheitsanweisung",
            "safety-instruction-editor.view.tabs.severity" : "Schweregrad"
    }
}
</i18n>


