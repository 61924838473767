import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state: {

        safetyInstruction: createDefaultSafetyInstruction(),
        severities: { items: [] }
    },

    mutations: {
        setHazardousSubstanceClassifications(state, hazards) {
            console.log(hazards);
            console.log("********************hazards");
            if (hazards) {
                let items = [];
                if (hazards.items) {
                    state.hazards.items = hazards.items;
                } else {
                    state.hazards.items = items;
                }

                if (hazards.tenantId) {
                    state.hazards.tenantId = hazards.tenantId;

                }

                if (hazards.pagination) {
                    state.hazards.pagination = hazards.pagination;
                }

            }
        },
        setHazardousSubstanceClassification(state, hazard) {
            if (hazard) {
                state.safetyInstruction.references.hazardoussubstanceclassification = hazard
            }
        },
        setSafetyInstruction(state, safetyInstruction) {

            console.log("=====================================")
            console.log(JSON.stringify(safetyInstruction, true, 2))
            console.log("=====================================")

            if (safetyInstruction) {
                state.safetyInstruction.creationDateTime = safetyInstruction.creationDateTime ? safetyInstruction.creationDateTime : null;
                state.safetyInstruction.creationUser = safetyInstruction.creationUser ? safetyInstruction.creationUser : null;
                state.safetyInstruction.modificationDateTime = safetyInstruction.modificationDateTime ? safetyInstruction.modificationDateTime : null;
                state.safetyInstruction.modificationUser = safetyInstruction.modificationUser ? safetyInstruction.modificationUser : null;
                state.safetyInstruction.tenantId = safetyInstruction.tenantId ? safetyInstruction.tenantId : null;
                state.safetyInstruction.name = safetyInstruction.name ? safetyInstruction.name : null;
                state.safetyInstruction.i18n = safetyInstruction.i18n ? safetyInstruction.i18n : {};
                state.safetyInstruction.active = safetyInstruction.active ? safetyInstruction.active : true;
                state.safetyInstruction.references = safetyInstruction.references ? safetyInstruction.references : {};
                state.safetyInstruction.references.severity = safetyInstruction.references.severity?safetyInstruction.references.severity:"Mittel";
                state.safetyInstruction.data.details = safetyInstruction.data.details?safetyInstruction.data.details:{};
            }

        },
        setSeverities(state, severities) {
            if (severities) {
                let items = [];
                if (severities.items) {
                    state.severities.items = severities.items;
                }
                else {
                    state.severities.items = items;
                }
            } else {
                state.severities.items = [];
            }
        },
        setSeverity(state, severity) {
            state.safetyInstruction.references.severity = severity;
        },
        UpdateLocale(state, locale) {
            console.log(locale)
            console.log("=====================================")
            set(state,"safetyInstruction.data.details."+locale.locale, locale.item[locale.locale]);
        },
        deleteDetail(state, locale) {
            //get all details except the one to be deleted
            let details = {}
            for (let key in state.safetyInstruction.data.details) {
                if (key !== locale[Object.keys(locale)[0]]) {
                    console.log(key + locale[Object.keys(locale)[0]])
                    details[key] = state.safetyInstruction.data.details[key];
                }
            }
            state.safetyInstruction.data.details = details;
        }
    },



    actions: {
        async deleteLocale(context, locale) {
            context.commit('deleteDetail', locale);
        },
        async addLocale(context, locale) {
            await context.commit('UpdateLocale', locale);
        },

        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.safetyInstructions.findById(name);
                await context.commit('setSafetyInstruction', get(response, 'item.safetyInstruction', createDefaultSafetyInstruction()));
                let items = await api.masterdata.severity.findByFilter('*', 0, 100);
                await context.commit('setSeverities', items);
                await context.commit('setSafetyInstruction', get(response, 'item.safetyInstruction', createDefaultSafetyInstruction()));
                   } catch (error) {
                await context.commit('setSeverities', {items:[]});
                context.commit('setSafetyInstruction', createDefaultSafetyInstruction());
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters, 'safetyInstruction');

                let response = await api.masterdata.safetyInstructions.update(payload);
                context.commit('setSafetyInstruction', get(response, 'item.safetyInstruction', createDefaultSafetyInstruction()));

            } catch (error) {
                console.log(error);
                context.commit('setSafetyInstruction', createDefaultSafetyInstruction());
                throw error;

            }
        },

        async updateSafetyInstruction(context, safetyInstruction) {
            context.commit('setSafetyInstruction', safetyInstruction, createDefaultSafetyInstruction());
        },
        async updateSeverity(context, severity) {
            context.commit('setSeverity', severity);
        },
    },

    getters: {
        details(state){
            return get(state,"safetyInstruction.data.details")
            },	
        
        safetyInstruction(state) {
            return state.safetyInstruction;

        },
        severity(state) {
            return state.safetyInstruction.references.severity;
        },
        severities(state) {
            return state.severities.items;
        }
    }
}
function createDefaultSafetyInstruction() {
    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "details":null
        },
        "active": true,
        "references": {
            severity: null
        }

    }
}