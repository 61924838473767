import api from '@/solutions/battery-data-hub/api/battery-data-hub.api';

export default {

    namespaced: true,

    state: {
        searchSettings: {
            searchExpression: '*',
        },

        searchResult: {
            items: [],
            pagination: {
                pageSize: 25,
                totalItemCount: 0
            }
        }
    },

    mutations: {

        setSearchResultItem(state, searchResultItems) {  
            state.searchResult.items = (searchResultItems) ? searchResultItems : [];
        },

        setSearchResultPagination(state, searchResultPagination) {
            state.searchResult.pagination = (searchResultPagination) ? searchResultPagination : { pageSize: 25, totalItemCount: 0 };
        }
    },

    actions: {
        
        async search(context, { searchExpression }) {

            const response = await api.masterdata.batteryPacks.findByFilter(searchExpression, 0, 25);

            context.commit('setSearchResultItem', response.items);
            context.commit('setSearchResultPagination', response.pagination);
        }
    },

    getters: {

        searchResult(state) {
            return state.searchResult;
        }
    }
}