import api from '../../../api/battery-data-hub.api';
import { get } from 'lodash';

export default {

    namespaced: true,

    state: {

        severity: createDefaultSeverity(),

    },

    mutations: {

        setBatterySatus(state, severity) {

            console.log("===========BATTERYSTATUS==============");
            console.log(JSON.stringify(severity, true, 2))
            console.log("===========BATTERYSTATUS==============");

            if (severity) {
                state.severity.creationDateTime = severity.creationDateTime ? severity.creationDateTime : null;
                state.severity.creationUser = severity.creationUser ? severity.creationUser : null;
                state.severity.modificationDateTime = severity.modificationDateTime ? severity.modificationDateTime : null;
                state.severity.modificationUser = severity.modificationUser ? severity.modificationUser : null;
                state.severity.tenantId = severity.tenantId ? severity.tenantId : null;
                state.severity.name = severity.name ? severity.name : null;
                state.severity.i18n = severity.i18n ? severity.i18n : {};
                state.severity.active = severity.active ? severity.active : true;
                state.severity.references = severity.references ? severity.references : {};
                state.severity.data.color = severity.data.color ? severity.data.color : null;
            }

        },
        setColor(state, color) {
            if (color) {
                state.severity.data.color = color;
            }
        }

    },



    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.severity.findById(name);

                context.commit('setBatterySatus', get(response, 'item.severity', createDefaultSeverity()));
            } catch (error) {
                context.commit('setBatterySatus', createDefaultSeverity());
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters, 'severity');
                let response = await api.masterdata.severity.update(payload);

                context.commit('setBatterySatus', get(response, 'item.severity', createDefaultSeverity()));
                console.log('##RESPONSE####', JSON.stringify(get(response, 'item.severity', createDefaultSeverity()), null, 2));


            } catch (error) {
                console.log(error);
                context.commit('setBatterySatus', createDefaultSeverity());
                throw error;

            }
        },


        async updateSeverity(context, severity) {
            context.commit('setBatterySatus', severity, createDefaultSeverity());
        },


        async updateColor(context, color) {
            context.commit('setColor', color);
        }
    },

    getters: {

        severity(state) {
            return state.severity;

        },
        color(state) {
            return state.severity.data.color;
        },



    }
}

function createDefaultSeverity() {
    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "color": null
        },
        "active": true,
        "references": {}

    }
}