<template>
  <v-container fluid class="secondary pt-10 pb-10">
      <v-row justify="center">
          <v-col cols="12" lg="9">
              <c-base-dialog :value="batterySubstances" :title="$t('battery-substances-wizard.view.title')"
                  :subtitle="$t('battery-substances-wizard.view.subtitle')" color="secondary"
                  @dialog-saved="save"
                  @dialog-canceled="cancel">
                  <template v-slot:default="props">
                      <v-card flat>
                          <v-card-text>
                              <v-row>
                                  <v-col cols="12">
                                      <c-name-input v-model="batterySubstances.name" filled />
                                  </v-col>
                              </v-row>
                              <v-row> 
                                  <v-col cols="12">

                                      <c-item-selector :items="hazardousSubstanceClassifications.items" v-model="props.item.references.hazardousSubstanceClassification" :return-object="false" filled
                                          :chips="false"
                                          :rules="[  v => v && !!v.trim()  || $t('hazardous-substance-classification-wizard.view.fields.schema.validation.notEmpty') ]"
                                          :label="$t('battery-substance-wizard.view.subheader.hazardousSubstanceClassification')">HazardousSubstanceClassifications</c-item-selector>
                                  </v-col>
                              </v-row>
                          </v-card-text>
                      </v-card>
                  </template>
              </c-base-dialog>
          </v-col>
      </v-row>
  </v-container>
</template>


<script>
import BatterySubstancesWizardModule from "../../../store/masterdata/battery-substances/battery-substances-wizard.module";

const MODULE_NAME = "BatterySubstancesWizard";

export default {
  name: "battery-substances-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
  ],

  methods: {
    async save() {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + "/checkForBatterySubstance");
                const error = new Error();
                error.message = this.$t('battery-substance-wizard.view.subheader.errorm');
                this.errorHandlerService.handleError(error);
            } catch (error) {
                try{
                await this.$store.dispatch(MODULE_NAME + "/createBatterySubstance");
                this.$router.push({
                    name: "BatterySubstanceEditorView",
                    params: { name: this.batterySubstances.name },});
                    this.reset();
                } catch(error1){
                    this.errorHandlerService.handleError(error1);
                    this.reset();
                } finally {
                    this.progressIndicatorService.hide();
                }
                
                
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.reset();
            this.$router.push({
                name: "BatterySubstanceListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetBatterySubstance");
            this.setTenantId(this.tenantId);
        }
  },

  computed: {

    hazardousSubstanceClassificationId: {
    get() {
        return this.$store.getters[MODULE_NAME + "/hazardousSubstanceClassificationId"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateHazardousSubstanceClassificationId", item)
            .catch(this.errorHandlerService.handleError);
    },
},
name:{
    get(){
        return this.$store.getters[MODULE_NAME + "/batterySubstance"].name;
    },
    set(value){
        this.$store.dispatch(MODULE_NAME + "/updateName", value)
    }
},
batterySubstances: {
    get() {
        return this.$store.getters[MODULE_NAME + "/batterySubstance"];
    },

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateBatterySubstances", item)
            .catch(this.errorHandlerService.handleError);
    },
},

batterySubstancesId: {

    set(item) {
        this.$store
            .dispatch(MODULE_NAME + "/updateBatterySubstances", item)
            .catch(this.errorHandlerService.handleError);
    },
},
hazardousSubstanceClassifications() {
    return this.$store.getters[MODULE_NAME + "/hazardousSubstanceClassifications"];
},



tenantId() {
    return this.envContextService.tenantId;
}
},

async beforemount(){
    if (!this.$store.hasModule(MODULE_NAME)) {
      await this.$store.registerModule(MODULE_NAME, BatterySubstancesWizardModule);//todo
  }
    await this.$store.commit(MODULE_NAME + "/resetBatterySubstance");//todo
    this.reset();
  },

async created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, BatterySubstancesWizardModule);//todo
  }
    await this.$store.commit(MODULE_NAME + "/resetBatterySubstance");//todo
    this.reset();
  await this.$store.dispatch(MODULE_NAME + "/loadHazardousSubstanceClassifications");//todo
},


destroyed() {
    this.reset();
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>

<i18n>
{
    "en" : {
        "battery-substances-wizard.view.title" : "Create New Battery Substance",
        "battery-substances-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Battery Substance.",
        "battery-substance-wizard.view.subheader.hazardousSubstanceClassification" : "Hazardous Substance Classification",
        "hazardous-substance-classification-wizard.view.fields.schema.validation.notEmpty" : "Please assign a Hazardous Substance Classification.",
        "battery-substance-wizard.view.subheader.errorm" : "This Battery Substance already exists."
        
    },

    "de" : {
        "battery-substances-wizard.view.title" : "Neues Batterie-Element anlegen",
        "battery-substances-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Batterie-Elements.",
        "battery-substance-wizard.view.subheader.hazardousSubstanceClassification" : "Gefahrenstoffklassifikation",
        "hazardous-substance-classification-wizard.view.fields.schema.validation.notEmpty" : "Bitte ordnen Sie dem Batterie-Element eine Gefahrenstoffklasse zu.",
        "battery-substance-wizard.view.subheader.errorm" : "Dieses Batterie-Element existiert bereits."
    }
}
</i18n>