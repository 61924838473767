<template>
    
  <div>
    <c-section
      :title="$t('hazardous-substance-classification-list.view.title')"
      :subtitle="$t('hazardous-substance-classification-list.view.subtitle')"
      class="secondary"
    >
      <v-card class="mt-5 mb-5" tile>
        <c-item-list
          :title="$t('hazardous-substance-classification-list.view.toolbar.title')"
          :items="items"
          :pagination="pagination"
          @refresh-list="refresh"
          @previous-page="onPreviousPage"
          @next-page="onNextPage"
          @item-added="onItemAdded"
          @item-edited="onItemEdited"
          @item-deleted="onItemDeleted"
          @item-list-filter-applied="onFilterChanged"

          :disable-add-item="!hasCreateRole"
          :disable-edit-item="!hasEditRole && !hasReadRole"
          :disable-delete-item="!hasDeleteRole"
          supports-delete-item
        >
        </c-item-list>
        <v-card-text></v-card-text>
        <v-divider />
        <v-subheader class="grey lighten-5">{{
          $t("hazardous-substance-classification-list.view.help.title")
        }}</v-subheader>
        <v-card-text class="grey lighten-5">{{
          $t("hazardous-substance-classification-list.view.help.description")
        }}</v-card-text>
      </v-card>
    </c-section>
  </div>
</template>

<script>
import Roles from "@/roles";
import { get } from "lodash";

import HazardousSubstanceClassificationListModule from "../../../store/masterdata/hazardous-substance-classification/hazardous-substance-classifications-list.module"

const MODULE_ID = "HazardousSubstanceClassificationsList";

export default {
  name: "hazardous-substance-classification-list",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "userContextService",
  ],

  data: () => ({
    collectionSetting: {},

    displayConfirmationDialog: false,

    displayDeleteDialog: false,
  }),

  computed: {
    response() {
      return this.$store.getters[MODULE_ID + "/response"];
    },

    items() {
      return this.response.items;
    },

    pagination() {
      return get(this.response, "pagination", {});
    },

    hasReadRole() {
      return this.userContextService.hasAllRoles(Roles.HAZARDOUSSUBSTANCECLASSIFICATION_READ);
    },

    hasEditRole() {
      return this.userContextService.hasAllRoles(Roles.HAZARDOUSSUBSTANCECLASSIFICATION_EDIT);
    },

    hasCreateRole() {
      return this.userContextService.hasAllRoles(Roles.HAZARDOUSSUBSTANCECLASSIFICATION_CREATE);
    },

    hasDeleteRole() {
      return this.userContextService.hasAllRoles(Roles.HAZARDOUSSUBSTANCECLASSIFICATION_DELETE);
    },
  },

  methods: {
    async refresh() {
      this.loadPage(0, 50);
    },

    async onNextPage({ from, pageSize }) {
      this.loadPage(from, pageSize);
    },

    async onPreviousPage({ from, pageSize }) {
      this.loadPage(from, pageSize);
    },

    async loadPage(from, pageSize) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_ID + "/loadResponse", {
          from,
          pageSize,
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    onItemEdited(event) {
      if (event) {
        this.$router.push({
          name: "HazardousSubstanceClassificationEditorView",
          params: {
            name: event.item.name,
          },
        });
      } else {
        this.$log.debug(
          "Event object is undefined. Can't navigate to prediction scenario."
        );
      }
    },

    onItemAdded() {
      this.$router.push({ name: "HazardousSubstanceClassificationWizardView" });
    },

    async onItemDeleted({ item }) {
      this.progressIndicatorService.show();
      try {
        // Delete the item, then reload the list in order to reflect change.
        await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
        this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async onFilterChanged(event) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter);
        await this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    }
  },

  created() {
    // Dynamically register the vuex store module for managing the data.
    if (!this.$store.hasModule(MODULE_ID)) {
      this.$store.registerModule(MODULE_ID, HazardousSubstanceClassificationListModule);
    }

    this.refresh();
  },
  destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_ID)) {
        this.$store.unregisterModule(MODULE_ID);
        this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
    }
  }
};
</script>

<i18n>
{
    "en" : {
        "hazardous-substance-classification-list.view.title" : "Hazardous Substance Classifications Management",
        "hazardous-substance-classification-list.view.subtitle" : "You may use this dialog for creating, editing or deleting Hazardous Substance Classifications.",
        "hazardous-substance-classification-list.view.toolbar.title" : "Hazardous Substance Classifications",

        "hazardous-substance-classification-list.view.columns.open" : "Open",
        "hazardous-substance-classification-list.view.columns.apis" : "APIs",

        "hazardous-substance-classification-list.view.help.title" : "What are Hazardous Substance Classifications?",
        "hazardous-substance-classification-list.view.help.description" : "Hazardous Substance Classifications are any kind of Classification that are used to determine the extend to which a chemical substance is hazardous. For example: FLAMMABLE or EXPLOSIVE."


    },

    "de" : {
        "hazardous-substance-classification-list.view.title" : "Verwaltung Gefahrenstoffklassen",
        "hazardous-substance-classification-list.view.subtitle" : "Sie können neue Gefahrenstoffklassen anlegen, eine Gefahrenstoffklasse editieren oder löschen.",
        "hazardous-substance-classification-list.view.toolbar.title" : "Gefahrenstoffklassen",

        "hazardous-substance-classification-list.view.columns.open" : "Öffnen",
        "hazardous-substance-classification-list.view.columns.apis" : "APIs",

        "hazardous-substance-classification-list.view.help.title" : "Was sind Gefahrenstoffklassen?",
        "hazardous-substance-classification-list.view.help.description" : "Gefahrenstoffklassen sind den chemischen Elementen zugeordnete Klassen, die eine Klassifikation des Gefahrenstoffs ermöglichen; Beispielsweise: ENTFLAMMBAR oder EXPLOSIV."

    }
}
</i18n>