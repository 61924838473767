import api from '../../../api/battery-data-hub.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        severity: createEmptyBaterryStatus(),
        tenantId: null,
        statusColor: null
    },

    mutations: {

        setSeverity(state, severity) {

            if (severity) {

                state.severity.tenantId = severity.tenantId ? severity.tenantId : null;
                state.severity.name = severity.name ? severity.name : null;
                state.severity.i18n = severity.i18n ? severity.i18n : {};
                state.severity.active = severity.active ? severity.active : true;
                state.severity.references = severity.references ? severity.references : {};
                state.severity.data.color = severity.data.color ? severity.data.color : null;

            } else {
                state.severity.tenantId = null;
                state.severity.name = null;
                state.severity.i18n = null;
                state.severity.data = null;
                state.severity.active = null;
                state.severity.references = null;
                state.severity.data.color = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.severity.tenantId = tenantId;

            } else {
                state.severity.tenantId = null;
            }
        },

        setColor(state, statusColor) {
            if (statusColor) {
                state.severity.data.color = statusColor;
            }
        }


    },

    actions: {

        async createSeverity(context) {

            try {

                let request = context.getters.request;
                await api.masterdata.severity.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetSeverity(context) {
            context.commit('setSeverity', createEmptyBaterryStatus());
        },

        async updateSeverity(context, severity) {

            try {
                context.commit('setSeverity', severity);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateColor(context, color) {
            try {
                context.commit('setColor', color);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.severity;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        severity(state) {
            return state.severity ? state.severity : createEmptyBaterryStatus();
        },

        statusColor(state) {
            return state.severity.data.color ? state.severity.data.color : null;
        }

    }
}

function createEmptyBaterryStatus() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "color": null
        },
        "active": true,
        "references": {}

    }
}