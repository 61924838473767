
// ===========================================================================================================================
// APPS
// ===========================================================================================================================
import BatteryPassportDashboardView from '@/solutions/battery-data-hub/views/apps/battery-passport/battery-passport-dashboard.view';
import BatteryPassportSearchView from '@/solutions/battery-data-hub/views/apps/battery-passport/battery-passport-search.view';
import BarreryMaterialMatrixView from '@/solutions/battery-data-hub/views/apps/battery-material-matrix/battery-material-matrix.view';
import BatteryStatusMatrixView from '@/solutions/battery-data-hub/views/apps/battery-status-matrix/battery-status-matrix.view';

import BatteryModelEditorView from '@/solutions/battery-data-hub/views/masterdata/battery-models/battery-model-editor.view';
import BatteryModelListView from '@/solutions/battery-data-hub/views/masterdata/battery-models/battery-model-list.view';
import BatteryModelWizardView from '@/solutions/battery-data-hub/views/masterdata/battery-models/battery-model-wizard.view';

import BatteryPackListView from '@/solutions/battery-data-hub/views/masterdata/battery-packs/battery-pack-list.view';
import BatteryPackEditorView from '@/solutions/battery-data-hub/views/masterdata/battery-packs/battery-pack-editor.view';
import BatteryPackWizardView from '@/solutions/battery-data-hub/views/masterdata/battery-packs/battery-pack-wizard.view';


import BatterySubstanceListView from '@/solutions/battery-data-hub/views/masterdata/battery-substances/battery-substance-list.view';
import BatterySubstanceWizardView from '@/solutions/battery-data-hub/views/masterdata/battery-substances/battery-substance-wizard.view';
import BatterySubstanceEditorView from '@/solutions/battery-data-hub/views/masterdata/battery-substances/battery-substance-editor.view';

import HazardousSubstanceClassificationListView from '@/solutions/battery-data-hub/views/masterdata/hazardous-substance-classifications/hazardous-substance-classifications-list.view';
import HazardousSubstanceClassificationWizardView from '@/solutions/battery-data-hub/views/masterdata/hazardous-substance-classifications/hazardous-substance-classifications-wizard.view';
import HazardousSubstanceClassificationEditorView from '@/solutions/battery-data-hub/views/masterdata/hazardous-substance-classifications/hazardous-substance-classifications-editor.view';
import BatteryMaterialListView from '@/solutions/battery-data-hub/views/masterdata/battery-materials/battery-material-list.view.vue';
import BatteryMaterialEditorView from '@/solutions/battery-data-hub/views/masterdata/battery-materials/battery-material-editor.view.vue';
import BatteryMaterialWizardView from '@/solutions/battery-data-hub/views/masterdata/battery-materials/battery-material-wizard.view.vue';

import BatteryStatusListView from '@/solutions/battery-data-hub/views/masterdata/battery-status/battery-status-list.view.vue';
import BatteryStatusEditorView from '@/solutions/battery-data-hub/views/masterdata/battery-status/battery-status-editor.view.vue';
import BatteryStatusWizardView from '@/solutions/battery-data-hub/views/masterdata/battery-status/battery-status-wizard.view.vue';

import BatteryChemistryListView from '@/solutions/battery-data-hub/views/masterdata/battery-chemistries/battery-chemistries-list.view.vue';
import BatteryChemistryEditorView from '@/solutions/battery-data-hub/views/masterdata/battery-chemistries/battery-chemistries-editor.view.vue';

import BatteryCategoryListView from '@/solutions/battery-data-hub/views/masterdata/battery-categories/battery-categories-list.view.vue';
import BatteryCategoryEditorView from '@/solutions/battery-data-hub/views/masterdata/battery-categories/battery-categories-editor.view.vue';
import BatteryCategoryWizardView from '@/solutions/battery-data-hub/views/masterdata/battery-categories/battery-categories-wizard.view.vue';

import ManufacturersListView from '@/solutions/battery-data-hub/views/masterdata/manufacturers/manufacturers-list.view.vue';
import ManufacturersEditorView from '@/solutions/battery-data-hub/views/masterdata/manufacturers/manufacturers-editor.view.vue';
import ManufacturersWizardView from '@/solutions/battery-data-hub/views/masterdata/manufacturers/manufacturers-wizard.view.vue';

import SubstancesRecyclingShareListView from '@/solutions/battery-data-hub/views/masterdata/substance-recycling-share/substance-recycling-share-list.view.vue';
import SubstancesRecyclingShareEditorView from '@/solutions/battery-data-hub/views/masterdata/substance-recycling-share/substance-recycling-share-editor.view.vue';
import SubstancesRecyclingShareWizardView from '@/solutions/battery-data-hub/views/masterdata/substance-recycling-share/substance-recycling-share-wizard.view.vue';

import ManufacturerPlantListView from '@/solutions/battery-data-hub/views/masterdata/manufacturerplant/manufacturerplant-list.view.vue';
import ManufacturerPlantEditorView from '@/solutions/battery-data-hub/views/masterdata/manufacturerplant/manufacturerplant-editor.view.vue';
import ManufacturerPlantWizardView from '@/solutions/battery-data-hub/views/masterdata/manufacturerplant/manufacturerplant-wizard.view.vue';

import SafetyInstructionListView from '@/solutions/battery-data-hub/views/masterdata/safety-instructions/safety-instruction-list.view.vue';
import SafetyInstructionEditorView from '@/solutions/battery-data-hub/views/masterdata/safety-instructions/safety-instruction-editor.view.vue';
import SafetyInstructionWizardView from '@/solutions/battery-data-hub/views/masterdata/safety-instructions/safety-instruction-wizard.view.vue';

import SeverityListView from '@/solutions/battery-data-hub/views/masterdata/severities/severity-list.view.vue';
import SeverityEditorView from '@/solutions/battery-data-hub/views/masterdata/severities/severity-editor.view.vue';
import SeverityWizardView from '@/solutions/battery-data-hub/views/masterdata/severities/severity-wizard.view.vue';

import AssignmentWizardView from '@/solutions/battery-data-hub/views/masterdata/battery-models/safety-instruction-assignment.view.vue';

import Roles from '@/roles';

export default [

    // =======================================================================================================================
    // APPS
    // =======================================================================================================================
    {

        path: '/battery-data-hub/apps/battery-passport',
        name: 'BatteryPassportSearchView',
        component: BatteryPassportSearchView,
    

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-apps',
                icon: 'badge',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Battery Pass'
                    },

                    'de': {
                        name: 'Batterie-Pass'
                    }
                }
            },
        }
    },
    {

        path: '/battery-data-hub/apps/battery-passport/:name',
        name: 'BatteryPassportDashboardView',
        component: BatteryPassportDashboardView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {

            navigation: {

                icon: 'badge',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Battery Passport'
                    },

                    'de': {
                        name: 'Batterie-Pass'
                    }
                }
            },
        }
    },
    {

        path: '/battery-data-hub/apps/battery-material-matrix/',
        name: 'BarreryMaterialMatrixView',
        component: BarreryMaterialMatrixView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-apps',
                icon: 'battery_full',
                order: 300,

                i18n: {

                    'en': {

                        name: 'Battery Material Matrix'
                    },

                    'de': {
                        name: 'Batterie-Material-Matrix'
                    }
                }
            },
        }
    },
    {

        path: '/battery-data-hub/apps/battery-status-matrix/',
        name: 'BatteryStatusMatrixView',
        component: BatteryStatusMatrixView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-apps',
                icon: 'battery_full',
                order: 400,

                i18n: {

                    'en': {

                        name: 'Battery Status Matrix'
                    },

                    'de': {
                        name: 'Batterie-Status-Matrix'
                    }
                }
            },
        }
    },

    // =======================================================================================================================
    // MASTERDATA
    // =======================================================================================================================


    // Safety Instructions ===========================================================================
    {
        path: '/battery-data-hub/masterdata/safety-instructions/',
        name: 'SafetyInstructionListView',
        component: SafetyInstructionListView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 311000,

                i18n: {
                    'en': {
                        name: 'Safety Instructions'
                    },

                    'de': {
                        name: 'Sicherheitsanweisungen'
                    }
                }
            },
            authorization: {
                roles: [Roles.SECURITYINSTRUCTIONS_READ]
            }
        }
    },    
    {
        path: '/battery-data-hub/masterdata/safety-instructions/new',
        name: 'SafetyInstructionWizardView',
        component: SafetyInstructionWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 311000,
                i18n: {

                    'en': {

                        name: 'Create Safety Instruction'
                    },

                    'de': {
                        name: 'Neue Sicherheitsanweisung anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.SECURITYINSTRUCTIONS_CREATE]
            }

        }
    },

    {
        path: '/battery-data-hub/masterdata/safety-instructions/:name',
        name: 'SafetyInstructionEditorView',
        component: SafetyInstructionEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Safety Instruction Details'
                    },

                    'de': {
                        name: 'Sicherheitsanweisungen Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.SECURITYINSTRUCTIONS_READ]
            }
        }
    },

    // Battery Pack ===========================================================================
    {
        path: '/battery-data-hub/masterdata/battery-pack/',
        name: 'BatteryPackListView',
        component: BatteryPackListView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 1000,

                i18n: {

                    'en': {

                        name: 'Battery Packs'
                    },

                    'de': {
                        name: 'Batterien'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYPACKS_READ]
            }

        }
    },
    {
        path: '/battery-data-hub/masterdata/battery-pack/new',
        name: 'BatteryPackWizardView',
        component: BatteryPackWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,
                i18n: {

                    'en': {

                        name: 'Create Battery Pack'
                    },

                    'de': {
                        name: 'Neue Batterie anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYPACKS_CREATE]
            }

        }
    },
    {
        path: '/solutions/battery-data-hub/masterdata/battery-pack/:name',
        name: 'BatteryPackEditorView',
        component: BatteryPackEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {
 
                        name: 'Battery Pack Details'
                    },

                    'de': {
                        name: 'Batterie Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYPACKS_READ]
            }
        }
    },

    // Battery Model  ===========================================================================
    {

        path: '/battery-data-hub/masterdata/battery-models/',
        name: 'BatteryModelListView',
        component: BatteryModelListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 1500,

                i18n: {

                    'en': {

                        name: 'Battery Model'
                    },

                    'de': {
                        name: 'Batterie-Modell'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYMODEL_READ]
            }
        }
    },

    {
        path: '/battery-data-hub/masterdata/battery-models/new',
        name: 'BatteryModelWizardView',
        component: BatteryModelWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1140,

                i18n: {

                    'en': {

                        name: 'Create Battery Model'
                    },

                    'de': {
                        name: 'Neues Batterie-Model anlegen'
                    }
                }
            },

            authorization: {
                roles: [Roles.BATTERYMODEL_CREATE]
            }

        }
    },


    {
        path: '/battery-data-hub/masterdata/battery-models/:name/',
        name: 'BatteryModelEditorView',
        component: BatteryModelEditorView,
        props: (route) => ({ value: { name: route.params.name}, query: route.params.tabIndex?route.params.tabIndex:0}
         ),

        meta: {

            navigation: {

                i18n: {

                    'en': {

                        name: 'Battery Model Details'
                    },

                    'de': {
                        name: 'Batterie-Modell Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYMODEL_READ]
            }

        }
    },
    {
        path: '/battery-data-hub/masterdata/battery-models/safety-instructions/:name',
        name: 'AssignmentWizardView',
        component: AssignmentWizardView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {

            navigation: {

                i18n: {

                    'en': {

                        name: 'Battery Model Details'
                    },

                    'de': {
                        name: 'Batterie-Modell Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYMODEL_EDIT,Roles.SECURITYINSTRUCTIONS_READ]
            }

        }
    },

    // Battery Chemistry ===========================================================================
    {
        path: '/battery-data-hub/masterdata/battery-chemistries/',
        name: 'BatteryChemistryListView',
        component: BatteryChemistryListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 1800,

                i18n: {


                    'en': {
                        name: 'Battery Chemistries'
                    },

                    'de': {
                        name: 'Batterie-Chemien'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYCHEMISTRY_READ]
            }
        },
    },


    // Battery Category ===========================================================================
    {

        path: '/battery-data-hub/masterdata/battery-category/',
        name: 'BatteryCategoryListView',
        component: BatteryCategoryListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 1800,

                i18n: {


                    'en': {
                        name: 'Battery Category'
                    },

                    'de': {
                        name: 'Batterie-Kategorie'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYCATEGORY_READ]
            }
        },
    },
    {
        path: '/battery-data-hub/masterdata/battery-category/new',
        name: 'BatteryCategoryWizardView',
        component: BatteryCategoryWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,
                i18n: {

                    'en': {

                        name: 'Create Battery Category'
                    },

                    'de': {
                        name: 'Neue Batterie-Kategorie anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYCATEGORY_CREATE]
            }
        }
    },

    {
        path: '/battery-data-hub/masterdata/battery-category/:name',
        name: 'BatteryCategoryEditorView',
        component: BatteryCategoryEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Battery Category Details'
                    },

                    'de': {
                        name: 'Batterie-Kategorie Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYCATEGORY_READ]
            }
        }
    },
    // ================= Battery Status
    {

        path: '/battery-data-hub/masterdata/battery-status/',
        name: 'BatteryStatusListView',
        component: BatteryStatusListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 1900,

                i18n: {

                    'en': {

                        name: 'Battery Status'
                    },

                    'de': {
                        name: 'Batterie-Status'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYSTATUS_READ]
            }
        }
    },
    {
        path: '/battery-data-hub/masterdata/battery-status/new',
        name: 'BatteryStatusWizardView',
        component: BatteryStatusWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',

                i18n: {
                    'en': {
                        name: 'Create Battery Status'
                    },
                    'de': {
                        name: 'Neuen Batterie-Status anlegen'
                    }
                },
            },
            authorization: {
                roles: [Roles.BATTERYSTATUS_CREATE]
            }

        }
    },
    {
        path: '/solutions/battery-data-hub/masterdata/battery-status/:name',
        name: 'BatteryStatusEditorView',
        component: BatteryStatusEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Battery Status Details'
                    },

                    'de': {
                        name: 'Batterie-Status Detailansicht'
                    }
                }
            }
        },
    },

    // ================= Battery Materials List
    {

        path: '/battery-data-hub/masterdata/battery-materials/',
        name: 'BatteryMaterialListView',
        component: BatteryMaterialListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 2000,

                i18n: {

                    'en': {

                        name: 'Battery Materials'
                    },

                    'de': {
                        name: 'Batterie-Materialien'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYMATERIALS_READ]
            }
        }
    },
    {
        path: '/battery-data-hub/masterdata/battery-materials/new',
        name: 'BatteryMaterialWizardView',
        component: BatteryMaterialWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,

                i18n: {

                    'en': {

                        name: 'Create Battery Material'
                    },

                    'de': {
                        name: 'Neues Batterie-Material anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYMATERIALS_CREATE]
            }

        }
    },
    {
        path: '/solutions/battery-data-hub/masterdata/battery-materials/:name',
        name: 'BatteryMaterialEditorView',
        component: BatteryMaterialEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Battery Materials Details'
                    },

                    'de': {
                        name: 'Batterie-Material Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYMATERIALS_READ]
            }


        }
    },


    // Battery Substances ===========================================================================

    {

        path: '/battery-data-hub/masterdata/battery-substances',
        name: 'BatterySubstanceListView',
        component: BatterySubstanceListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 3500,

                i18n: {

                    'en': {

                        name: 'Battery Substances'
                    },

                    'de': {
                        name: 'Batterie-Elemente'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYSUBSTANCE_READ]
            }
        }
    },


    {
        path: '/solutions/battery-data-hub/masterdata/battery-substances/new',
        name: 'BatterySubstanceWizardView',
        component: BatterySubstanceWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 2100,

                i18n: {

                    'en': {

                        name: 'Create Battery Substance'
                    },

                    'de': {
                        name: 'Neues Batterie-Element anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYSUBSTANCE_CREATE]
            }
        }
    },

    {
        path: '/solutions/battery-data-hub/masterdata/battery-substances/:name',
        name: 'BatterySubstanceEditorView',
        component: BatterySubstanceEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Battery Substances Details'
                    },

                    'de': {
                        name: 'Batterie-Elemente Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYSUBSTANCE_READ]
            }
        }
    },

    // ================= Hazardous Substance Classification
    {

        path: '/battery-data-hub/masterdata/hazardous-substance-classification/',
        name: 'HazardousSubstanceClassificationListView',
        component: HazardousSubstanceClassificationListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 2200,

                i18n: {


                    'en': {
                        name: 'Hazardous Substance Classification'
                    },

                    'de': {
                        name: 'Gefahrenstoffklasse'
                    }
                }
            },
            authorization: {

                roles: [Roles.HAZARDOUSSUBSTANCECLASSIFICATION_READ]
            }
        },
    },
    {
        path: '/battery-data-hub/masterdata/hazardous-substance-classifications/new',
        name: 'HazardousSubstanceClassificationWizardView',
        component: HazardousSubstanceClassificationWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,

                i18n: {

                    'en': {

                        name: 'Create Hazardous Substance Classification'
                    },

                    'de': {
                        name: 'Neue Gefahrenstoffklasse anlegen'
                    }
                }
            },
            authorization: {
                roles: [Roles.HAZARDOUSSUBSTANCECLASSIFICATION_CREATE]
            }
        }
    },

    {
        path: '/solutions/battery-data-hub/masterdata/hazardous-substance-classifications/:name',
        name: 'HazardousSubstanceClassificationEditorView',
        component: HazardousSubstanceClassificationEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Hazardous Substance Classification Details'
                    },

                    'de': {
                        name: 'Gefahrenstoffklasse Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.HAZARDOUSSUBSTANCECLASSIFICATION_READ]
            }
        }
    },



    // ================= Substance Recycling Share
    {

        path: '/battery-data-hub/masterdata/substances-recycling-share/',
        name: 'SubstancesRecyclingShareListView',
        component: SubstancesRecyclingShareListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 2500,

                i18n: {


                    'en': {
                        name: 'Substance Recycling Shares'
                    },

                    'de': {
                        name: 'Wiederverwertbarkeitsanteile'
                    }
                }
            },
            authorization: {

                roles: [Roles.RECYCLINGSHARE_READ]
            }
        },
    },



    {
        path: '/battery-data-hub/masterdata/substances-recycling-share/new',
        name: 'SubstancesRecyclingShareWizardView',
        component: SubstancesRecyclingShareWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,
                i18n: {

                    'en': {

                        name: 'Create Substances Recycling Share'
                    },

                    'de': {
                        name: 'Neuen Wiederverwertbarkeitsanteil anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.RECYCLINGSHARE_CREATE]
            }

        }
    },

    {
        path: '/solutions/battery-data-hub/masterdata/substances-recycling-share/:name',
        name: 'SubstancesRecyclingShareEditorView',
        component: SubstancesRecyclingShareEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Substances Recycling Share Details'
                    },

                    'de': {
                        name: 'Wiederverwertbarkeitsanteil Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.RECYCLINGSHARE_READ]
            }
        }
    },
    {
        path: '/solutions/battery-data-hub/masterdata/battery-chemistry/:name',
        name: 'BatteryChemistryEditorView',
        component: BatteryChemistryEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Battery Chemistry Details'
                    },

                    'de': {
                        name: 'Battery-Chemie Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.BATTERYCHEMISTRY_READ]
            }
        }
    },

    // Manufacturers =========================================================
    {

        path: '/battery-data-hub/masterdata/manufacturers/',
        name: 'ManufacturersListView',
        component: ManufacturersListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 3100,

                i18n: {

                    'en': {

                        name: 'Manufacturers'
                    },

                    'de': {
                        name: 'Hersteller'
                    }
                }
            },
            authorization: {

                roles: [Roles.MANUFACTURERS_READ]
            }

        }
    },

    {
        path: '/battery-data-hub/masterdata/manufacturers/new',
        name: 'ManufacturersWizardView',
        component: ManufacturersWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 3100,
                i18n: {

                    'en': {

                        name: 'Create Manufacturers'
                    },

                    'de': {
                        name: 'Neuen Hersteller anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.MANUFACTURERS_CREATE]
            }

        }
    },

    {
        path: '/solutions/battery-data-hub/masterdata/manufacturers/:name',
        name: 'ManufacturersEditorView',
        component: ManufacturersEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Manufacturers Details'
                    },

                    'de': {
                        name: 'Hersteller Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.MANUFACTURERS_READ]
            }
        }
    },

    // Severity =========================================================
    {

        path: '/battery-data-hub/masterdata/severity/',
        name: 'SeverityListView',
        component: SeverityListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 310000,

                i18n: {

                    'en': {

                        name: 'Severity'
                    },

                    'de': {
                        name: 'Schweregrade'
                    }
                }
            },
            authorization: {

                roles: [Roles.SEVERITY_READ]
            }

        }
    },

    {
        path: '/battery-data-hub/masterdata/severity/new',
        name: 'SeverityWizardView',
        component: SeverityWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 310000,
                i18n: {

                    'en': {

                        name: 'Create Severity'
                    },

                    'de': {
                        name: 'Neuen Schweregrad anlegen'
                    }
                }
            },
            authorization: {

                roles: [Roles.SEVERITY_CREATE]
            }

        }
    },

    {
        path: '/solutions/battery-data-hub/masterdata/severity/:name',
        name: 'SeverityEditorView',
        component: SeverityEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Severity Details'
                    },

                    'de': {
                        name: 'Schweregrad Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.SEVERITY_READ]
            }
        }
    },

    // Manufacturer Plants =========================================================
    {

        path: '/battery-data-hub/masterdata/manufacturer-plants/',
        name: 'ManufacturerplantListView',
        component: ManufacturerPlantListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-solutions-batterydatahub-masterdata',
                icon: 'battery_full',
                order: 3200,

                i18n: {

                    'en': {

                        name: 'Maunfacturer Plants'
                    },

                    'de': {
                        name: 'Hersteller-Werk'
                    }
                }
            },
            authorization: {

                roles: [Roles.MANUFACTURERPLANT_READ]
            }

        }
    },
    {
        path: '/battery-data-hub/masterdata/manufacturer-plants/new',
        name: 'ManufacturerPlantWizardView',
        component: ManufacturerPlantWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1100,
                i18n: {

                    'en': {

                        name: 'Create Manufacturer Plant'
                    },

                    'de': {
                        name: 'Neues Hersteller-Werk anlegen'
                    }
                }
            },
            authorization: {
                roles: [Roles.MANUFACTURERPLANT_CREATE, Roles.MANUFACTURERS_READ]
            }

        }
    },

    {
        path: '/solutions/battery-data-hub/masterdata/manufacturer-plants/:name',
        name: 'ManufacturerPlantEditorView',
        component: ManufacturerPlantEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {

            navigation: {
                i18n: {

                    'en': {

                        name: 'Manufacturer Plant Details'
                    },

                    'de': {
                        name: 'Hersteller-Werk Detailansicht'
                    }
                }
            },
            authorization: {

                roles: [Roles.MANUFACTURERPLANT_READ]
            }
        }
    },




];